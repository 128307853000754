import React from 'react';

function Process() {
  return (
    <section className="tc-process-style2">
      <div className="container">
        <h2 className="fsz-45 fw-500 mb-80 text-center">
          Quy trình của
          <span className="sub-font fst-italic color-orange1 fw-400">
           - Nexcon
          </span>
        </h2>
        <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="accordion-side wow fadeInUp slow">
                <div className="accordion" id="accordionProcess">
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 / </span> <h3> Thu thập yêu cầu, tư vấn & thiết kế nội thất </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        Giai đoạn đầu tiên này bao gồm việc giao tiếp với khách hàng để hiểu rõ nhu cầu, mong muốn và phong cách sống của họ. <br />
                        Các nhà thiết kế sẽ tiến hành khảo sát không gian, đo đạc và thu thập dữ liệu cần thiết để lên ý tưởng thiết kế. <br />
                        Tư vấn cho khách hàng về các xu hướng, vật liệu, màu sắc và giải pháp nội thất, đồng thời phát triển thiết kế concept 3D.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 / </span> <h3> Thiết kế kỹ thuật & lập dự toán </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        Trên cơ sở kế hoạch thiết kế ban đầu, các nhà thiết kế sẽ phát triển các bản vẽ kỹ thuật chi tiết, 
                        bao gồm bố cục không gian, đặt đồ nội thất và chi tiết kỹ thuật. <br />
                        Lập dự toán chi phí dựa trên thiết kế, bao gồm chi phí vật liệu, nhân công và các chi phí khác liên quan đến việc thi công.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 / </span> <h3> Triển khai thi công </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        Giai đoạn này bắt đầu với việc chọn lựa nhà thầu và cung cấp các bản vẽ kỹ thuật cho họ. <br />
                        Quá trình thi công bao gồm việc lắp đặt, sơn, trang trí và hoàn thiện các chi tiết theo thiết kế đã được duyệt. <br />
                        Giám sát chặt chẽ tiến độ và chất lượng công việc để đảm bảo dự án được hoàn thành đúng hạn và đúng yêu cầu.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        <span className="num"> 4 / </span> <h3> Bảo hành 3 năm </h3>
                      </button>
                    </div>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        Sau khi dự án hoàn thành, Nexcon sẽ cung cấp dịch vụ bảo hành cho khách hàng kéo dài trong 3 năm và bảo trì trọn đời công trình. <br />
                        Bảo hành bao gồm việc sửa chữa hoặc thay thế các linh kiện nội thất bị hỏng hoặc có vấn đề kỹ thuật. <br />
                        Đảm bảo rằng khách hàng có thể liên hệ và nhận được sự hỗ trợ kịp thời khi cần thiết.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="/PortfolioPage1"
                  className="butn bg-white rounded-pill mt-50 hover-bg-black"
                >
                  <span>
                    Tất cả dự án
                    <i className="ms-2 fal fa-long-arrow-right ico-45"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="imgs">
                <div className="img" data-lag="0.2">
                  <img
                    src="/home2/assets/img/process/proc1.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Design </span>
                </div>
                <div className="img" data-lag="0.4">
                  <img
                    src="/home2/assets/img/process/proc2.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Handover </span>
                </div>
                <div className="img" data-lag="0.3">
                  <img
                    src="/home2/assets/img/process/proc3.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Survey </span>
                </div>
                <div className="img" data-lag="0.5">
                  <img
                    src="/home2/assets/img/process/proc4.jpg"
                    alt=""
                    className="img-cover"
                  />
                  <span className="txt sub-font"> Implement </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/home2/assets/img/prc_bg.png"
        alt=""
        className="bg"
        data-speed="1.2"
      />
    </section>
  );
}

export default Process;
