import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Phong cách thiết kế và bản sắc cá nhân
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              1. Cuộc sống - Ngôn ngữ của không gian:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Phong cách sống là tập hợp những giá trị, thói quen, sở thích và lối sống của một cá nhân hoặc một nhóm người. Nó ảnh hưởng đến mọi mặt của cuộc sống, từ cách ăn mặc, sinh hoạt cho đến cách trang trí ngôi nhà, tạo ra một bức tranh về cá tính và bản sắc của họ. Phong cách thiết kế nội thất là ngôn ngữ hình thể hiện rõ ràng nhất cho phong cách sống đó, tạo nên một không gian sống phản ánh đúng nhất cái “chất” của chủ nhân.
              </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b28_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b28_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2. Phong cách thiết kế nội thất: Bảng màu cho cuộc sống đa dạng
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.1. Sự đa dạng phong phú:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
  <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Phong cách thiết kế nội thất được hình thành và phát triển qua nhiều thời kỳ, từ những phong cách cổ điển đến những phong cách hiện đại, tạo nên một bảng màu phong phú cho không gian sống. Mỗi phong cách mang đến một hơi thở riêng, phản ánh những giá trị, thẩm mỹ và lối sống của một thời đại, một nền văn hóa nhất định.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.2. Các phong cách thiết kế nội thất phổ biến:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phong cách cổ điển:</strong> Sang trọng, tinh tế, mang hơi thở của lịch sử, thường sử dụng những vật liệu cao cấp như gỗ tự nhiên, da bò, lụa, v.v. (ví dụ: Phong cách Pháp, Phong cách Ý, Phong cách Anh, Phong cách Gothic, Phong cách Baroque).
        </h6>
            </li>
           <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phong cách tân cổ điển:</strong> Kết hợp sự sang trọng của phong cách cổ điển với sự hiện đại, đơn giản hơn, thường sử dụng những vật liệu mới như kính, kim loại, v.v. (ví dụ: Phong cách Neoclassical, Phong cách Art Deco).</h6>
            </li>
           <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phong cách hiện đại:</strong> Đơn giản, gọn gàng, chú trọng chức năng, thường sử dụng những vật liệu hiện đại như nhựa, kim loại, kính, v.v. (ví dụ: Phong cách Minimalism, Phong cách Scandinavian, Phong cách Industrial).
        </h6>
            </li>
           <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phong cách Á Đông:</strong> Mang đậm nét văn hóa Á Đông, thường sử dụng những vật liệu tự nhiên như gỗ, tre, nứa, v.v. (ví dụ: Phong cách Nhật Bản, Phong cách Trung Quốc, Phong cách Việt Nam).
        </h6>
            </li>
          </ul>
        </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3. Ngôn ngữ chung cho sự riêng biệt:
        </h6>
        <br />
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.1. Đặc điểm chung của các phong cách thiết kế:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Mỗi phong cách thiết kế nội thất đều có những đặc điểm riêng biệt, nhưng cũng có những nét chung như:
        </h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Màu sắc:</strong> Mỗi phong cách thường sử dụng những màu sắc đặc trưng, tạo nên bầu không khí riêng cho không gian.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Vật liệu:</strong> Mỗi phong cách thường sử dụng những vật liệu riêng biệt, tạo nên sự hài hòa về màu sắc, chất liệu, và phong cách.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Họa tiết:</strong> Mỗi phong cách thường sử dụng những họa tiết trang trí riêng biệt, tạo nên sự độc đáo và sự hài hòa cho không gian.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Ánh sáng:</strong> Mỗi phong cách thường sử dụng những cách chiếu sáng riêng biệt, tạo nên bầu không khí riêng cho không gian.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Bố trí:</strong> Mỗi phong cách thường có cách bố trí không gian riêng biệt, tạo nên sự thuận tiện và hài hòa cho không gian.
        </h6>
            </li>
          </ul>
              </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.2. Tạo lên điểm nhấn riêng cho từng công trình:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Để tạo nên sự độc đáo cho từng công trình, người thiết kế cần phải kết hợp các yếu tố như:</h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hiểu rõ nhu cầu của chủ nhân:</strong> Nắm bắt được phong cách sống, sở thích, và nhu cầu sử dụng của chủ nhân để tạo ra không gian phù hợp nhất.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Lựa chọn phong cách thiết kế phù hợp:</strong> Lựa chọn phong cách thiết kế phù hợp với phong cách sống của chủ nhân, tạo ra sự hài hòa và thuận tiện cho sử dụng.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Sử dụng vật liệu và màu sắc độc đáo:</strong> Kết hợp các vật liệu và màu sắc một cách sáng tạo, tạo ra sự độc đáo và ấn tượng cho không gian.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Tạo điểm nhấn cho không gian:</strong> Sử dụng những chi tiết trang trí độc đáo, những bức tranh, những đồ vật trang trí đẹp mắt để tạo nên điểm nhấn cho không gian.
        </h6>
            </li>
          </ul>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Linh hoạt cho từng cá nhân:</h6>
        <br />
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Tùy biến không gian cho từng cá nhân sử dụng:</h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Mỗi người có một phong cách sống riêng biệt, do đó cần phải tùy biến không gian cho từng cá nhân sử dụng, tạo ra sự thoải mái và thuận tiện nhất cho họ. Ví dụ, trong một ngôi nhà có nhiều thành viên, có thể tùy biến phong cách thiết kế cho từng phòng ngủ, phòng khách, phòng bếp cho phù hợp với nhu cầu và sở thích của mỗi người.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Vẫn mang một ngôn ngữ chung tổng thể:</h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          Dù tùy biến không gian cho từng cá nhân, nhưng vẫn cần phải duy trì sự hài hòa và thống nhất về phong cách cho toàn bộ ngôi nhà, tạo nên một ngôn ngữ chung tổng thể. Điều này có thể được thực hiện bằng cách sử dụng những màu sắc, vật liệu, họa tiết chung, hoặc bằng cách tạo nên một điểm nhấn chung cho toàn bộ không gian.
        </h6>
              </div>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b28_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b28_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b28_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5. NEXCON: Nét riêng cho mỗi cuộc sống:
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.1. Nghiên cứu về tầm ảnh hưởng của phong cách sống đến phong cách thiết kế:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >NEXCON, đơn vị thiết kế thi công trực thuộc Nexholding, luôn chú trọng nghiên cứu về tầm ảnh hưởng của phong cách sống đến phong cách thiết kế, để tạo ra những không gian sống phù hợp và ấn tượng nhất cho khách hàng.
        </h6>
        </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.2. Phương pháp nghiên cứu:
        </h6>
       
        <div
           className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
           data-wow-delay="0.2s"
          >
             <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <li className="d-flex my-4">
           <span className="icon me-3"> • </span> 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phỏng vấn khách hàng:</strong> Hiểu rõ phong cách sống, sở thích, và nhu cầu sử dụng của khách hàng để tạo ra không gian phù hợp nhất.

        </h6>
           </li>
           <li className="d-flex my-4">
           <span className="icon me-3"> • </span> 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Nghiên cứu về vật liệu và công nghệ:</strong> Cập nhật những vật liệu và công nghệ mới nhất để tạo ra những không gian sống hiện đại và tiện nghi.

        </h6>
           </li>
           <li className="d-flex my-4">
           <span className="icon me-3"> • </span> 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phân tích thị trường:</strong> Nắm bắt xu hướng phát triển của phong cách thiết kế nội thất để tạo ra những giải pháp mới mẻ và hấp dẫn.

        </h6>
           </li>
            </ul>
          </div>
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.3. Ứng dụng vào các dự án thiết kế:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >NEXCON áp dụng những nghiên cứu của mình vào từng dự án thiết kế, tạo ra những không gian sống độc đáo, phản ánh đúng bản sắc và phong cách sống của chủ nhân. Từ những ngôi nhà riêng cho đến các căn hộ cao cấp, NEXCON luôn mang đến những giải pháp thiết kế độc đáo và ấn tượng.

        </h6>
              </div>

        
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b28_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b28_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             6. Kết Luận:

            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Phong cách thiết kế nội thất không chỉ là một ngôn ngữ hình thể hiện sự sang trọng, tinh tế của không gian, mà còn là một bức tranh phản ánh phong cách sống của chủ nhân. NEXCON, với tư cách là đơn vị tiên phong trong lĩnh vực thiết kế nội thất, sẽ tiếp tục nỗ lực nghiên cứu và phát triển những giải pháp mới mẻ, góp phần kiến tạo những không gian sống độc đáo, phản ánh đúng bản sắc và phong cách sống của mỗi cá nhân.



            </h6>
           <br />
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b22_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            Sustainable Design Award 2024
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
