import React from 'react';

function Blog() {
  return (
    <section className="tc-blog-style5">
      <div className="container">
        <h2 className="fsz-70 text-uppercase fw-400 text-center mb-120 js-splittext-lines">
          <span className="color-brown1"> ALL </span> Project's
        </h2>
        <div className="row gx-5">
          <div className="col-lg-6">
            <div
              className="main-post pe-lg-5 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <a href="/SingleProject02" className="img">
                <img
                  src="/innerpages/assets/img/s_project/m11.jpg"
                  alt=""
                  className="img-cover"
                />
              </a>
              <div className="info">
                <div className="date">
                  <h3 className="numb"> 12 </h3>
                  <small>
                    december <br /> 2023
                  </small>
                </div>
                <div className="cont">
                  <a
                    href="/SingleProject11"
                    className="title fsz-24 text-uppercase hover-brown1 d-block"
                  >
                    Hạ Long House
                  </a>
                  <small className="fsz14 text-uppercase mt-15 color-brown1 fw-300">
                    Villa
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="sub-posts ps-lg-5 mt-5 mt-lg-0">
              <div
                className="post-card wow fadeInUp slow"
                data-wow-delay="0.4s"
              >
                <div className="date">
                  <h3 className="numb"> 12 </h3>
                  <small>
                    december <br /> 2023
                  </small>
                </div>
                <div className="cont">
                  <a
                    href="/SingleProject12"
                    className="title fsz-24 text-uppercase hover-brown1 d-block"
                  >
                   TERRACE HOUSE
                  </a>
                  <small className="fsz14 text-uppercase mt-15 color-brown1 fw-300">
                  Hòa mình với thiên nhiên, tái tạo năng lượng, sống trọn vẹn mỗi khoảnh khắc 
                  </small>
                </div>
              </div>
              <div
                className="post-card wow fadeInUp slow"
                data-wow-delay="0.6s"
              >
                <div className="date">
                  <h3 className="numb"> 7 </h3>
                  <small>
                    october <br /> 2023
                  </small>
                </div>
                <div className="cont">
                  <a
                    href="/SingleProject13"
                    className="title fsz-24 text-uppercase hover-brown1 d-block"
                  >
                    HÀ HOUSE
                  </a>
                  <small className="fsz14 text-uppercase mt-15 color-brown1 fw-300">
                  Nét xanh giữa lòng phố thị
                  </small>
                </div>
              </div>
              <div
                className="post-card wow fadeInUp slow"
                data-wow-delay="0.8s"
              >
                <div className="date">
                  <h3 className="numb"> 15 </h3>
                  <small>
                    august <br /> 2023
                  </small>
                </div>
                <div className="cont">
                  <a
                    href="/SingleProject10"
                    className="title fsz-24 text-uppercase hover-brown1 d-block"
                  >
                    SUNSET VILLA 
                  </a>
                  <small className="fsz14 text-uppercase mt-15 color-brown1 fw-300">
                  Hòa mình vào thiên nhiên, tận hưởng cuộc sống bình yên.
                  </small>
                </div>
              </div>
              <a
                href="/PortfolioPage"
                className="more-link ms-lg-5 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Tất cả dự án <i className="fat fa-arrow-up-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
