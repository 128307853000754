import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Kiến Trúc Bền Vững: Hòa Quyện Giữa Bản Địa Và Xu Hướng Toàn Cầu </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Trong bối cảnh biến đổi khí hậu và đô thị hóa diễn ra nhanh chóng, việc xây dựng các công trình kiến trúc bền vững đã trở thành một nhiệm vụ cấp bách. Bên cạnh việc ứng dụng công nghệ tiên tiến, vật liệu mới, kiến trúc bền vững còn cần phải dựa trên yếu tố bản địa, tạo nên những công trình hài hòa với môi trường, văn hóa và con người.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Yếu Tố Bản Địa: Cội Nguồn Của Kiến Trúc Bền Vững:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Khí hậu và địa hình:</strong> Kiến trúc bền vững cần phải phù hợp với điều kiện khí hậu, địa hình của từng khu vực. Ví dụ, ở vùng khí hậu nhiệt đới, nên ưu tiên sử dụng vật liệu cách nhiệt, tạo ra hệ thống thông gió tự nhiên, để giảm thiểu tác động của nắng nóng. Ở vùng ven biển, nên sử dụng vật liệu chống chịu được sự ăn mòn của nước biển, gió biển.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Văn hóa và lịch sử:</strong> Kiến trúc bền vững cần phải tôn trọng văn hóa, lịch sử của địa phương. Việc sử dụng vật liệu truyền thống, kết hợp với kỹ thuật hiện đại, giúp tạo nên những công trình mang bản sắc văn hóa riêng, giữ gìn và phát huy những giá trị văn hóa truyền thống.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tài nguyên địa phương:</strong> Kiến trúc bền vững cần phải tận dụng tối đa tài nguyên địa phương, như vật liệu xây dựng, năng lượng tái tạo, để giảm thiểu tác động đến môi trường, thúc đẩy phát triển kinh tế địa phương.</h6>
            </li>
          </ul>
          </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               2. Sự Kết Hợp Hài Hòa:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Vật liệu truyền thống và hiện đại:</strong> Kiến trúc bền vững có thể kết hợp hài hòa giữa vật liệu truyền thống và vật liệu hiện đại. Ví dụ, sử dụng gỗ truyền thống kết hợp với vật liệu cách nhiệt hiện đại, để tạo nên những ngôi nhà vừa đẹp vừa tiết kiệm năng lượng.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kỹ thuật truyền thống và hiện đại:</strong> Kiến trúc bền vững có thể kết hợp kỹ thuật truyền thống và kỹ thuật hiện đại. Ví dụ, sử dụng kỹ thuật xây dựng truyền thống kết hợp với phần mềm mô phỏng 3D, để tạo nên những công trình vừa bền vững vừa hiệu quả.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phong cách kiến trúc truyền thống và hiện đại:</strong> Kiến trúc bền vững có thể kết hợp phong cách kiến trúc truyền thống và hiện đại. Ví dụ, sử dụng phong cách kiến trúc truyền thống như nhà rường, nhà cổ kết hợp với thiết kế hiện đại, để tạo nên những công trình mang nét đẹp riêng biệt và bền vững.</h6>
            </li>
          </ul>
          </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               3. Lợi Ích Của Kiến Trúc Bền Vững:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Bảo vệ môi trường:</strong> Kiến trúc bền vững giúp giảm thiểu tác động đến môi trường, tiết kiệm năng lượng, giảm thiểu lượng khí thải, sử dụng nước hiệu quả, tái chế vật liệu, tạo ra môi trường sống trong lành.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao chất lượng cuộc sống:</strong> Kiến trúc bền vững tạo ra những không gian sống tiện nghi, thoải mái, an toàn, nâng cao chất lượng cuộc sống cho người dân.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phát triển kinh tế:</strong> Kiến trúc bền vững thúc đẩy phát triển kinh tế, tạo ra nhiều ngành nghề mới, thu hút đầu tư, góp phần thúc đẩy tăng trưởng kinh tế.</h6>
            </li>
          </ul>
          </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4. Thách Thức Và Cơ Hội:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thách thức:</strong></h6>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiếu hụt nguồn lực:</strong> Việc xây dựng kiến trúc bền vững đòi hỏi nguồn lực về công nghệ, tài chính và nhân lực lớn</h6>
              </li>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiếu nhận thức:</strong> Nâng cao nhận thức về kiến trúc bền vững cho cộng đồng là điều cần thiết, nhưng việc tiếp cận thông tin, kiến thức về kiến trúc bền vững còn hạn chế.</h6>
              </li>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiếu chính sách hỗ trợ:</strong> Cần có các chính sách hỗ trợ và khuyến khích phát triển kiến trúc bền vững, tạo điều kiện thuận lợi cho doanh nghiệp đầu tư vào công nghệ và giải pháp bền vững.</h6>
              </li>
            </ul>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Cơ hội:</strong></h6>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phát triển công nghệ:</strong> Công nghệ kiến trúc bền vững đang ngày càng phát triển, cho ra đời các vật liệu mới, kỹ thuật mới, giúp nâng cao hiệu quả và tính bền vững của công trình.</h6>
              </li>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Xu hướng toàn cầu:</strong> Xu hướng xây dựng kiến trúc bền vững đang ngày càng phổ biến trên thế giới, tạo cơ hội cho Việt Nam phát triển ngành kiến trúc bền vững.</h6>
              </li>
              <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao năng lực cạnh tranh:</strong> Việc ứng dụng kiến trúc bền vững giúp Việt Nam nâng cao năng lực cạnh tranh, thu hút đầu tư, phát triển kinh tế.</h6>
              </li>
            </ul>
            </li>
          </ul>
          </div>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               5. Hành Động Cho Tương Lai:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chính phủ:</strong> Ban hành các chính sách khuyến khích đầu tư vào công nghệ và giải pháp bền vững, hỗ trợ doanh nghiệp phát triển các dự án kiến trúc bền vững, đẩy mạnh công tác tuyên truyền nâng cao nhận thức cho cộng đồng về kiến trúc bền vững.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Doanh nghiệp:</strong> Đầu tư nghiên cứu và phát triển công nghệ, giải pháp bền vững, ứng dụng các tiêu chuẩn xây dựng xanh trong các dự án, tham gia các chương trình đào tạo, chuyển giao công nghệ kiến trúc bền vững. </h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kiến trúc sư:</strong> Không ngừng học hỏi, nâng cao năng lực chuyên môn, tiếp cận công nghệ mới, tăng cường kiến thức về yếu tố bản địa, chủ động kết nối, hợp tác với các kiến trúc sư quốc tế, các tổ chức quốc tế. </h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Người dân:</strong> Nâng cao nhận thức về kiến trúc bền vững, lựa chọn các sản phẩm và dịch vụ thân thiện môi trường, ủng hộ các dự án kiến trúc bền vững, cùng chung tay xây dựng một môi trường sống xanh, sạch, đẹp. </h6>
            </li>
          </ul>
          </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Kết luận:</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kiến trúc bền vững là một chiến lược phát triển lâu dài, góp phần bảo vệ môi trường, nâng cao chất lượng cuộc sống cho người dân, và đảm bảo phát triển bền vững cho thế hệ mai sau. Việc kết hợp hài hòa giữa yếu tố bản địa và xu hướng toàn cầu là chìa khóa để kiến tạo những công trình kiến trúc bền vững, mang đến giá trị cho cộng đồng. Hãy cùng chung tay hành động, xây dựng một tương lai xanh, bền vững cho Việt Nam!</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
