import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> HẠNH HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét đẹp hiện đại giữa khung cảnh thiên nhiên Kiên Giang
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m23.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Hạnh House, tọa lạc tại ngoại ô Kiên Giang, là một công trình kiến trúc nhà ở cá nhân được thiết kế theo phong cách hiện đại, mang đến một không gian sống tiện nghi, thoáng đãng và đầy cảm hứng, hòa quyện tinh tế với khung cảnh thiên nhiên thơ mộng của vùng đất miền Tây.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Kiên Giang
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        152 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/NCH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/NCH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          HHiện đại và thoáng đãng: Hạnh House được thiết kế theo phong cách hiện đại, toát lên vẻ đẹp thanh lịch, tinh tế và đầy cá tính. Những đường nét kiến trúc mạnh mẽ, những gam màu trung tính, những vật liệu hiện đại, tất cả tạo nên một diện mạo trẻ trung, thu hút, kết hợp hài hòa với không gian thiên nhiên rộng lớn.<br/>
          Kết nối với thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống. Hệ thống cửa sổ lớn, giếng trời, ban công rộng rãi, những khoảng xanh được bố trí khéo léo, tạo nên một không gian thông thoáng, gần gũi với thiên nhiên.<br/>
          Tối ưu công năng sử dụng: Hạnh House được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng thành viên.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền ấn tượng: Hạnh House sở hữu một mặt tiền ấn tượng với những đường nét kiến trúc mạnh mẽ, những gam màu trung tính, những vật liệu hiện đại. Điểm nhấn của mặt tiền là hệ thống cửa sổ lớn, tạo nên một diện mạo thoáng đãng, thu hút.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Ban công rộng rãi: Ban công rộng rãi được thiết kế để tạo không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.<br/>
Kết hợp với vật liệu địa phương: Công trình sử dụng những vật liệu địa phương như gạch bông, đá, tre nứa, mang đến sự gần gũi và thân thiện với môi trường, đồng thời tạo nên nét đặc trưng riêng cho ngôi nhà.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/NCH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/NCH5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/NCH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Ưu điểm:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Phong cách hiện đại: Hạnh House được thiết kế theo phong cách hiện đại, mang đến một không gian sống năng động, tươi mới và đầy cảm hứng.<br/>
          Hòa quyện thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống.<br/>
          Công năng tối ưu: Công trình được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình.<br/>
          Thân thiện môi trường: Công trình được thiết kế với những giải pháp tiết kiệm năng lượng, bảo vệ môi trường.<br/>
          Kết hợp với văn hóa địa phương: Công trình sử dụng những vật liệu địa phương, mang đến sự gần gũi và thân thiện với môi trường, đồng thời tạo nên nét đặc trưng riêng cho ngôi nhà.<br/>
Kết nối với thiên nhiên: Hà House được thiết kế với ban công xanh mát, tạo nên một không gian sống gần gũi với thiên nhiên.<br/>
Hạnh House là một công trình kiến trúc nhà ở cá nhân độc đáo, mang đến một không gian sống tiện nghi, thoáng đãng và đầy cảm hứng. Ngôi nhà là sự kết hợp hài hòa giữa phong cách hiện đại và những yếu tố thiên nhiên, tạo nên một không gian sống lý tưởng cho gia đình.<br/>


          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/NCH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/NCH3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/NCH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/NCH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/NCH5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
