import React from 'react';

function Chat() {
  return (
    <section className="tc-chat-style1">
      <div className="container">
        <div className="content">
          <a href="#" className="xl-text js-splittext-lines">
          nexholding.vn@gmail.com
          </a>
          <h5 className="mb-50 lh-5">
          Hãy để chúng tôi giúp bạn <br /> xây dựng tổ ấm mơ ước.
          </h5>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/c_line4.png"
        alt=""
        className="c-line wow"
      />
    </section>
  );
}

export default Chat;
