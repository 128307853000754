import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Kiến Trúc </a>
           
          </div>
          <h1 className="title"> HÀ HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét xanh giữa lòng phố thị
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m13.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Hà House, tọa lạc tại Long Biên, Hà Nội, là một công trình nhà phố được thiết kế theo phong cách hiện đại, mang đến một không gian sống tiện nghi, thoáng đãng và đầy cá tính. Đặc biệt, điểm nhấn của Hà House là thiết kế mặt tiền ban công kết hợp cây xanh, tạo nên một oase xanh mát giữa lòng phố thị đông đúc.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Long Biên, Hà Nội
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        160m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Hiện đại và tinh tế: Hà House được thiết kế theo phong cách hiện đại, toát lên vẻ đẹp thanh lịch, tinh tế và đầy cá tính. Những đường nét kiến trúc đơn giản, những gam màu trung tính, những vật liệu hiện đại, tất cả tạo nên một diện mạo sang trọng, ấn tượng.<br/>
Kết nối thiên nhiên: Hà House được thiết kế với những ô cửa sổ lớn, giếng trời và ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách. Đặc biệt, ban công được thiết kế như một khu vườn nhỏ, với những chậu cây xanh mát, tạo nên một không gian sống gần gũi với thiên nhiên, mang đến cảm giác thư giãn, thoải mái.<br/>
Tối ưu công năng sử dụng: Hà House được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng thành viên.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền ấn tượng: Hà House sở hữu một mặt tiền ấn tượng với những đường nét kiến trúc mạnh mẽ, những gam màu trung tính, những vật liệu hiện đại. Điểm nhấn của mặt tiền là ban công rộng rãi được thiết kế kết hợp với cây xanh, tạo nên một oase xanh mát giữa lòng phố thị.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Ban công xanh mát: Ban công được thiết kế như một khu vườn nhỏ, với những chậu cây xanh mát, tạo nên một không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất thông minh: Hà House được thiết kế với nội thất thông minh, tối ưu hóa không gian sử dụng, tạo nên một không gian sống tiện nghi, hiện đại.<br/>
Vật liệu hiện đại: Công trình sử dụng những vật liệu hiện đại, thân thiện với môi trường, mang đến vẻ đẹp sang trọng và bền vững.<br/>
Gam màu trung tính: Gam màu trung tính được sử dụng trong trang trí nội thất, tạo cảm giác thanh lịch, tinh tế cho không gian sống.<br/>
<b>Ưu điểm của Hà House:<br/></b>
Kiến trúc hiện đại: Công trình được thiết kế theo phong cách hiện đại, mang đến một không gian sống năng động, tươi mới và đầy cảm hứng.<br/>
Kết nối với thiên nhiên: Hà House được thiết kế với ban công xanh mát, tạo nên một không gian sống gần gũi với thiên nhiên.<br/>
Tối ưu công năng sử dụng: Công trình được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình.<br/>
Thân thiện với môi trường: Công trình được thiết kế với những giải pháp tiết kiệm năng lượng, bảo vệ môi trường.<br/>
Hà House là một công trình kiến trúc nhà phố hiện đại, mang đến một không gian sống tiện nghi, thoáng đãng và đầy cá tính. Công trình là sự kết hợp hài hòa giữa kiến trúc hiện đại và những yếu tố thiên nhiên, tạo nên một oase xanh mát giữa lòng phố thị.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
