import React from 'react';

function Services() {
  return (
    <section className="tc-services-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-7">
            <h2 className="fsz-45 fw-500 mb-50">
              Dịch vụ của 
              <span className="sub-font fst-italic color-orange1 fw-400">
               - Nexcon
              </span>
            </h2>
            <div className="services">
              <div className="row">
                <div className="col-lg-4">
                  <a
                    href="/PortfolioPage1"
                    className="service-card wow fadeInUp slow"
                    data-wow-delay="0.2s"
                  >
                    <div className="img">
                      <img
                        src="/home2/assets/img/services/ser1.jpg"
                        alt=""
                        className="img-cover"
                      />
                      <div className="arrow">
                        <i className="fal fa-long-arrow-right ico-45"></i>
                      </div>
                    </div>
                    <div className="info pt-30">
                      <h4 className="fsz-24"> Thiết Kế </h4>
                      <p className="color-666 mt-15">
                        Khảo sát hiện trạng, thiết kế concept, thiết kế kỹ thuật, diễn họa 3D, triển khai hồ sơ kỹ thuật.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    href="/PortfolioPage1"
                    className="service-card wow fadeInUp slow"
                    data-wow-delay="0.4s"
                  >
                    <div className="img">
                      <img
                        src="/home2/assets/img/services/ser2.jpg"
                        alt=""
                        className="img-cover"
                      />
                      <div className="arrow">
                        <i className="fal fa-long-arrow-right ico-45"></i>
                      </div>
                    </div>
                    <div className="info pt-30">
                      <h4 className="fsz-24"> Thi Công </h4>
                      <p className="color-666 mt-15">
                        Lập dự toán, sản xuất nội thất, thi công công trình, hoàn thiện thô, điện nước.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    href="/PortfolioPage1"
                    className="service-card wow fadeInUp slow"
                    data-wow-delay="0.6s"
                  >
                    <div className="img">
                      <img
                        src="/home2/assets/img/services/ser3.jpg"
                        alt=""
                        className="img-cover"
                      />
                      <div className="arrow">
                        <i className="fal fa-long-arrow-right ico-45"></i>
                      </div>
                    </div>
                    <div className="info pt-30">
                      <h4 className="fsz-24"> Bảo Hành </h4>
                      <p className="color-666 mt-15">
                        Bảo hành 3 năm, bảo trì chọn đời.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-1">
            <h2 className="fsz-45 sub-font fw-500 js-splittext-lines">
            Mục tiêu của chúng tôi là tạo ra những không gian không chỉ đẹp mà còn 
              <span className="color-orange1"> tiện dụng </span> và
              <span className="color-orange1"> thoải mái. </span>
            </h2>
            <div className="btns">
              <a
                href="/Home3Page"
                className="butn borderd border rounded-pill mt-70 hover-bg-black me-2"
              >
                <span>
                  Về chúng tôi
                  <i className="ms-2 fal fa-long-arrow-right ico-45"></i>
                </span>
              </a>
              <a
                href="/PortfolioPage1"
                className="butn rounded-pill mt-70 hover-bg-black bg-orange1 text-white"
              >
                <span>
                  Xem tất cả dự án <i className="ms-2 fal fa-play"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="btm-info mt-50">
          <div className="row gx-0">
            <div className="col-lg-7">
              <h5 className="fsz-24 lh-4 wow fadeInUp slow">
              Chúng tôi cung cấp giải pháp tốt nhất cho tổ ấm của bạn, 
              và điều chỉnh ngân sách của bạn và phù hợp với yêu cầu về chất lượng dự án
              </h5>
              <a
                href="/ContactPage"
                className="butn borderd border rounded-pill mt-70 hover-bg-black"
              >
                <span>
                  Liên hệ ngay
                  <i className="ms-2 fal fa-long-arrow-right ico-45"></i>
                </span>
              </a>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <div className="phone-box" data-speed="1" data-lag="0.5">
                <img
                  src="/home2/assets/img/subtract2.png"
                  alt=""
                  className="bg-img"
                />
                <h2 className="fsz-45 sub-font fw-400">SDT: (+84) 826 888 831</h2>
                <p className="color-666 mt-40">
                Hãy gọi trực tiếp cho chúng tôi<br /> 24/7 
                để được tư vấn miễn phí
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
