import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Từ Khối Bê Tông Đến "Lá Phổi Xanh" Của Đô Thị Nhiệt Đới: Câu Chuyện Về Công Viên KLCC - Kuala Lumpur </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Kuala Lumpur, trung tâm nhộn nhịp của Malaysia, từng là một đô thị với những tòa nhà chọc trời bê tông, hệ thống giao thông đông đúc và bầu không khí ngột ngạt. Nhưng giờ đây, thành phố đang dần khoác lên mình một diện mạo mới, xanh mát hơn, thân thiện môi trường hơn, với điểm nhấn là công viên KLCC - "lá phổi xanh" của đô thị nhiệt đới.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. Nơi Khối Bê Tông Trở Thành Không Gian Xanh Mát:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Công viên KLCC, tọa lạc ngay giữa lòng thủ đô Kuala Lumpur, là một minh chứng cho sự chuyển mình của đô thị. Nơi đây từng là một bãi đất trống, bị bao phủ bởi những khối bê tông, nhưng giờ đây đã trở thành một ốc đảo xanh mát, là điểm hẹn lý tưởng cho người dân thư giãn, tập thể dục, và tận hưởng thiên nhiên.
              </h6>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiết kế thông minh:</strong> Công viên KLCC được thiết kế theo phong cách hiện đại, kết hợp hài hòa giữa yếu tố thiên nhiên và kiến trúc. Hệ thống cây xanh được bố trí khoa học, tạo ra những khu vườn rợp bóng mát, các con đường dạo bộ uốn lượn, hồ nước trong xanh, tạo nên một không gian thoáng đãng, dễ chịu.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết nối với thiên nhiên:</strong> Công viên KLCC mang đến cho người dân cơ hội hòa mình vào thiên nhiên ngay giữa lòng thành phố. Hệ thống cây xanh giúp lọc không khí, giảm thiểu hiệu ứng đảo nhiệt đô thị, tạo ra bầu không khí trong lành, giúp người dân thư giãn và giảm stress.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Khuyến khích lối sống lành mạnh:</strong> Công viên KLCC là nơi lý tưởng cho người dân tập thể dục, tập yoga, chơi thể thao, góp phần thúc đẩy lối sống lành mạnh, nâng cao sức khỏe.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Không gian văn hóa - xã hội:</strong> Công viên KLCC còn là nơi tổ chức các hoạt động văn hóa - xã hội, như các buổi biểu diễn âm nhạc, triển lãm, các cuộc thi, góp phần gắn kết cộng đồng, tạo ra những hoạt động ý nghĩa cho người dân.</h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Sự Thay Đổi Của Kuala Lumpur:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Từ "thành phố bê tông" đến đô thị xanh:</strong> Sự xuất hiện của công viên KLCC là minh chứng cho sự chuyển mình của Kuala Lumpur, từ một đô thị với những khối bê tông lạnh lẽo, đến một thành phố xanh mát, thân thiện môi trường hơn.</h6>
             
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao chất lượng cuộc sống:</strong> Công viên KLCC góp phần nâng cao chất lượng cuộc sống cho người dân Kuala Lumpur, mang đến không gian xanh mát, bầu không khí trong lành, cơ hội thư giãn, tập thể dục và vui chơi giải trí.</h6>
             
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thu hút đầu tư và du lịch:</strong> Công viên KLCC là điểm đến hấp dẫn cho du khách, góp phần thu hút đầu tư, phát triển du lịch cho Kuala Lumpur.</h6>
             
            </li>
            
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Bài Học Cho Việt Nam:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Xây dựng các đô thị xanh:</strong> Việt Nam đang đối mặt với nhiều thách thức về môi trường, do đô thị hóa diễn ra nhanh chóng. Việc xây dựng các công viên, khu vực xanh mát, giúp cải thiện môi trường sống, nâng cao chất lượng cuộc sống cho người dân.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết hợp kiến trúc với thiên nhiên:</strong> Thiết kế kiến trúc cần kết hợp hài hòa giữa yếu tố hiện đại và thiên nhiên, tạo ra những công trình đẹp mắt, thân thiện môi trường, mang đến sự thoải mái cho người dân.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Khuyến khích lối sống xanh:</strong> Cần có những chính sách, biện pháp để khuyến khích người dân thay đổi lối sống, sử dụng năng lượng tiết kiệm, sử dụng phương tiện giao thông công cộng, góp phần bảo vệ môi trường.</h6>
            </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            4. Kết luận:</h6>
           <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Câu chuyện về công viên KLCC là một minh chứng cho việc biến đổi tích cực của một đô thị nhiệt đới. Việc chuyển đổi từ những khối bê tông lạnh lẽo thành "lá phổi xanh" không chỉ mang đến vẻ đẹp cho thành phố, mà còn nâng cao chất lượng cuộc sống, thu hút đầu tư, phát triển du lịch, góp phần xây dựng một đô thị bền vững. Việt Nam có thể học hỏi từ thành công của Kuala Lumpur, để kiến tạo những đô thị xanh, bền vững, mang đến cuộc sống tốt đẹp hơn cho người dân.</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
