import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> NEXCON GỬI YÊU THƯƠNG ĐẾN NHỮNG NGƯỜI HÙNG THẦM LẶNG </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Ngày 30/04 - 01/05, trong khi mọi người đang tận hưởng kỳ nghỉ lễ vui vẻ bên gia đình, những người công nhân xây dựng vẫn miệt mài trên công trường, góp phần tạo nên những công trình vững chãi cho đất nước. Hiểu được sự vất vả và cống hiến thầm lặng của họ, Công ty Cổ phần Xây dựng Nexcon đã dành tặng những món quà ý nghĩa, thể hiện sự tri ân sâu sắc đến những người hùng thầm lặng này.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Trong không khí rộn ràng của ngày lễ, tại các công trường của Nexcon, những nụ cười rạng rỡ đã nở trên gương mặt của những người công nhân khi nhận được những phần quà từ công ty. Đó là những phần quà thiết thực như nhu yếu phẩm, đồ uống, bánh kẹo… thể hiện sự quan tâm, sẻ chia và động viên tinh thần của Nexcon dành cho đội ngũ công nhân.
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> <ul>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Ông Đặng Hoàng Sơn, Giám đốc Công ty Cổ phần Xây dựng Nexcon, chia sẻ:</h6>
              <br />
                <li>“Công nhân là lực lượng nòng cốt, là những người hùng thầm lặng góp phần tạo nên thành công của Nexcon. Trong những ngày lễ, việc họ vẫn miệt mài làm việc để đảm bảo tiến độ thi công khiến chúng tôi vô cùng cảm kích. Những món quà nhỏ này là lời tri ân chân thành của Nexcon đến những người công nhân, thể hiện sự quan tâm, động viên và khích lệ họ tiếp tục cống hiến hết mình cho công ty.”</li>
                <br />
                <li>Không chỉ là những món quà vật chất, hành động của Nexcon còn thể hiện sự trân trọng và tôn vinh những đóng góp thầm lặng của người công nhân. Mỗi phần quà được trao đi đều chứa đựng tấm lòng của Nexcon, là lời khẳng định về sự gắn bó, đồng lòng và chia sẻ của tập thể công ty.</li>
              </ul>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>  <ul>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Anh Lương Gia Mạnh, một công nhân xây dựng tại công trường của Nexcon, tâm sự:</h6>
              <br />
                <li>“Dù làm việc trong những ngày lễ nhưng chúng tôi vẫn rất vui vì được công ty quan tâm, động viên. Những phần quà này tuy nhỏ nhưng lại rất ý nghĩa, giúp chúng tôi thêm động lực để tiếp tục cống hiến hết mình cho công ty.”</li>
                <br />
                <li>Hành động của Nexcon đã góp phần lan tỏa thông điệp về sự trân trọng lao động, tôn vinh những người công nhân xây dựng - những người hùng thầm lặng góp phần xây dựng đất nước.</li>
              </ul>
            </li>
            </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           Sự kiện này không chỉ là lời tri ân, mà còn là lời khẳng định về trách nhiệm xã hội của Nexcon, một doanh nghiệp luôn hướng đến việc xây dựng môi trường làm việc tốt đẹp, mang đến cuộc sống tốt đẹp hơn cho người lao động.
            </h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Nexcon tin rằng, với sự chung tay của tập thể, Nexcon sẽ tiếp tục gặt hái nhiều thành công hơn nữa trong tương lai.
            </h6>
            
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage3"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b21_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="/" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="/SinglePostPage21" className="hover-orange1 fsz-24">
                          Kiến Trúc Bền Vững: Tiết Kiệm Năng Lượng & Tối Ưu Hóa Không Gian Sống
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage23"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b23_9.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexcon - Nexsa
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="/SinglePostPage23" className="hover-orange1 fsz-24">
                          Kiến trúc xanh: Hòa nhịp thiên nhiên, nâng tầm cuộc sống
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
