import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Từ nhu cầu đến hiện thực: Nhà ở thông minh - Xu hướng toàn cầu
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1.1. Nền tảng của cuộc cách mạng:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Sự bùng nổ của cuộc cách mạng công nghiệp lần thứ tư đã mang đến những thay đổi chóng mặt cho đời sống con người, nhất là trong lĩnh vực công nghệ thông tin và truyền thông. Công nghệ ngày càng trở nên thông minh, kết nối và tự động hóa, tạo ra những giải pháp đột phá cho các ngành nghề và lĩnh vực khác nhau, bao gồm cả lĩnh vực nhà ở.
              </h6></div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1.2. Xu hướng tất yếu:
              </h6>
             <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nhà ở thông minh (Smart Home) không còn là khái niệm xa lạ, mà đã trở thành một xu hướng tất yếu, được ứng dụng rộng rãi ở nhiều quốc gia trên thế giới. Đó là một hệ sinh thái thông minh, sử dụng công nghệ để tự động hóa, quản lý và điều khiển các thiết bị trong nhà, nâng cao sự tiện nghi, an toàn và tiết kiệm năng lượng cho người sử dụng.
              </h6></div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1.3. Hành trình phát triển của nhà ở thông minh:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
                  <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Giai đoạn đầu (năm 1980 - 1990):</strong> Xu hướng này xuất hiện với những thiết bị đơn giản như hệ thống điều khiển từ xa cho TV, âm thanh, đèn chiếu sáng.
               </h6>   </li>
                  <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Giai đoạn phát triển (năm 2000 - 2010):</strong> Sự ra đời của internet và các thiết bị mạng không dây đã mở ra kỷ nguyên mới cho nhà ở thông minh. Các hệ thống quản lý nhà ở thông minh ngày càng đa dạng và phức tạp hơn, bao gồm điều khiển nhiệt độ, ánh sáng, hệ thống an ninh, v.v.
</h6>
                  </li>
                  <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Giai đoạn hiện đại (năm 2010 - nay):</strong> Công nghệ IoT (Internet of Things) và AI (Artificial Intelligence) đã thay đổi cách chúng ta tương tác với ngôi nhà. Nhà ở thông minh ngày càng thông minh hơn, tự động hóa cao hơn, có khả năng học hỏi và thích nghi với thói quen của người sử dụng.</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b26_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b26_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2. Nhà ở thông minh: Hành trình chinh phục sự tiện nghi và an toàn
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2.1. Định nghĩa:</h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nhà ở thông minh là ngôi nhà được trang bị các thiết bị điện tử, phần mềm và hệ thống kết nối thông minh, cho phép người sử dụng điều khiển, quản lý và giám sát các chức năng của ngôi nhà từ xa thông qua các thiết bị di động, máy tính hoặc giọng nói.
              </h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2.2. Hệ thống nhà ở thông minh: Cấu trúc và chức năng:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Quản lý và vận hành tối ưu:</strong> Điều khiển và giám sát các thiết bị trong nhà từ bất kỳ đâu, bất kỳ lúc nào, tiết kiệm thời gian và công sức.
              </h6>
              </li>
            <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>An toàn và bảo mật:</strong> Hệ thống an ninh thông minh giúp phát hiện và ngăn chặn các mối nguy hiểm, báo động khi có sự cố.</h6>
              </li>
            <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tiết kiệm năng lượng:</strong> Hệ thống quản lý năng lượng thông minh giúp tối ưu hóa việc sử dụng điện, nước, giảm thiểu chi phí và bảo vệ môi trường.
              </h6>
              </li>
            <li className="d-flex my-4">
                  <span className="icon me-3"> • </span> 
                  <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tăng cường tiện nghi:</strong> Tạo ra không gian sống thông minh, tiện nghi, đáp ứng mọi nhu cầu của người sử dụng.
              </h6>
              </li>
          </ul>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3. Bước ngoặt trong kiến trúc: Nhà ở thông minh - Tối ưu không gian sống
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.1. Tùy biến không gian theo nhu cầu:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Công nghệ nhà thông minh cho phép người sử dụng tùy biến không gian sống theo ý thích và nhu cầu sử dụng. Ví dụ, có thể điều chỉnh ánh sáng, nhiệt độ, âm thanh phù hợp với mỗi hoạt động, tạo ra không gian riêng tư, thoáng đãng, thư giãn, hoặc năng động, tập trung.</h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.2. Kết hợp hài hòa với thiết kế nội thất:

              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Hệ thống nhà thông minh có thể được tích hợp một cách tinh tế vào thiết kế nội thất, tạo ra một tổng thể hài hòa, hiện đại, thuận tiện cho sử dụng.
              </h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.3. Sử dụng tối ưu diện tích:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Công nghệ nhà thông minh có thể giúp tối ưu hóa diện tích sử dụng, ví dụ như sử dụng các hệ thống lưu trữ thông minh, kết hợp các chức năng đa năng cho các thiết bị trong nhà.
              </h6>
              </div>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b26_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b26_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b26_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Thực trạng nhà ở thông minh ở Việt Nam: Nét đẹp hiện đại, tiềm năng to lớn
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Xu hướng đang lên:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Nhà ở thông minh ở Việt Nam đang ngày càng được chú ý và phát triển, thu hút sự quan tâm của nhiều người dân, nhất là những người trẻ tuổi, có nhu cầu về một cuộc sống hiện đại, tiện nghi và an toàn.
        </h6>
      </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Thách thức và cơ hội:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          ><h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Thách thức:</strong>
        </h6>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Chi phí đầu tư cao.
        </h6>
            </li>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiếu hụt kiến thức, kỹ năng về công nghệ nhà thông minh.</h6>
            </li>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Môi trường pháp lý, chính sách chưa hoàn thiện.
        </h6>
            </li>
            </ul>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Cơ hội:</strong>
        </h6>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thị trường nhà ở thông minh Việt Nam có tiềm năng phát triển rất lớn.
        </h6>
            </li>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Sự gia tăng nhu cầu về cuộc sống tiện nghi, an toàn và tiết kiệm năng lượng.
        </h6>
            </li>
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Chính phủ đang có những chính sách khuyến khích phát triển công nghệ thông minh.
        </h6>
            </li>
            </ul>
            </li>
          </ul>
      </div>
      <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.3. Bức tranh đa dạng:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Hiện nay, có nhiều loại hình nhà ở thông minh được ứng dụng ở Việt Nam, từ những ngôi nhà đơn giản cho đến những dự án căn hộ cao cấp, tích hợp đầy đủ các tiện ích thông minh.
        </h6>
              </div>
      <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5. Nhà ở thông minh - Hòa nhịp với đô thị thông minh
        </h6>
        <br />
      <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.1. Sự kết nối đồng bộ:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Nhà ở thông minh là một phần quan trọng của mô hình đô thị thông minh. Sự kết nối giữa nhà ở thông minh và hệ thống hạ tầng đô thị thông minh sẽ tạo ra một môi trường sống thông minh, tiện nghi, an toàn và bền vững.
        </h6>
              </div>
      <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.2. Tạo dựng một hệ sinh thái thông minh:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Quản lý năng lượng thông minh:</strong> Kết nối với hệ thống quản lý năng lượng đô thị để tối ưu hóa việc sử dụng năng lượng cho cả ngôi nhà và toàn thành phố.

        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hệ thống giao thông thông minh:</strong> Kết nối với hệ thống giao thông công cộng thông minh, dự báo tình hình giao thông, tìm kiếm tuyến đường tối ưu.

        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Dịch vụ công cộng thông minh:</strong> Kết nối với hệ thống dịch vụ công cộng thông minh như y tế, giáo dục, an ninh, giúp người dân tiếp cận các dịch vụ một cách thuận tiện và nhanh chóng.

        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hệ thống giám sát môi trường:</strong> Kết nối với hệ thống giám sát môi trường để theo dõi chất lượng không khí, nước, báo động kịp thời khi có sự cố.

        </h6>
            </li>
          </ul> 
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          6. Hành trình kiến tạo tương lai: Giải pháp và vai trò của NEXCON
        </h6>  <br />
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         6.1. Đánh thức tiềm năng:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
           <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
        Để phát triển nhà ở thông minh một cách bền vững, cần có những giải pháp đồng bộ:
        </h6>  
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phát triển hạ tầng kỹ thuật số:</strong> Nâng cấp hệ thống mạng internet, tăng cường kết nối không dây, xây dựng hệ thống dữ liệu lớn, ứng dụng AI.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hoàn thiện chính sách, pháp luật:</strong> Cần có những chính sách khuyến khích đầu tư, phát triển công nghệ nhà thông minh, xây dựng khung pháp lý rõ ràng, bảo vệ quyền lợi của người tiêu dùng.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Nâng cao nhận thức:</strong> Tăng cường truyền thông, giáo dục để nâng cao nhận thức của người dân về nhà ở thông minh, tạo sự tin tưởng và thu hút sự tham gia của cộng đồng.
        </h6>
            </li>
          </ul> 
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         6.2. NEXCON: Tiên phong kiến tạo cuộc sống thông minh:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         NEXCON, đơn vị thiết kế thi công trực thuộc Nexholding, là đơn vị tiên phong trong việc nghiên cứu, ứng dụng các giải pháp nhà thông minh vào trong sản phẩm thiết kế. NEXCON mang đến những giải pháp nhà thông minh đầy đủ, hiện đại, phù hợp với nhu cầu và điều kiện cụ thể của từng khách hàng.
        </h6>  
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         6.3. Lợi thế vượt trội:
        </h6>  
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Kiến thức chuyên môn:</strong> Đội ngũ kỹ sư, kiến trúc sư giàu kinh nghiệm, nắm bắt sâu sắc kiến thức về công nghệ nhà thông minh, thiết kế nội thất, kiến trúc.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hệ sinh thái sản phẩm đa dạng:</strong> Cung cấp đầy đủ các giải pháp nhà thông minh, từ hệ thống quản lý năng lượng, an ninh, giải trí, cho đến các thiết bị gia dụng thông minh, phần mềm điều khiển, v.v.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Chất lượng và dịch vụ:</strong> Cam kết mang đến những sản phẩm chất lượng cao, dịch vụ chuyên nghiệp, hỗ trợ khách hàng tận tâm, bảo hành chu đáo.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Cập nhật công nghệ:</strong> Luôn cập nhật những công nghệ mới nhất, mang đến những giải pháp tiên tiến, đáp ứng nhu cầu ngày càng cao của khách hàng.
        </h6>
            </li>
          </ul> 
              </div>
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b26_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b26_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           Kết nối tương lai: Khơi mở tiềm năng cho cuộc sống Việt:


            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           Nhà ở thông minh không chỉ là một xu hướng, mà còn là một giải pháp góp phần nâng cao chất lượng cuộc sống, xây dựng một xã hội văn minh, hiện đại và bền vững. NEXCON, với tư cách là đơn vị tiên phong trong lĩnh vực nhà ở thông minh, sẽ tiếp tục nỗ lực phát triển các giải pháp đột phá, góp phần kiến tạo một cuộc sống thông minh, tiện nghi và hạnh phúc cho người dân Việt Nam.



            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           
           Nhà ở thông minh không chỉ là một công trình kiến trúc, mà còn là một lời khẳng định về tầm nhìn và ý chí kiến tạo một cuộc sống tốt đẹp hơn. NEXCON cam kết sẽ đồng hành cùng khách hàng, thực hiện những giấc mơ về một ngôi nhà thông minh, tiện nghi và an toàn, góp phần nâng tầm cuộc sống Việt Nam.


            </h6>
            <br />
           
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
