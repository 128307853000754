import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> T HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét thanh lịch giữa hiện đại và cổ điển
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m27.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          T HOUSE, tọa lạc tại Bắc Giang, là một công trình kiến trúc nhà ở cá nhân được thiết kế theo phong cách hiện đại, kết hợp tinh tế với những vòm cong nhẹ nhàng, mang đến một không gian sống độc đáo, thanh lịch và đầy cảm hứng. Ngôi nhà là sự giao thoa hài hòa giữa nét đẹp mạnh mẽ của hiện đại và nét đẹp mềm mại, uyển chuyển của cổ điển, tạo nên một không gian sống vừa năng động, vừa thanh bình.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Bắc Giang
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        130 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thiết kế kiến trúc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/T2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/T3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Sự kết hợp độc đáo: T HOUSE là sự kết hợp độc đáo giữa phong cách hiện đại và những vòm cong nhẹ nhàng. Phong cách hiện đại mang đến sự tối giản, tinh tế, năng động với những đường nét mạnh mẽ, vật liệu hiện đại, trong khi đó, những vòm cong mang đến sự mềm mại, uyển chuyển, tạo nên nét đẹp thanh lịch, cổ điển.<br/>
          Hòa quyện với thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống. Hệ thống cửa sổ lớn, ban công rộng rãi, giếng trời, những khoảng xanh được bố trí khéo léo, tạo nên một không gian thông thoáng, gần gũi với thiên nhiên.<br/>
          Tối ưu công năng sử dụng: T HOUSE được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng thành viên.

<br/>
<b>Kiến trúc:<br/></b>
Hình khối đơn giản: Ngôi nhà được thiết kế với những hình khối đơn giản, mạnh mẽ, tạo nên vẻ đẹp thanh lịch và hiện đại.<br/>
Vòm cong nhẹ nhàng: Những vòm cong được sử dụng tinh tế, tạo nên nét đẹp mềm mại, uyển chuyển, điểm xuyết cho kiến trúc hiện đại, tạo nên sự độc đáo và thanh lịch cho ngôi nhà.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Ban công xanh mát: Ban công được thiết kế với những chậu cây xanh mát, tạo nên một không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/T4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/T5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/T2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Ưu điểm:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Sự độc đáo trong thiết kế: T HOUSE là sự kết hợp hài hòa giữa phong cách hiện đại và những vòm cong nhẹ nhàng, tạo nên một không gian sống độc đáo, thanh lịch.<br/>
          Hòa quyện thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống.<br/>
          Công năng tối ưu: Ngôi nhà được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình.<br/>

Thân thiện môi trường: Công trình được thiết kế với những giải pháp tiết kiệm năng lượng, bảo vệ môi trường.<br/>
T HOUSE là một công trình kiến trúc nhà ở cá nhân độc đáo, mang đến một không gian sống thanh bình, tiện nghi và tràn đầy năng lượng. Ngôi nhà là sự kết hợp hài hòa giữa phong cách hiện đại và nét đẹp cổ điển, tạo nên một không gian sống lý tưởng cho gia đình.<br/>

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/T4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/T3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/T2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m27.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/T2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
