import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Quy Hoạch Nghỉ Dưỡng  </a>
        
          </div>
          <h1 className="title"> DAK PLAO SPA & RETREAT </h1>
          <div className="text color-666 mt-30">
          Nơi Hòa Mình Với Thiên Nhiên, Nuôi Dưỡng Tâm Hồn.
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m7.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Mô tả dự án
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Dak Plao là khu nghỉ dưỡng sinh thái độc đáo tọa lạc bên hồ Tà Đùng, một trong những hồ nước ngọt lớn nhất Việt Nam. Nằm giữa khung cảnh thiên nhiên hùng vĩ với núi non trùng điệp, mặt hồ trong xanh, Dak Plao mang đến trải nghiệm nghỉ dưỡng thư giãn, hòa mình vào thiên nhiên, đồng thời tận hưởng dịch vụ spa đẳng cấp.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          services
                        </small>
                        <div className="links fsz-18 fw-500">
                          Architecture Design, <br /> Interior Design, Landscape
                          <br /> Design
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hồ Tà Đùng, Đắk Nông
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        6.5 ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thi Tuyển
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/DP2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/DP3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Xây dựng Dak Plao trở thành điểm đến lý tưởng cho du khách yêu thích thiên nhiên, muốn tìm kiếm sự bình yên và 
         trải nghiệm dịch vụ spa chất lượng.Khai thác tiềm năng du lịch sinh thái của vùng hồ Tà Đùng, góp phần thúc đẩy 
         phát triển kinh tế địa phương.Bảo tồn và phát huy giá trị văn hóa truyền thống của đồng bào dân tộc thiểu số nơi đây.<br />
         <b>Mô hình thiết kế:<br /></b>
         Dak Plao được thiết kế theo mô hình spa resort, với những điểm nhấn:<br />
Kiến trúc hòa hợp thiên nhiên: Sử dụng vật liệu tự nhiên như gỗ, tre, đá, tạo nên không gian gần gũi, thân thiện, tối ưu hóa ánh sáng tự nhiên, mang đến cảm giác thư thái, gần gũi với thiên nhiên.<br />
Hệ thống phòng nghỉ đa dạng: Bao gồm các loại phòng nghỉ sang trọng, tiện nghi, hướng ra hồ Tà Đùng hoặc khu vườn xanh mát, mang đến sự riêng tư và thư giãn tối đa.<br />
Khu vực spa & wellness: Trang bị đầy đủ các dịch vụ spa hiện đại, sử dụng nguyên liệu thiên nhiên từ vùng hồ Tà Đùng, mang đến trải nghiệm chăm sóc sức khỏe và sắc đẹp độc đáo.<br />
Hoạt động trải nghiệm: Tổ chức các hoạt động du lịch sinh thái, khám phá văn hóa, ẩm thực địa phương, như: chèo thuyền kayak, câu cá, trekking, tham quan các bản làng, thưởng thức đặc sản địa phương.<br />
Khu vực nhà hàng: Phục vụ các món ăn địa phương, các món ăn mang phong cách Á - Âu, cùng với các loại thức uống và cocktail đặc biệt.<br />
Khu vực vui chơi giải trí: Thiết kế khu vui chơi cho trẻ em, khu vực tổ chức sự kiện, hội nghị, đáp ứng nhu cầu của mọi đối tượng khách hàng.


          </div>
          
    
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/DP3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/DP4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/DP5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Ưu điểm nổi bật:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Vị trí đắc địa, cảnh quan thiên nhiên tuyệt đẹp, khí hậu trong lành.
Thiết kế độc đáo, sang trọng, mang đậm nét văn hóa địa phương.
Dịch vụ spa chuyên nghiệp, sử dụng nguyên liệu thiên nhiên.
Hoạt động du lịch sinh thái đa dạng, phù hợp với mọi lứa tuổi.
Dịch vụ chu đáo, chuyên nghiệp, mang đến sự hài lòng cho khách hàng.
Dak Plao hứa hẹn sẽ là điểm đến lý tưởng cho du khách muốn tìm kiếm sự thư giãn, phục hồi năng lượng và tận hưởng vẻ đẹp thiên nhiên hùng vĩ của vùng hồ Tà Đùng. Dự án góp phần thúc đẩy phát triển du lịch sinh thái, mang lại lợi ích kinh tế và xã hội cho địa phương.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/DP2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/DP3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/DP3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/DP4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/DP5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
