import React from 'react';

function Portfolio() {
  return (
    <section className="tc-portfolio-style4">
      <div className="container">
        <div className="content">
          <div className="title js-splittext-lines">
            <h2 className="sec-title mb-30"> Quy trình triển khai dự án </h2>
            <p className="fsz-18 color-666">
            Sự sáng tạo là trọng tâm trong tất cả các dự án của Nexcon
           
            </p>
          </div>
          <div className="projects">
            <a href="#" className="work-card">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr1.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Khảo sát hiện trạng </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr2.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Thu thập yêu cầu & tư vấn </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr3.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Thiết kế concept </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr4.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Dự toán và kế hoạch thi công </h5>
            </a>
            <a href="#" className="work-card ">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr5.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Thi công nội thất </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow animated">
                <img
                  src="/home4_personal_architect/assets/img/works/wr6.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Thi công xây dựng </h5>
            </a>
          </div>
          <h4 className="pr-link">
            <a href="/PortfolioPage1" className="js-splittext-lines">
              Xem tất cả <br /> Dự án
            </a>
            <span className="icon js-splittext-lines">
              <i className="ti-arrow-top-right"></i>
            </span>
          </h4>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
