import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Nội Thất Thông Minh: Nâng Tầm Cuộc Sống Hiện Đại </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Trong bối cảnh cuộc sống hiện đại ngày càng bận rộn, con người luôn tìm kiếm những giải pháp tối ưu để nâng cao chất lượng cuộc sống. Nội thất thông minh, với sự kết hợp tinh tế giữa công nghệ và thiết kế, đang dần trở thành xu hướng được ưa chuộng, mang đến nhiều lợi ích thiết thực cho mọi gia đình.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
            1. Sự Tiện Nghi Vượt Trội:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Điều khiển từ xa:</strong> Nội thất thông minh cho phép người dùng điều khiển các thiết bị trong nhà bằng điện thoại thông minh, máy tính bảng hoặc các thiết bị thông minh khác. Bạn có thể bật/tắt đèn, điều chỉnh nhiệt độ, mở/đóng rèm cửa, bật/tắt hệ thống âm thanh, từ bất kỳ đâu, bất kỳ lúc nào.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tự động hóa</strong>: Nội thất thông minh tự động hóa các hoạt động thường ngày, giúp bạn tiết kiệm thời gian và công sức. Ví dụ, hệ thống chiếu sáng tự động bật/tắt theo thời gian, hệ thống điều hòa tự động điều chỉnh nhiệt độ theo thời tiết, hệ thống rèm cửa tự động mở/đóng khi nắng lên hoặc trời tối.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>An ninh và bảo mật:</strong> Nội thất thông minh giúp tăng cường an ninh và bảo mật cho ngôi nhà. Hệ thống camera giám sát, cảm biến chuyển động, hệ thống báo động, giúp bạn yên tâm hơn khi vắng nhà, cảnh báo kịp thời khi có sự cố xảy ra.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tiết kiệm năng lượng:</strong> Nội thất thông minh giúp tiết kiệm năng lượng hiệu quả. Hệ thống chiếu sáng tự động bật/tắt theo thời gian, hệ thống điều hòa tự động điều chỉnh nhiệt độ, hệ thống rèm cửa tự động mở/đóng, giúp giảm thiểu lãng phí năng lượng, bảo vệ môi trường.</h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Sự Thoải Mái Và Tiện Dụng:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tạo không gian sống thông minh:</strong> Nội thất thông minh giúp tạo ra không gian sống thông minh, tiện nghi, phù hợp với nhu cầu của mỗi người. Bạn có thể tùy chỉnh các thiết bị, hệ thống theo sở thích của mình, tạo ra một môi trường sống thoải mái, tiện lợi.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao chất lượng cuộc sống:</strong> Nội thất thông minh giúp bạn giải phóng thời gian, năng lượng, để tập trung vào những hoạt động yêu thích, góp phần nâng cao chất lượng cuộc sống</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thân thiện môi trường:</strong> Nội thất thông minh giúp tiết kiệm năng lượng, giảm thiểu lãng phí, bảo vệ môi trường, góp phần tạo ra một cuộc sống xanh, bền vững.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Xu Hướng Phát Triển:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Công nghệ tiên tiến:</strong> Công nghệ nội thất thông minh đang ngày càng phát triển, cho ra đời những sản phẩm thông minh, tiện lợi hơn, với nhiều tính năng mới.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết nối IoT:</strong> Nội thất thông minh được kết nối với Internet of Things (IoT), cho phép các thiết bị kết nối và tương tác với nhau, tạo ra một hệ sinh thái thông minh.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự phổ biến:</strong> Nội thất thông minh ngày càng được phổ biến, trở thành lựa chọn của nhiều gia đình, góp phần thay đổi cách thức chúng ta sống, làm việc và giải trí.</h6>
            </li>
          </ul>
              </div>
              <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >4. Thách Thức Và Cơ Hội:</h6>
            <br />
             <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
             <li className="d-flex my-4">
             <span className="icon me-3"> • </span>
             <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Thách thức:</strong></h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <strong>Chi phí:</strong> Nội thất thông minh thường có giá thành cao hơn so với nội thất truyền thống.
            </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <strong>Công nghệ:</strong> Công nghệ nội thất thông minh còn đang trong giai đoạn phát triển, một số thiết bị có thể gặp lỗi hoặc khó sử dụng.
            </h6>
            </li>
          </ul>
             </li>
             <li className="d-flex my-4">
             <span className="icon me-3"> • </span>
             <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Cơ hội:</strong></h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Phát triển thị trường:</strong> Thị trường nội thất thông minh đang ngày càng phát triển, tạo ra nhiều cơ hội cho các doanh nghiệp sản xuất, phân phối và dịch vụ.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Nâng cao năng lực cạnh tranh:</strong> Việc ứng dụng nội thất thông minh giúp các doanh nghiệp nâng cao năng lực cạnh tranh, thu hút khách hàng.</h6>
            </li>
          </ul>
             </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            5. Kết luận:</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Nội thất thông minh đang thay đổi cách chúng ta sống, làm việc và giải trí. Với những lợi ích vượt trội về tiện nghi, thoải mái, an ninh, tiết kiệm năng lượng và sự phát triển của công nghệ, nội thất thông minh đang trở thành xu hướng được ưa chuộng, góp phần nâng tầm cuộc sống hiện đại.</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           Hãy cùng đón nhận những thay đổi tích cực mà nội thất thông minh mang lại, để tạo ra một cuộc sống hiện đại, tiện nghi và đầy đủ tiện nghi.</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
