import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <h1 className="title"> CÔNG VIÊN HỒ LƯƠNG YÊN </h1>
          <div className="text color-666 mt-30">
          Hội Tụ Sinh Thái,<br /> Kết Nối Cộng Đồng
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m2.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Dự án thiết kế công viên Hồ Lương Yên được hình thành dựa trên ý tưởng tạo ra một không gian xanh, mở và gần gũi với thiên nhiên, nơi mọi người có thể tận hưởng không khí trong lành và tương tác với cộng đồng.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Công viên Hồ Lương Yên không chỉ là một không gian xanh cho cư dân đô thị mà còn là một biểu tượng của sự hài hòa giữa con người và thiên nhiên, góp phần tạo nên một cộng đồng mạnh mẽ và gắn kết. Đây sẽ là một điểm đến lý tưởng cho mọi lứa tuổi, tạo điều kiện cho sự phát triển toàn diện của cả cộng đồng và môi trường xung quanh.
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Dịch vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Quy hoạch chi tiết 
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa Điểm:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Bỉm Sơn, Thanh Hóa
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích:
                        </small>
                        <div className="links fsz-18 fw-500">
                        1.3ha 
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img1_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img2_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Thông tin: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Dự án thiết kế công viên Hồ Lương Yên được hình thành dựa trên ý tưởng tạo ra một không gian xanh, mở và gần gũi với thiên nhiên, nơi mọi người có thể tận hưởng không khí trong lành và tương tác với cộng đồng. 
            <br />
            <br />
            Mục tiêu thiết kế:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tạo ra một công viên sinh thái hài hòa với mặt nước, cây xanh và các không gian công cộng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Khuyến khích hoạt động ngoại khóa và tăng cường tính kết nối cộng đồng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Bảo tồn và phát huy giá trị cảnh quan tự nhiên của khu vực Hồ Lương Yên.
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/a6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/a5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/a4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Đặc điểm thiết kế:
          </h3>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Mặt nước:Công viên sẽ có một hồ nước lớn làm trung tâm, với các dòng chảy nhân tạo và thác nước nhỏ tạo điểm nhấn.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Cây xanh: Trồng nhiều loại cây bản địa để tạo bóng mát và không gian xanh mát cho công viên.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Không gian công cộng: Thiết kế các khu vực chức năng như sân chơi trẻ em, khu vực picnic, sân khấu ngoài trời và đường dạo bộ.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tính kết nối:Các lối đi được bố trí thông minh để kết nối các khu vực trong công viên, tạo sự thuận tiện cho người dùng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng vật liệu thân thiện với môi trường và công nghệ xây dựng tiên tiến để giảm thiểu tác động đến môi trường.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Lợi ích cộng đồng:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Cung cấp không gian xanh cho cư dân đô thị, giúp cải thiện chất lượng không khí và giảm stress.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tạo điều kiện cho các hoạt động cộng đồng như hội chợ, biểu diễn nghệ thuật và thể thao.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Khuyến khích lối sống lành mạnh và tăng cường giao lưu giữa các thế hệ trong cộng đồng.
            </li>
          </ul>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/a1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/a2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/a3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/a4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/a5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
