import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Xây Dựng </a>
            <a href="#"> Nội Thất </a>``
          </div>
          <h1 className="title"> 
          Harmony Home
            </h1>
          <div className="text color-666 mt-30">
          Nơi yêu thương kết nối, không gian lan tỏa.
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m68.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Hơn cả một công trình kiến trúc, "Harmony Home" là minh chứng cho sức mạnh của sự thấu hiểu và khả năng kiến tạo nên những điều phi thường từ những điều giản dị nhất. Được "lột xác" từ căn nhà cấp 4 nhỏ bé 17m2, Harmony Home đã trở thành bản hòa ca tuyệt đẹp của tình yêu thương gia đình và sự sáng tạo không giới hạn.
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch Vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                          Thiết kế <br /> Xây Dựng
                          <br /> Bảo Hiểm
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Ba Vì, Hà Nội
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        350m2

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i68_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i68_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Từ mong muốn xóa nhoà ranh giới giữa "ngôi nhà của cha mẹ" và "không gian riêng của con cái", Nexcon đã khéo léo sử dụng ánh sáng tự nhiên như một sợi dây liên kết vô hình. Bức tường thô cứng được thay thế bằng những vách ngăn linh hoạt, cho phép ánh sáng len lỏi vào từng ngóc ngách, tạo nên cảm giác thoáng đãng và kết nối liền mạch giữa các khu vực.
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Sự sáng tạo được thể hiện rõ nét qua thiết kế “đa tầng lớp”, nơi phòng khách được "hô biến" linh hoạt thành 2 phòng ngủ và 2 phòng khách. Không gian một tầng dường như được nhân lên gấp bội, vừa đảm bảo sự riêng tư cho từng thành viên, vừa tạo nên một tổng thể thống nhất và ấm cúng.

          </div>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj68_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj68_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj68_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           {/* tieu de tong ket */}
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Harmony Home là minh chứng cho thấy, hạnh phúc không nằm ở diện tích mà nằm ở cách ta kiến tạo và cảm nhận. Nơi đây, tình yêu thương được tự do lan tỏa, kết nối các thành viên trong gia đình và tạo nên những khoảnh khắc đáng nhớ.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_9.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_10.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_11.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_12.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj68_8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
