import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> NEX: Cánh cửa dẫn đến hiệu quả và minh bạch trong ngành xây dựng Việt Nam </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Ngành xây dựng Việt Nam đang bước vào giai đoạn phát triển mạnh mẽ, đòi hỏi những giải pháp tiên tiến để nâng cao hiệu quả, minh bạch và bền vững. NEX, một nền tảng quản lý dự án dựa trên công nghệ BIM (Mô hình thông tin xây dựng), đang được xem là công cụ hữu hiệu cho các công ty xây dựng, kiến trúc và quản lý đô thị tại Việt Nam.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. NEX: Cách mạng hóa quản lý dự án xây dựng:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Kết nối thông tin xuyên suốt: NEX là một hệ sinh thái tích hợp các phần mềm khác như Revit, Navisworks, Civil 3D, giúp kết nối thông tin giữa các bên liên quan trong dự án như kiến trúc sư, kỹ sư, nhà thầu, chủ đầu tư, tạo dựng môi trường làm việc minh bạch và hiệu quả.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>  
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Quản lý tài liệu tập trung: NEX cung cấp kho lưu trữ dữ liệu tập trung, cho phép truy cập và quản lý các bản vẽ, mô hình 3D, tài liệu kỹ thuật, thông tin dự án một cách dễ dàng, đồng thời kiểm soát phiên bản và bảo mật thông tin.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > Quản lý tài liệu tập trung: NEX cung cấp kho lưu trữ dữ liệu tập trung, cho phép truy cập và quản lý các bản vẽ, mô hình 3D, tài liệu kỹ thuật, thông tin dự án một cách dễ dàng, đồng thời kiểm soát phiên bản và bảo mật thông tin. </h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > Theo dõi tiến độ và chi phí hiệu quả: Nền tảng này cung cấp các công cụ theo dõi tiến độ thi công, quản lý chi phí, phân bổ tài nguyên một cách trực quan và chính xác, giúp nhà quản lý nắm bắt tình hình dự án một cách toàn diện và kịp thời. </h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > Cải thiện giao tiếp và hợp tác: NEX hỗ trợ giao tiếp, trao đổi thông tin trực tuyến giữa các bên liên quan, tạo điều kiện thuận lợi cho việc phối hợp và giải quyết vấn đề một cách nhanh chóng và hiệu quả </h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > Nâng cao an toàn lao động: NEX giúp phân tích rủi ro, lập kế hoạch an toàn lao động, cảnh báo nguy hiểm tiềm ẩn, góp phần nâng cao an toàn cho công nhân trong quá trình thi công. </h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. NEX cho các công ty xây dựng:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tăng hiệu quả thi công: NEX giúp tối ưu hóa quy trình thi công, giảm thiểu lãng phí vật liệu và nhân lực, rút ngắn thời gian hoàn thành dự án, nâng cao năng suất lao động.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Giảm thiểu chi phí: NEX giúp phát hiện và xử lý các vấn đề tiềm ẩn từ giai đoạn thiết kế, giảm thiểu chi phí sửa chữa, bảo trì và vận hành sau khi hoàn thành công trình.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Cải thiện chất lượng công trình: NEX giúp nâng cao tính chính xác và hiệu quả của quá trình thi công, đảm bảo chất lượng công trình đạt tiêu chuẩn.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. NEX cho các công ty kiến trúc:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tăng cường tính sáng tạo: NEX giúp kiến trúc sư tạo ra các bản vẽ 3D chi tiết, mô phỏng chân thực công trình, thể hiện ý tưởng thiết kế một cách rõ ràng và thu hút khách hàng.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Hỗ trợ ra quyết định: NEX giúp phân tích, đánh giá các phương án thiết kế, so sánh hiệu quả, tối ưu hóa chi phí và nâng cao chất lượng công trình.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Cung cấp hình ảnh trực quan: NEX giúp kiến trúc sư cung cấp cho khách hàng hình ảnh trực quan về công trình, giúp họ dễ dàng hình dung và đưa ra quyết định.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >4. NEX cho các cơ quan quản lý đô thị:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Quản lý hạ tầng đô thị hiệu quả: NEX giúp quản lý và theo dõi tình trạng hạ tầng đô thị, từ đó đưa ra kế hoạch bảo trì, sửa chữa và nâng cấp hiệu quả.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Phòng chống thiên tai: NEX cho phép mô phỏng và dự báo tác động của thiên tai, giúp các cơ quan chức năng đưa ra kế hoạch ứng phó hiệu quả.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Xây dựng đô thị thông minh: NEX hỗ trợ việc quản lý dữ liệu đô thị, xây dựng mô hình 3D của thành phố, góp phần xây dựng đô thị thông minh, phát triển bền vững.</h6>
            </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            5. Kết luận:</h6>
            <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            </div>
            <ul
              className="list-styled color-666 fsz-14 lh-5 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <li>
              NEX là giải pháp tối ưu cho các công ty xây dựng, kiến trúc và quản lý đô thị tại Việt Nam, góp phần nâng cao hiệu quả quản lý dự án, chất lượng công trình, minh bạch thông tin, an toàn lao động và phát triển bền vững. Việc ứng dụng NEX sẽ giúp ngành xây dựng Việt Nam hội nhập và phát triển mạnh mẽ trong thời gian tới.
              </li>
            </ul>
            <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s">
            Ngoài ra, cần lưu ý rằng việc ứng dụng NEX cần đi kèm với việc đào tạo, nâng cao năng lực cho đội ngũ cán bộ, kỹ sư, công nhân xây dựng, đảm bảo họ hiểu rõ và sử dụng thành thạo công nghệ này để đạt hiệu quả tối ưu.
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage3"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b21_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="/" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="/SinglePostPage21" className="hover-orange1 fsz-24">
                          Kiến Trúc Bền Vững: Tiết Kiệm Năng Lượng & Tối Ưu Hóa Không Gian Sống
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage23"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b23_9.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexcon - Nexsa
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="/SinglePostPage23" className="hover-orange1 fsz-24">
                          Kiến trúc xanh: Hòa nhịp thiên nhiên, nâng tầm cuộc sống
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
