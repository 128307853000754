import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <h1 className="title"> Phương Đông Green Valley </h1>
          <div className="text color-666 mt-30">
          THUNG LŨNG XANH NƠI CỬA NGÕ HÒA BÌNH  
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/projects/vtc.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tại cửa ngõ phía Đông của Hòa Bình, giữa bốn bề núi đồi xanh mướt, 
            bên dòng sông Bùi xinh đẹp, có một “thung lũng xanh” tươi mới và yên bình. 
            Nơi đây sẽ mang đến cho cư dân bầu không khí trong lành của núi rừng Tây Bắc, chào đón bình minh tràn đầy năng lượng cho một ngày hứng khởi. 
            Mỗi ngày trôi qua tại Phương Đông Green Valley với thật nhiều cung bậc, không ồn ã, không xô bồ nhưng đầy thi vị. Chọn cuộc sống tại Phương Đông Green Valley là chọn một tổ ấm bình yên và đủ đầy tiện ích, là ngôi nhà thứ hai luôn mở rộng cửa đón bạn trở về.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Dịch vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Quy hoạch chi tiết
                         
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Địa điểm:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Trung tâm xã Tân Vinh,Lương Sơn, Hòa Bình
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích:
                        </small>
                        <div className="links fsz-18 fw-500">
                        9.6ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Thông tin:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
             Tại cửa ngõ phía Đông của Hòa Bình, giữa bốn bề núi đồi xanh mướt, bên dòng sông Bùi xinh đẹp, có một “thung lũng xanh” tươi mới và yên bình. Nơi đây sẽ mang đến cho cư dân bầu không khí trong lành của núi rừng Tây Bắc, chào đón bình minh tràn đầy năng lượng cho một ngày hứng khởi. Mỗi ngày trôi qua tại Phương Đông Green Valley với thật nhiều cung bậc, không ồn ã, không xô bồ nhưng đầy thi vị. Chọn cuộc sống tại Phương Đông Green Valley là chọn một tổ ấm bình yên và đủ đầy tiện ích, là ngôi nhà thứ hai luôn mở rộng cửa đón bạn trở về. <br />
         
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
    
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/d1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/d2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/d3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           {/* tieu de tong ket */}
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Với vị trí được bao bọc bởi bốn bề là đồi núi hữu tình, từ Phương Đông Green Valley cư dân có thể dễ dàng kết nối với trung tâm thị trấn Lương Sơn – trái tim của Hòa Bình và các khu vực lân cận qua các tuyến đường trọng điểm: Quốc lộ 21, Láng Hòa Lạc,… Bên cạnh đó, việc tiếp giáp với nhiều khu nghỉ dưỡng đẳng cấp như: Long Thành Luxury Resort, Sun Village,…… sẽ khiến Phương Đông Green Valley không chỉ là nơi đáng sống bậc nhất Lương Sơn mà còn là cơ hội kinh doanh hàng đầu cho những chủ nhân năng động.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/d3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
