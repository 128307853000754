import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Biệt Thự </a>
           
          </div>
          <h1 className="title"> TERRACE HOUSE </h1>
          <div className="text color-666 mt-30">
          Hòa mình với thiên nhiên, tái tạo năng lượng, sống trọn vẹn mỗi khoảnh khắc 
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m12.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Dự án Terrace House là một không gian sống đẳng cấp, được thiết kế theo phong cách "Resort in house" dành riêng cho một gia chủ yêu thích sự riêng tư, độc đáo và tận hưởng cuộc sống nghỉ dưỡng ngay tại nhà. Nằm tại Hòa Bình, nơi thiên nhiên hùng vĩ, không khí trong lành, Terrace House là nơi lý tưởng để tìm về sự yên bình và tái tạo năng lượng.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hòa Bình
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        600 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/TV2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/TV3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Kiến trúc:<br/></b>
          Phong cách: Kiến trúc hiện đại, kết hợp với những nét tinh tế của kiến trúc truyền thống, tạo nên vẻ đẹp độc đáo, hài hòa với thiên nhiên.
Bố cục: Dự án được thiết kế theo mô hình "Resort in house", với các khu vực riêng biệt được bố trí khoa học, tận dụng tối đa ánh sáng tự nhiên, gió trời, mang đến cảm giác thông thoáng, thư giãn.
Vật liệu: Ưu tiên sử dụng các vật liệu tự nhiên như gỗ, đá, tre nứa, kết hợp với kính cường lực, tạo nên không gian sống sang trọng, gần gũi với thiên nhiên.
Hình ảnh: Các công trình được thiết kế với hình khối đơn giản, tạo sự thanh thoát, kết hợp với hệ thống cửa sổ lớn, giúp tối ưu hóa ánh sáng tự nhiên.


<br/>
<b>Tiện ích nội khu:<br/></b>
Sân vườn cảnh quan: Được thiết kế theo phong cách vườn nhiệt đới, mang đến không gian xanh mát, yên tĩnh. Có thể bao gồm: hồ bơi riêng, khu vực BBQ, vườn hoa, vườn cây ăn quả...
Khu spa, gym, clubhouse cá nhân: Là không gian riêng biệt dành cho gia chủ, tạo nên chốn nghỉ dưỡng thuộc quyền sở hữu của riêng mình. Bao gồm: phòng tập gym, phòng xông hơi, phòng massage, phòng giải trí, vườn trà...

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/TV4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/TV6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/TV7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Sử dụng các vật liệu cao cấp, mang đến cho căn hộ sự sang trọng, đẳng cấp.
Thiết kế nội thất thông minh, tối ưu hóa công năng sử dụng.
Hệ thống chiếu sáng thông minh, mang đến sự ấm áp, tiện nghi cho căn hộ.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/TV9.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/TV3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/TV4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/TV7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/TV2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
