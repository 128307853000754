import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Xây Dựng </a>
            <a href="#"> Nội Thất </a>``
          </div>
          <h1 className="title"> 
          BIỆT THỰ ĐẢO ECOPARK
            </h1>
          <div className="text color-666 mt-30">
          Nét sang trọng hiện đại trong không gian mở
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m89.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Biệt thự đảo Ecopark là nơi hội tụ tinh hoa của phong cách sống hiện đại, tạo nên một không gian sống sang trọng, thoáng đãng và đầy đủ tiện nghi cho gia chủ. Với thiết kế nội thất theo phong cách hiện đại, biệt thự mang đến cảm giác mở rộng, sáng tạo và luôn toát lên sự thanh lịch, tinh tế.

          </div>
          
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch Vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                          Thiết kế <br /> Xây Dựng
                          <br /> Bảo Hiểm
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hà Nội

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        450 m2

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i89_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i89_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Phong cách hiện đại sang trọng được lựa chọn bởi sự phù hợp với lối sống năng động, hiện đại của gia chủ, đồng thời tạo nên một không gian sống thoáng đãng, tiện nghi và đầy đủ ánh sáng tự nhiên. Phong cách này thể hiện sự tinh tế, sang trọng và cá tính của gia chủ.

          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Không gian mở:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tận dụng tối đa diện tích, tạo nên một không gian liên thông giữa các tầng, giúp tăng cường sự thoáng đãng, rộng rãi cho ngôi nhà.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> TSử dụng cầu thang kính hoặc thép tạo cảm giác mở rộng, đồng thời giúp ánh sáng tự nhiên từ sân thượng tràn vào phòng khách.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Màu sắc:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng gam màu trung tính như trắng, be, xám, kết hợp với những điểm nhấn màu sắc nổi bật như xanh đen, vàng đồng, tạo nên sự hài hòa, sang trọng cho không gian.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Màu sắc được lựa chọn sao cho phù hợp với phong cách hiện đại, tạo cảm giác mở rộng, thoáng đáng cho phòng khách.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Vật liệu:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng vật liệu cao cấp, sang trọng như gỗ tự nhiên, đá onyx, kính cường lực, thép không gỉ, tạo nên sự tinh tế, sang trọng cho nội thất.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Gỗ tự nhiên được sử dụng cho bàn ghế, kệ ti vi, tạo nên vẻ đẹp sang trọng, ấm cúng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Đá onyx được sử dụng cho mặt bàn, bệ lửa, tạo nên sự sang trọng, tinh tế cho phòng khách.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Kính cường lực được sử dụng cho vách ngăn, cửa sổ, tạo nên sự thoáng đáng, rộng rãi cho không gian. 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Thép không gỉ được sử dụng cho khung bàn, chân ghế, tạo nên sự hiện đại, sang trọng cho nội thất.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Nội thất:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng nội thất thiết kế đơn giản, gọn gàng, tạo nên sự thoáng đáng, tiện dụng cho phòng khách.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng sofa da cao cấp, bàn ghế đơn giản, kết hợp với kệ ti vi hiện đại, tạo nên sự sang trọng, thanh lịch cho không gian.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng đèn trang trí hiện đại, tạo nên điểm nhấn cho không gian, đồng thời tăng cường ánh sáng cho phòng khách.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
        Ưu điểm của phương án thiết kế:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Không gian mở, thoáng đáng, tiện dụng, tạo cảm giác rộng rãi cho phòng khách.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng vật liệu cao cấp, sang trọng, tạo nên sự tinh tế, thanh lịch cho nội thất.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Màu sắc hài hòa, sang trọng, tạo nên một không gian sống ấn tượng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Thiết kế nội thất hiện đại, phù hợp với lối sống năng động, hiện đại của gia chủ.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Với thiết kế nội thất hiện đại sang trọng, biệt thự đảo Ecopark hứa hẹn sẽ mang đến một không gian sống thoải mái, tiện nghi và đầy đủ ánh sáng tự nhiên cho gia chủ. 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                src="/innerpages/assets/img/s_project/pj89_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                src="/innerpages/assets/img/s_project/pj89_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                src="/innerpages/assets/img/s_project/pj89_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           {/* tieu de tong ket */}
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Ngôi nhà không chỉ là nơi ở mà còn là một tác phẩm kiến trúc độc đáo, thể hiện sự tinh tế, sang trọng và cá tính của gia chủ.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj89_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj89_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj89_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj89_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj89_1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
