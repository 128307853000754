import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <h1 className="title"> KHU ĐÔ THỊ NINH BA </h1>
          <div className="text color-666 mt-30">
          Định Hình Tương Lai Đô Thị Thông Minh
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m4.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Phương án thi tuyển quốc tế quy hoạch khu đô thị thông minh tại Ninh Ba, Chiết Giang, được xây dựng dựa trên mục tiêu tạo ra một mô hình đô thị tiên tiến, nơi công nghệ và sự bền vững được tích hợp một cách thông minh để phục vụ cuộc sống của cư dân.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Dịch vụ: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Quy hoạch chi tiết
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Ninh Ba, Chiết Giang, Trung Quốc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích:
                        </small>
                        <div className="links fsz-18 fw-500">
                        12.2ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thi Tuyển
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img1_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img2_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tầm nhìn:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Xây dựng một khu đô thị thông minh hàng đầu, nơi mọi hoạt động đều được hỗ trợ bởi công nghệ tiên tiến, từ quản lý năng lượng đến giao thông và dịch vụ công cộng.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Các yếu tố chính:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Công nghệ: Áp dụng các giải pháp IoT (Internet of Things) để quản lý thông tin và tài nguyên một cách hiệu quả.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Giao thông thông minh: Hệ thống giao thông tự động và kết nối, giảm thiểu tắc nghẽn và ô nhiễm.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Năng lượng sạch: Sử dụng năng lượng tái tạo và hệ thống quản lý năng lượng thông minh để giảm thiểu tác động môi trường.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Không gian xanh: Tích hợp không gian xanh trong quy hoạch để cải thiện chất lượng không khí và tạo không gian thư giãn cho cư dân.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Lợi ích cộng đồng:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sức khỏe và an toàn: Cung cấp môi trường sống sạch sẽ, an toàn với hệ thống giám sát và phản ứng nhanh chóng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Kết nối cộng đồng: Tạo điều kiện cho cư dân giao lưu và kết nối thông qua các ứng dụng và dịch vụ trực tuyến.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Phát triển kinh tế: Thu hút đầu tư và tạo ra cơ hội việc làm thông qua việc phát triển các ngành công nghệ cao.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Chiến lược thực hiện:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Phát triển theo từng giai đoạn, với việc triển khai các dự án pilot trước khi mở rộng quy mô.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Hợp tác với các công ty công nghệ hàng đầu để đảm bảo việc áp dụng các giải pháp tiên tiến nhất.
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/c1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/c2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/c3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng Kết:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tập trung vào việc giáo dục và đào tạo cư dân để họ có thể tận dụng tối đa lợi ích của một đô thị thông minh.
            <br />
            Khu đô thị thông minh tại Ninh Ba, Chiết Giang, không chỉ là một dự án quy hoạch đô thị mà còn là một bước tiến quan trọng trong việc áp dụng công nghệ để cải thiện cuộc sống hàng ngày. Đây sẽ là một mô hình cho sự phát triển đô thị thông minh, bền vững và thân thiện với môi trường trong tương lai.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/c4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/c5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/c1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/c2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/c3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
