import React from 'react';

function RelatedProjects() {
  return (
    <section className="tc-related-projects-style1">
      <div className="container">
        <div
          className="row align-items-center wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          <div className="col-lg-9">
            <h3 className="fsz-45 text-capitalize">Các dự án khác </h3>
          </div>
          <div className="col-lg-3 text-lg-end mt-4 mt-lg-0">
            <a
              href="/PortfolioPage"
              className="butn border rounded-pill color-orange1 border-orange1 hover-bg-orange1"
            >
              <span>
                Xem tất cả dự án <i className="fal fa-arrow-up-right ms-2"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="content mt-50">
        <div
          className="tab-content wow fadeInUp slow float_box_container"
          data-wow-delay="0.4s"
          id="pills-tabContent"
        >
          <div className="tab-pane fade show active" id="pills-tab1">
            <div className="cases-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="case-card">
                    <a
                      href="/SingleProject31"
                      className="img"
                      
                    >
                      <img
                        src="/innerpages/assets/img/s_project/m31.jpg"
                        alt=""
                        className="img-cover"
                      />
                    </a>
                    <div className="info">
                      <div className="tags mb-30">
                        <a href="/PortfolioPage1"> Thiết kế </a>
                        <a href="/PortfolioPage"> Biệt thự </a>
                      </div>
                      <h3 className="title fsz-35 mb-20">
                        <a href="/SingleProject31" className="hover-orange1">
                        Maison Hue - Tái sinh Kiệt tác
                        </a>
                      </h3>
                      <div className="text color-666">
                      Nơi thanh bình chạm đến tinh hoa
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="case-card">
                    <a
                      href="/SingleProject08"
                      className="img"
                      
                    >
                      <img
                        src="/innerpages/assets/img/s_project/m8.jpg"
                        alt=""
                        className="img-cover"
                      />
                    </a>
                    <div className="info">
                      <div className="tags mb-30">
                        <a href="/PortfolioPage"> Quy Hoạch </a>
                        <a href="/PortfolioPage"> Nghỉ dưỡng </a>
                      </div>
                      <h3 className="title fsz-35 mb-20">
                        <a href="/SingleProject08" className="hover-orange1">
                        LAM HILL
                        </a>
                      </h3>
                      <div className="text color-666">
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="case-card">
                    <a
                      href="/SingleProject"
                      className="img"
                      
                    >
                      <img
                        src="/innerpages/assets/img/projects/vtc.jpg"
                        alt=""
                        className="img-cover"
                      />
                    </a>
                    <div className="info">
                      <div className="tags mb-30">
                        <a href="/PortfolioPage"> Quy Hoạch </a>
                        <a href="/PortfolioPage"> Đô thị </a>
                      </div>
                      <h3 className="title fsz-35 mb-20">
                        <a href="/SingleProject" className="hover-orange1">
                        Green Phương Đông Valley
                        </a>
                      </h3>
                      <div className="text color-666">
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="case-card">
                    <a
                      href="/SingleProject24"
                      className="img"
                      
                    >
                      <img
                        src="/innerpages/assets/img/s_project/m24.png"
                        alt=""
                        className="img-cover"
                      />
                    </a>
                    <div className="info">
                      <div className="tags mb-30">
                        <a href="/PortfolioPage1"> Thiết kế </a>
                        <a href="/PortfolioPage1"> Ngoại Thất </a>
                      </div>
                      <h3 className="title fsz-35 mb-20">
                        <a href="/SingleProject24" className="hover-orange1">
                        LY HOUSE
                        </a>
                      </h3>
                      <div className="text color-666">
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="case-card">
                    <a
                      href="/SingleProject89"
                      className="img"
                      
                    >
                      <img
                        src="/innerpages/assets/img/s_project/m89.jpg"
                        alt=""
                        className="img-cover"
                      />
                    </a>
                    <div className="info">
                      <div className="tags mb-30">
                        <a href="/PortfolioPage1"> Thiết kế </a>
                        <a href="/PortfolioPage1"> Nội Thất </a>
                      </div>
                      <h3 className="title fsz-35 mb-20">
                        <a href="/SingleProject" className="hover-orange1">
                        BIỆT THỰ ĐẢO ECOPARK
                        </a>
                      </h3>
                      <div className="text color-666">
                      Nét sang trọng hiện đại trong không gian mở
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="float-cursor float_box">
            Hold <br /> and Drag
          </div>
        </div>
      </div>
    </section>
  );
}

export default RelatedProjects;
