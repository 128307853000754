import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất </a>
           
          </div>
          <h1 className="title"> MINH ĐỨC TOWER </h1>
          <div className="text color-666 mt-30">
          Nét hiện đại giữa cao nguyên
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m26.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Minh Đức Tower, tọa lạc tại Đắk Lắk, là một công trình kiến trúc tòa nhà văn phòng được thiết kế theo phong cách hiện đại, mang đến một không gian làm việc năng động, hiệu quả và đầy cảm hứng. Công trình được thiết kế với những đường nét kiến trúc mạnh mẽ, những gam màu tươi sáng, những vật liệu hiện đại, tạo nên một diện mạo ấn tượng, phù hợp với nhịp sống năng động của thành phố trẻ.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Đắk Lắk
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        520 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thiết kế kiến trúc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/MD2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/MD3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Thiết kế kiến trúc<br/>
          Thân thiện môi trường: Công trình được thiết kế với những giải pháp tiết kiệm năng lượng, bảo vệ môi trường, góp phần xây dựng một môi trường làm việc xanh, sạch, đẹp.<br/>
          Tối ưu công năng sử dụng: Minh Đức Tower được bố trí khoa học, tạo sự thông thoáng, tiện lợi cho việc di chuyển và làm việc. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng bộ phận.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền ấn tượng: Minh Đức Tower sở hữu một mặt tiền ấn tượng với những đường nét kiến trúc mạnh mẽ, những gam màu tươi sáng, những vật liệu hiện đại. Điểm nhấn của mặt tiền là hệ thống cửa sổ lớn, tạo nên một diện mạo thoáng đãng, thu hút.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian bên trong.<br/>
Ban công rộng rãi: Ban công rộng rãi được thiết kế để tạo không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/MD4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/MD5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/MD6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất thông minh: Minh Đức Tower được thiết kế với nội thất thông minh, tối ưu hóa không gian sử dụng, tạo nên một không gian làm việc tiện nghi, hiện đại.<br/>
          Vật liệu hiện đại: Công trình sử dụng những vật liệu hiện đại, thân thiện với môi trường, mang đến vẻ đẹp sang trọng và bền vững.<br/>
          Gam màu tươi sáng: Gam màu tươi sáng được sử dụng trong trang trí nội thất, tạo cảm giác năng động, tươi mới cho không gian làm việc.<br/>
<b>Tiện ích:<br/></b>
Hệ thống thang máy hiện đại: Hệ thống thang máy hiện đại được trang bị đầy đủ, đáp ứng nhu cầu di chuyển nhanh chóng, thuận tiện cho nhân viên.<br/>
Hệ thống an ninh: Hệ thống an ninh được trang bị đầy đủ, đảm bảo an toàn cho tài sản và con người.<br/>
Hệ thống phòng cháy chữa cháy: Hệ thống phòng cháy chữa cháy được trang bị đầy đủ, đảm bảo an toàn cho tòa nhà.<br/>
Khu vực nghỉ ngơi: Khu vực nghỉ ngơi được thiết kế hiện đại, tạo không gian thư giãn cho nhân viên.<br/>


          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/MD5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/MD6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/MD7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/MD8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/MD9.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
