import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="description">
        <div className="containers"  >
          <h1
            className=" fsz-45 wow fadeInUp slow "
            data-wow-delay="0.2s"
           
          >
            Chính sách bảo mật thông tin cá nhân
          </h1>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h4
            className="sub-title mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            1.    Mục đích thu thập: 
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Chúng tôi thu thập thông tin cá nhân chỉ cần thiết nhằm phục vụ cho các mục đích:
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         -      Dịch Vụ Chăm Sóc Khách Hàng: bao gồm các phản hồi cho các yêu cầu, khiếu nại và phản hồi của bạn;
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         -      An Ninh: cho các mục đích ngăn ngừa các hoạt động phá hủy tài khoản người dùng của khách hàng hoặc các hoạt động giả mạo khách hàng.      
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         -      Theo yêu cầu của pháp luật: tùy quy định của pháp luật vào từng thời điểm, chúng tôi có thể thu thập, lưu trữ và cung cấp theo yêu cầu của cơ quan nhà nước có thẩm quyền.     
          </div>
          <h4
            className="sub-title  mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           2.    Phạm vi thu thập: 
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Chúng tôi thu thập thông tin khách hàng khi:
          <br />
          <br />
            <ul
            className="dots-list fsz-18 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <span className="icon me-3"> • </span>Khách hàng giao dịch trực tiếp với chúng tôi
          <li className="d-flex my-4">Thông tin cá nhân của các bạn cung cấp chủ yếu trên trang website của chúng tôi nexcon.vn. Thông tin bao gồm: Họ tên, ngày sinh, địa chỉ, số điện thoại, email, tên đăng nhập và mật khẩu (Tài khoản đăng nhập), câu hỏi/ câu trả lời bảo mật.</li>  
          <li className="d-flex my-4">Chi tiết yêu cầu tư vấn của bạn được chúng tôi lưu giữ nhưng vì lý do bảo mật nên chúng tôi không công khai trực tiếp được. </li>  
          <li className="d-flex my-4">Chúng tôi sử dụng cookies và công nghệ dấu khác để thu thập một số thông tin khi bạn gửi yêu cầu tư vấn.</li>  
          <li className="d-flex my-4">Chúng tôi dùng cookie để tiện cho bạn vào trang web (ví dụ: ghi nhớ tên truy cập khi bạn muốn vào thay đổi lại giỏ mua hàng mà không cần phải nhập lại địa chỉ email của mình) và không đòi hỏi bất kỳ thông tin nào về bạn (ví dụ: mục tiêu quảng cáo).Trình duyệt của bạn có thể được thiết lập không sử dụng cookie nhưng điều này sẽ hạn chế quyền sử dụng của bạn trên trang web. Xin vui lòng chấp nhận cam kết của chúng tôi là cookie không bao gồm bất cứ chi tiết cá nhân riêng tư nào và an toàn với virus. </li>  
           </ul>
           <ul
            className="dots-list fsz-18 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <span className="icon me-3"> • </span>Các nguồn hợp pháp khác
          </ul>
          <li className="d-flex my-4">Chúng tôi có thể thu thập thông tin của bạn trên các nguồn hợp pháp khác.</li>
          </div>
          <h4
            className="sub-title  mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          3.    Thời gian lưu trữ
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          ><ul
            className="dots-list fsz-18 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <li className="d-flex my-4"><span className="icon me-3"> • </span>Thông tin khách hàng được lưu trữ cho đến khi nhận được yêu cầu huỷ bỏ của khách hàng, hoặc khách hàng tự đăng nhập để huỷ bỏ. </li>
           <li className="d-flex my-4"><span className="icon me-3"> • </span>Mọi thông tin của khách hàng đều được lưu trữ trên máy chủ của nexcon.vn</li>
            </ul>
          </div>
          <h4
            className="sub-title  mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          4.    Thông tin Khách hàng đối với bên thứ ba
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <ul
            className="dots-list fsz-18 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Chúng tôi cam kết không cung cấp thông tin khách hàng với bất kì bên thứ ba nào, trừ những trường hợp sau:
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Các đối tác là bên cung cấp dịch vụ cho chúng tôi liên quan đến thực hiện đơn hàng và chỉ giới hạn trong phạm vi thông tin cần thiết cũng như áp dụng các quy định đảm bảo an ninh, bảo mật các thông tin cá nhân.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Chúng tôi có thể sử dụng dịch vụ từ một nhà cung cấp dịch vụ là bên thứ ba để thực hiện một số hoạt động liên quan đến trang mua sắm điện tử Coolmate.me và khi đó bên thứ ba này có thể truy cập hoặc xử lý các thông tin cá nhân trong quá trình cung cấp các dịch vụ đó. Chúng tôi yêu cầu các bên thứ ba này tuân thủ mọi luật lệ về bảo vệ thông tin cá nhân liên quan và các yêu cầu về an ninh liên quan đến thông tin cá nhân.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Các chương trình có tính liên kết, đồng thực hiện, thuê ngoài cho các mục địch được nêu tại Mục 1 và luôn áp dụng các yêu cầu bảo mật thông tin cá nhân.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập, hợp nhất toàn bộ hoặc một phần với công ty khác, người mua sẽ có quyền truy cập thông tin được chúng tôi lưu trữ, duy trì trong đó bao gồm cả thông tin cá nhân.</li>
          </ul>
          </div>
          <h4
            className="sub-title  mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          5.    An toàn dữ liệu
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <ul
            className="dots-list fsz-18 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Chúng tôi luôn nỗ lực để giữ an toàn thông tin cá nhân của khách hàng, chúng tôi đã và đang thực hiện nhiều biện pháp an toàn, bao gồm:
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Bảo đảm an toàn trong môi trường vận hành: chúng tôi lưu trữ thông tin cá nhân khách hàng trong môi trường vận hành an toàn và chỉ có nhân viên, đại diện và nhà cung cấp dịch vụ có thể truy cập trên cơ sở cần phải biết. Chúng tôi tuân theo các tiêu chuẩn ngành, pháp luật trong việc bảo mật thông tin cá nhân khách hàng.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân khách hàng, chúng tôi sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng để điều tra xử lý kịp thời và thông báo cho khách hàng được biết.</li>
            <li className="d-flex my-4"><span className="icon me-3"> • </span>Các thông tin thanh toán: được bảo mật theo tiêu chuẩn ngành.</li>
            </ul>
          </div>
          <br />
          <br />

          <h4
            className="sub-title mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            6.    Quyền lợi của khách hàng đối với thông tin cá nhân
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         -        Khách hàng có quyền cung cấp thông tin cá nhân cho chúng tôi và có thể thay đổi quyết định đó vào bất cứ lúc nào.
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         -        Khách hàng có quyền tự kiểm tra, cập nhật, điều chỉnh thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu chúng tôi thực hiện việc này.     
          </div>
          <br />
          <br />
      <h4
            className="sub-title mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           7. Yêu cầu xóa bỏ thông tin cá nhân
          </h4>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >- Khách hàng có quyền yêu cầu xóa bỏ hoàn toàn các thông tin cá nhân lưu trữ trên hệ thống của chúng tôi bất cứ khi nào</div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >- Khách hàng gửi thư điện tử về địa chỉ <a className="link-to-mail" href="/ContactPage">sales.nexcon@gmail.com</a> để yêu cầu xóa bỏ thông tin cá nhân hoàn toàn khỏi hệ thống.</div>
        </div>
      </div>
    </section>
  );
}

export default Project;
