import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Nhà Mặt Phố Hướng Tây: Thách Thức & Giải Pháp Thiết Kế Thông Minh
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Giới thiệu:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Hà Nội, trái tim nhịp đập của đất nước, đang từng ngày thay da đổi thịt với những công trình kiến trúc hiện đại. Trong đó, nhà mặt phố hướng Tây, Tây Nam và Tây Bắc đang là lựa chọn của nhiều gia đình. Tuy nhiên, việc xây nhà hướng Tây cũng đi kèm những thách thức về khí hậu, ánh sáng và nhiệt độ, đòi hỏi chủ đầu tư phải có kiến thức và kinh nghiệm để đưa ra những giải pháp thiết kế tối ưu.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Bài viết này sẽ phân tích đánh giá các nghiên cứu liên quan đến tổ chức không gian nhà mặt phố xây mới các hướng Tây, Tây Nam và Tây Bắc ở Hà Nội, tập trung vào những vấn đề bất lợi của nhà hướng Tây và những lưu ý cần thiết khi xây dựng. Bên cạnh đó, bài viết sẽ giới thiệu về Công ty thiết kế thi công NEXCON, một thành viên của tập đoàn Nexholding, với những giải pháp thiết kế chuyên nghiệp, giúp tối ưu hóa không gian sống cho nhà hướng Tây.
              </h6>
          <br />
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b25_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b25_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2. Những Thách Thức Khi Xây Nhà Hướng Tây:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2.1. Ánh Nắng Mạnh và Nóng:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vấn đề: Hướng Tây tiếp nhận ánh nắng chiều trực tiếp, cường độ ánh sáng mạnh và nhiệt độ tăng cao, đặc biệt là vào mùa hè. Điều này gây ra nhiều vấn đề:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Ảnh hưởng sức khỏe: Gây mệt mỏi, khó chịu, thậm chí là các bệnh về da liễu như cháy nắng, ung thư da.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tiêu hao năng lượng: Tăng chi phí điện năng sử dụng điều hòa, làm tăng lượng khí thải, ảnh hưởng đến môi trường.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Làm giảm tuổi thọ vật liệu: Gây phai màu, nứt nẻ, biến dạng cho nội thất, đặc biệt là những vật liệu dễ bị ảnh hưởng bởi nhiệt như gỗ, da, vải.</h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về bức xạ mặt trời: Các nghiên cứu đã chỉ ra rằng ánh sáng mặt trời có cường độ bức xạ cao, đặc biệt là vào mùa hè. Bức xạ này có thể gây nóng bức, ảnh hưởng đến sức khỏe và môi trường.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về vật liệu cách nhiệt: Các nghiên cứu đã chứng minh hiệu quả của các vật liệu cách nhiệt trong việc giảm thiểu nhiệt lượng truyền vào bên trong công trình. Ví dụ như gạch mát, kính phản quang, sơn cách nhiệt...
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về hệ thống thông gió: Các nghiên cứu đã chứng minh hiệu quả của hệ thống thông gió tự nhiên và thông gió cơ học trong việc tạo ra luồng không khí mát, giảm nhiệt độ bên trong công trình.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >2.2. Thiếu Ánh Sáng Tự Nhiên vào Buổi Sáng:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vấn đề: Hướng Tây thường bị che khuất bởi các tòa nhà xung quanh, gây thiếu ánh sáng tự nhiên vào buổi sáng. Điều này ảnh hưởng đến:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tâm trạng, sức khỏe: Gây cảm giác uể oải, mệt mỏi, ảnh hưởng đến tinh thần, hiệu quả làm việc.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Khó khăn trong bố trí nội thất: Khó khăn trong việc bố trí nội thất, tạo cảm giác chật chội, thiếu sáng, ảnh hưởng đến thẩm mỹ.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về ánh sáng tự nhiên: Các nghiên cứu đã chứng minh tác động tích cực của ánh sáng tự nhiên đối với sức khỏe, tâm trạng và hiệu quả làm việc.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về thiết kế cửa sổ: Các nghiên cứu về thiết kế cửa sổ, kích thước, vị trí và vật liệu giúp tối ưu hóa việc đón ánh sáng tự nhiên.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Nghiên cứu về màu sắc: Các nghiên cứu về màu sắc cho thấy ảnh hưởng của màu sắc đối với tâm trạng, cảm giác về không gian và khả năng phản chiếu ánh sáng.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3. Giải Pháp Thiết Kế Cho Nhà Hướng Tây:
              <br />
              <br />
              3.1. Vật Liệu Xây Dựng:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vật liệu cách nhiệt:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Gạch mát: Gạch mát có khả năng hấp thụ nhiệt thấp, giúp giảm nhiệt độ bên trong công trình, tạo cảm giác mát mẻ.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Kính phản quang: Kính phản quang có khả năng phản chiếu ánh sáng mặt trời, giảm thiểu lượng nhiệt truyền vào bên trong.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Sơn cách nhiệt: Sơn cách nhiệt có khả năng phản chiếu ánh sáng mặt trời, giảm nhiệt độ bề mặt tường, tạo cảm giác mát mẻ.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vật liệu cách âm:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Bông thủy tinh: Bông thủy tinh có khả năng cách âm, cách nhiệt, giúp giảm thiểu tiếng ồn từ bên ngoài.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Xốp EPS: Xốp EPS cũng là vật liệu cách âm, cách nhiệt hiệu quả, thường được sử dụng cho tường, trần nhà.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >PU foam: PU foam có khả năng cách âm, cách nhiệt tốt, thường được sử dụng cho cửa sổ, cửa ra vào.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.2. Thiết Kế Không Gian:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tận dụng bóng râm: Xây dựng mái hiên, lam che nắng, trồng cây xanh để tạo bóng râm cho mặt tiền, giảm cường độ ánh nắng chiếu vào nhà.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thiết kế cửa sổ: Thiết kế cửa sổ thông minh, có thể điều chỉnh ánh sáng, hướng gió, đảm bảo sự thông thoáng và chiếu sáng cho ngôi nhà.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Bố trí nội thất: Bố trí nội thất hợp lý, tận dụng ánh sáng tự nhiên, tạo sự thông thoáng, dễ chịu cho ngôi nhà.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Sử dụng màu sắc: Sử dụng màu sắc sáng, mát mẻ cho tường, nội thất, tạo cảm giác rộng rãi, thoáng đãng.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Hệ thống thông gió: Thiết kế hệ thống thông gió tự nhiên hiệu quả, tận dụng gió mát từ hướng Đông.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.3. Hệ Thống Điều Hòa:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Lựa chọn loại điều hòa phù hợp: Lựa chọn loại điều hòa phù hợp với diện tích và nhu cầu sử dụng, tiết kiệm năng lượng, thân thiện môi trường.
<br />Kết hợp với hệ thống thông gió: Kết hợp sử dụng điều hòa với hệ thống thông gió tự nhiên để tối ưu hóa hiệu quả làm mát, giảm thiểu chi phí điện năng.
</h6>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b25_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b25_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b25_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Giới thiệu về Công ty Thiết Kế Thi Công NEXCON:
        </h6>
        <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Khái Quát về NEXCON:
<br />NEXCON, một thành viên của tập đoàn Nexholding, là công ty chuyên cung cấp dịch vụ thiết kế thi công kiến trúc nội thất cho các dự án nhà ở, biệt thự, văn phòng, khách sạn... Với đội ngũ kiến trúc sư, kỹ sư giàu kinh nghiệm và tâm huyết, NEXCON cam kết mang đến cho khách hàng những công trình chất lượng cao, thẩm mỹ, đáp ứng đầy đủ nhu cầu sử dụng.
</h6>
<br />
<h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Lợi Ích Khi Sử Dụng Dịch Vụ của NEXCON:
<br />Kiến trúc sư chuyên nghiệp: Đội ngũ kiến trúc sư giàu kinh nghiệm, am hiểu sâu sắc về kiến trúc và thiết kế nội thất, tư vấn và thiết kế theo phong cách phù hợp với nhu cầu của khách hàng.<br />Công nghệ thi công tiên tiến: Áp dụng công nghệ thi công tiên tiến, đảm bảo chất lượng công trình, tiến độ thi công đúng hẹn.
<br />Vật liệu chất lượng cao: Sử dụng vật liệu xây dựng, nội thất chất lượng cao, đảm bảo độ bền và thẩm mỹ cho công trình.
<br />Dịch vụ trọn gói: Cung cấp dịch vụ trọn gói từ thiết kế đến thi công, giúp khách hàng tiết kiệm thời gian và công sức.
<br />Giá cả cạnh tranh: Báo giá minh bạch, chi phí thi công hợp lý, phù hợp với khả năng tài chính của khách hàng.
</h6>
<br />
<h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5. Những Nghiên Cứu Trong Giải Pháp Thiết Kế Cho Nhà Hướng Tây:
        </h6>
        <br />
<h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.1. Nghiên Cứu về Vật Liệu Xây Dựng:
<br />Gạch mát: Nghiên cứu về vật liệu xây dựng cho thấy gạch mát có khả năng hấp thụ nhiệt thấp hơn so với gạch thông thường.<br />Ưu điểm: Giảm nhiệt độ bên trong công trình, tạo cảm giác mát mẻ, tiết kiệm năng lượng.
<br />Nhược điểm: Giá thành có thể cao hơn so với gạch thông thường.
<br />Kính phản quang: Nghiên cứu về kính phản quang cho thấy loại kính này có khả năng phản chiếu ánh sáng mặt trời hiệu quả.
<br />Ưu điểm: Giảm thiểu lượng nhiệt truyền vào bên trong công trình, bảo vệ nội thất khỏi bị phai màu, hư hỏng.
<br />Nhược điểm: Có thể làm giảm ánh sáng tự nhiên chiếu vào bên trong.
Sơn cách nhiệt: Nghiên cứu về sơn cách nhiệt cho thấy loại sơn này có khả năng phản chiếu ánh sáng mặt trời, giảm nhiệt độ bề mặt tường.
<br />Ưu điểm: Giảm nhiệt độ bên trong công trình, tạo cảm giác mát mẻ, tiết kiệm năng lượng.
<br />Nhược điểm: Giá thành có thể cao hơn so với sơn thông thường.
</h6>
        <br />
<h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.2. Nghiên Cứu về Thiết Kế Không Gian:
<br />Thiết kế thông gió: Nghiên cứu về thiết kế thông gió cho thấy hệ thống thông gió tự nhiên và thông gió cơ học có thể hiệu quả trong việc tạo ra luồng không khí mát, giảm nhiệt độ bên trong công trình.
<br />Ưu điểm: Tạo ra luồng không khí mát, giảm thiểu sử dụng điều hòa, tiết kiệm năng lượng.<br />Nhược điểm: Cần có thiết kế kỹ lưỡng, phù hợp với điều kiện khí hậu, địa hình.
<br />Sử dụng màu sắc: Nghiên cứu về màu sắc cho thấy ảnh hưởng của màu sắc đối với tâm trạng, cảm giác về không gian và khả năng phản chiếu ánh sáng.
<br />Ưu điểm: Tạo cảm giác rộng rãi, thoáng đãng, tạo tâm trạng thoải mái.
<br />Nhược điểm: Cần lựa chọn màu sắc phù hợp với phong cách thiết kế, sở thích của chủ nhà.
</h6>
        <br />
<h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.3. Nghiên Cứu về Cây Xanh:
<br />Cây xanh: Nghiên cứu về cây xanh cho thấy cây xanh có tác dụng giảm nhiệt độ, tạo bóng râm, tăng cường độ ẩm, cải thiện môi trường sống.
<br />Ưu điểm: Giảm nhiệt độ bên trong công trình, tạo không gian sống trong lành, thanh mát.
<br />Nhược điểm: Cần lựa chọn loại cây phù hợp với điều kiện khí hậu, đất đai, phù hợp với diện tích của ngôi nhà.
</h6>
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b25_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b25_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             6. Kết Luận:

            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
          Xây nhà mặt phố hướng Tây, Tây Nam và Tây Bắc ở Hà Nội cần lưu ý những vấn đề về nắng nóng, thiếu ánh sáng, gió mùa và ẩm ướt. Việc ứng dụng những nghiên cứu về vật liệu, thiết kế không gian, cây xanh và vật liệu xanh sẽ giúp tối ưu hóa không gian, tạo ra một ngôi nhà thoáng đãng, mát mẻ và đầy đủ tiện nghi.


            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Công ty thiết kế thi công NEXCON với kinh nghiệm và chuyên môn của mình, sẵn sàng đồng hành cùng quý khách hàng trong việc hiện thực hóa ngôi nhà mơ ước, phù hợp với nhu cầu và phong cách sống của mỗi gia đình. Hãy liên hệ với NEXCON để được tư vấn và hỗ trợ tốt nhất.


            </h6>
            <br />
           
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
