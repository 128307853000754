import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#">Nhựa trong Thiết Kế: Giải Pháp Tiềm Năng Cho Biến Đổi Khí Hậu</a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Trong bối cảnh biến đổi khí hậu toàn cầu đang trở nên cấp bách, ngành thiết kế và kiến trúc đang tìm kiếm những giải pháp sáng tạo và bền vững để giảm thiểu tác động tiêu cực đến môi trường. Nhựa, với những đặc tính ưu việt về độ bền, nhẹ, dễ gia công và chi phí thấp, đang nổi lên như một giải pháp tiềm năng cho ngành xây dựng, góp phần ứng phó với biến đổi khí hậu.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Ưu Điểm Của Nhựa Trong Thiết Kế:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Độ bền cao:</strong> Nhựa có khả năng chịu lực tốt, chống va đập, chịu nhiệt, chống ăn mòn, đảm bảo độ bền vững cho công trình trong thời gian dài, giảm thiểu nhu cầu sửa chữa, thay thế.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Trọng lượng nhẹ:</strong> Nhựa có trọng lượng nhẹ hơn nhiều so với các vật liệu truyền thống như bê tông, thép, giúp giảm tải trọng cho công trình, tiết kiệm năng lượng cho quá trình vận chuyển, thi công và giảm thiểu tác động đến nền móng.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Dễ gia công:</strong> Nhựa dễ dàng tạo hình, uốn cong, cắt gọt, giúp tạo ra những thiết kế độc đáo, linh hoạt, đáp ứng nhu cầu đa dạng của ngành xây dựng.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chi phí thấp:</strong> So với các vật liệu truyền thống, nhựa thường có giá thành thấp hơn, giúp giảm chi phí xây dựng, mang lại hiệu quả kinh tế cao cho các dự án.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thân thiện môi trường:</strong> Ngày nay, các loại nhựa tái chế, nhựa sinh học đang được ứng dụng rộng rãi trong ngành xây dựng, góp phần giảm thiểu lượng rác thải, bảo vệ môi trường.</h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Ứng Dụng Của Nhựa Trong Thiết Kế:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Vật liệu xây dựng:</strong> Nhựa được sử dụng để sản xuất các loại tấm ốp tường, tấm trần, cửa sổ, cửa ra vào, ống nước, gạch lát, giúp giảm thiểu sử dụng bê tông, thép, tiết kiệm năng lượng và giảm thiểu lượng khí thải.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nội thất:</strong> Nhựa được sử dụng để sản xuất bàn ghế, tủ, kệ, vật liệu trang trí nội thất, mang lại vẻ đẹp hiện đại, dễ lau chùi, bền bỉ, thân thiện môi trường.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết cấu công trình:</strong> Nhựa composite được ứng dụng trong xây dựng cầu, nhà, hệ thống đường ống, giúp giảm tải trọng, tăng cường độ bền, giảm chi phí.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Năng lượng mặt trời:</strong> Nhựa được sử dụng để sản xuất các tấm pin năng lượng mặt trời, giúp tận dụng năng lượng tái tạo, giảm thiểu sử dụng nhiên liệu hóa thạch.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Thách thức và cơ hội:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thách thức:</strong></h6>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Tuổi thọ: Một số loại nhựa có tuổi thọ thấp hơn so với các vật liệu truyền thống, cần phải được kiểm tra, bảo trì định kỳ.
              </h6>
            </li>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Vấn đề môi trường: Việc xử lý rác thải nhựa là vấn đề cần được quan tâm, phải đảm bảo các quy trình thu gom, phân loại, tái chế hiệu quả.
              </h6>
            </li>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Nhận thức: Cần nâng cao nhận thức của cộng đồng về vai trò của nhựa trong xây dựng, giúp mọi người hiểu rõ hơn về các ưu điểm và hạn chế của vật liệu này.
              </h6>
            </li>
          </ul>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Cơ hội:</strong></h6>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Công nghệ tiên tiến: Công nghệ sản xuất nhựa đang ngày càng phát triển, cho ra đời các loại nhựa có độ bền cao, chống cháy, chống ăn mòn tốt hơn, thân thiện môi trường hơn.
              </h6>
            </li>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Xu hướng xanh: Nhu cầu về các giải pháp xây dựng bền vững đang ngày càng tăng cao, tạo cơ hội cho nhựa khẳng định vị thế trong ngành xây dựng.
              </h6>
            </li>
          <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Đầu tư: Các chính phủ và doanh nghiệp đang đầu tư nhiều hơn vào nghiên cứu và phát triển các loại nhựa thân thiện môi trường, góp phần thúc đẩy ứng dụng nhựa trong ngành xây dựng.
              </h6>
            </li>
          </ul>
            </li>
          </ul>
              </div>
              <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >4. Hành Động Cho Tương Lai:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nghiên cứu và phát triển:</strong> Đầu tư vào nghiên cứu và phát triển các loại nhựa mới có độ bền cao, thân thiện môi trường, tái chế được, góp phần giải quyết các vấn đề về tuổi thọ và xử lý rác thải nhựa.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chính sách hỗ trợ:</strong> Các chính phủ nên ban hành các chính sách hỗ trợ và khuyến khích việc sử dụng nhựa trong xây dựng, tạo điều kiện thuận lợi cho doanh nghiệp phát triển các sản phẩm, công nghệ nhựa thân thiện môi trường.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao nhận thức:</strong> Tăng cường tuyên truyền, phổ biến kiến thức về nhựa trong xây dựng, giúp cộng đồng hiểu rõ hơn về vai trò, ưu điểm và hạn chế của vật liệu này.</h6>
            </li>
          </ul>
              </div>            
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
           Kết Luận:</h6>
           <br />
             <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Nhựa là một giải pháp tiềm năng cho ngành xây dựng, góp phần ứng phó với biến đổi khí hậu. Với những ưu điểm vượt trội về độ bền, nhẹ, dễ gia công, chi phí thấp và sự phát triển của công nghệ, nhựa sẽ tiếp tục đóng vai trò quan trọng trong việc xây dựng các công trình bền vững, thân thiện môi trường, giúp bảo vệ trái đất cho thế hệ mai sau. Cần có sự chung tay của các bên liên quan, từ Chính phủ, doanh nghiệp, đến người dân, để thúc đẩy việc ứng dụng nhựa một cách hiệu quả và bền vững trong ngành xây dựng.
            </h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
