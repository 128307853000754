import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Dinh Độc Lập: Giá Trị Văn Hóa Ẩn Sau Thiết Kế Kiến Trúc Độc Đáo Việt Nam </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Dinh Độc Lập, biểu tượng lịch sử và kiến trúc của Việt Nam, không chỉ là một công trình kiến trúc độc đáo mà còn là minh chứng cho sự kết hợp tinh tế giữa văn hóa truyền thống và ảnh hưởng của thế giới. Hơn thế nữa, Dinh Độc Lập còn là lời khẳng định về tinh thần độc lập, tự cường của dân tộc Việt Nam.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
             1. Hòa Quyện Giữa Truyền Thống Và Hiện Đại:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
             Dinh Độc Lập được xây dựng vào năm 1962, với kiến trúc độc đáo, kết hợp hài hòa giữa phong cách kiến trúc phương Tây và phương Đông.
              </h6>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phong cách kiến trúc phương Tây:</strong> Kiến trúc Dinh Độc Lập mang đậm dấu ấn của kiến trúc Pháp, với những đường nét thanh thoát, đơn giản, kết hợp với những khối hình vuông vắn, giống như những tòa nhà chính phủ thời Pháp thuộc.</h6> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phong cách kiến trúc phương Đông:</strong> Kiến trúc Dinh Độc Lập lại ẩn chứa những nét đặc trưng của kiến trúc truyền thống Việt Nam, như mái ngói cong vút, hệ thống cửa sổ, giếng trời, mang đến sự thoáng đãng, thoáng mát.</h6> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự kết hợp tinh tế:</strong> Sự kết hợp giữa hai phong cách kiến trúc tạo nên sự độc đáo, hấp dẫn cho Dinh Độc Lập. Kiến trúc Dinh Độc Lập thể hiện sự tinh tế, khéo léo trong việc kết hợp những yếu tố truyền thống và hiện đại, tạo nên một tổng thể hài hòa, mang đậm bản sắc Việt.</h6> 
            </li>
            </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Ý Nghĩa Văn Hóa Sau Kiến Trúc:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Biểu tượng của tinh thần độc lập:</strong> Dinh Độc Lập được xây dựng trong bối cảnh đất nước đang trong thời kỳ chiến tranh chống Mỹ, là biểu tượng cho sự độc lập, tự cường của dân tộc Việt Nam.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Khẳng định chủ quyền quốc gia:</strong> Dinh Độc Lập là nơi các vị lãnh đạo Việt Nam đã đưa ra những quyết sách quan trọng, góp phần bảo vệ chủ quyền quốc gia, đảm bảo hòa bình và độc lập cho đất nước.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết nối lịch sử và hiện tại:</strong> Dinh Độc Lập là minh chứng cho lịch sử đấu tranh giành độc lập của dân tộc Việt Nam, là nơi lưu giữ những kỷ vật, hình ảnh, tài liệu quý giá về lịch sử, văn hóa của đất nước.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Di sản văn hóa quốc gia:</strong>  Dinh Độc Lập là một di sản văn hóa quốc gia, thu hút đông đảo du khách trong và ngoài nước, góp phần quảng bá hình ảnh đất nước Việt Nam đến với bạn bè quốc tế. Dinh Độc Lập là một di sản văn hóa quốc gia, thu hút đông đảo du khách trong và ngoài nước, góp phần quảng bá hình ảnh đất nước Việt Nam đến với bạn bè quốc tế.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Bài Học Từ Kiến Trúc Dinh Độc Lập:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
              <li className="d-flex my-4">
                <span className="icon me-3"> • </span>
                <h6
                  className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                  data-wow-delay="0.2s"
                ><strong>Kết hợp truyền thống và hiện đại:</strong> Kiến trúc Việt Nam cần kế thừa, phát huy những giá trị văn hóa truyền thống, đồng thời kết hợp với yếu tố hiện đại, tạo ra những công trình độc đáo, mang đậm bản sắc Việt.</h6>
              </li>
              <li className="d-flex my-4">
                <span className="icon me-3"> • </span>
                <h6
                  className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                  data-wow-delay="0.2s"
                ><strong>Thể hiện tinh thần độc lập:</strong> Kiến trúc Việt Nam cần thể hiện tinh thần độc lập, tự cường của dân tộc, góp phần khẳng định vị thế của đất nước trên trường quốc tế.</h6>
              </li>
              <li className="d-flex my-4">
                <span className="icon me-3"> • </span>
                <h6
                  className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                  data-wow-delay="0.2s"
                ><strong>Bảo tồn và phát huy di sản:</strong> Cần có những chính sách, biện pháp để bảo tồn, phát huy giá trị di sản văn hóa, kiến trúc, để truyền tải những giá trị lịch sử, văn hóa của đất nước cho các thế hệ mai sau.</h6>
              </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            4. Kết luận:</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Dinh Độc Lập không chỉ là một công trình kiến trúc độc đáo, mà còn là biểu tượng cho tinh thần độc lập, tự cường của dân tộc Việt Nam. Kiến trúc Dinh Độc Lập là minh chứng cho sự kết hợp tinh tế giữa văn hóa truyền thống và ảnh hưởng của thế giới, góp phần tạo nên nét độc đáo, hấp dẫn cho kiến trúc Việt Nam. Hãy cùng chung tay bảo tồn và phát huy giá trị của Dinh Độc Lập, để giữ gìn những giá trị lịch sử, văn hóa quý báu của dân tộc Việt Nam.</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
