import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <h1 className="title">KHU ĐÔ THỊ NGHỈ DƯỠNG SUNNY BÌNH THUẬN</h1>
          <div className="text color-666 mt-30">
          Nghỉ Dưỡng Sinh Thái, Trải Nghiệm Đích Thực
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m5.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Dự án khu đô thị nghỉ dưỡng Sunny Bình Thuận tại Bình Thuận được thiết kế với mục tiêu tạo ra một không gian nghỉ dưỡng sinh thái, nơi hài hòa giữa thiên nhiên và các tiện ích hiện đại. Dịch vụ: Quy hoạch chi tiết
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Bình Thuận
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích:
                        </small>
                        <div className="links fsz-18 fw-500">
                        8.2ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img1_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img2_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Khu đô thị nghỉ dưỡng Sunny Bình Thuận nằm tại vùng đất có bãi biển đẹp và khí hậu nhiệt đới mát mẻ của Bình Thuận.
           <br />
           Mục tiêu chính là tạo ra một khu đô thị nghỉ dưỡng sinh thái, nơi cung cấp trải nghiệm nghỉ dưỡng đẳng cấp quốc tế với sự tôn trọng cao đối với môi trường tự nhiên.
          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Đặc điểm nổi bật:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Thiết kế sinh thái: Các khu vực nghỉ dưỡng được thiết kế để tối đa hóa sự hòa nhập với thiên nhiên, từ việc sử dụng năng lượng tái tạo đến việc bảo tồn đa dạng sinh học.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Cung cấp các tiện ích nghỉ dưỡng cao cấp như spa, nhà hàng, sân golf và các hoạt động giải trí ngoài trời.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Bảo vệ môi trường: Áp dụng các biện pháp bảo vệ môi trường nghiêm ngặt, từ việc xử lý nước thải đến việc giảm thiểu rác thải nhựa.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Lợi ích cộng đồng:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Phát triển du lịch: Góp phần vào sự phát triển du lịch bền vững của Bình Thuận, thu hút khách du lịch trong nước và quốc tế.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tạo việc làm: Tạo ra nhiều cơ hội việc làm cho người dân địa phương trong các lĩnh vực dịch vụ, quản lý và bảo trì.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Nâng cao chất lượng sống: Cung cấp một không gian sống và nghỉ dưỡng chất lượng cao cho cư dân và du khách.
            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Chiến lược thực hiện:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Hợp tác với các đối tác quốc tế để đảm bảo tiêu chuẩn quốc tế trong quy hoạch và xây dựng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tập trung vào việc đào tạo và phát triển nguồn nhân lực địa phương để họ có thể tham gia vào quá trình vận hành và quản lý dự án.
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/e1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/e2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/e3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng Kết:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Khu đô thị nghỉ dưỡng Sunny Bình Thuận hứa hẹn sẽ trở thành một điểm đến nghỉ dưỡng hàng đầu, nơi du khách có thể trải nghiệm sự yên bình và tận hưởng cuộc sống trong lành, gần gũi với thiên nhiên.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/e4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/e5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/e6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/e7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/e8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
