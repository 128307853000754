import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Biệt thự </a>``
          </div>
          <h1 className="title"> Maison Hue - Tái sinh Kiệt tác
          </h1>
          <div className="text color-666 mt-30">
          Nơi thanh bình chạm đến tinh hoa
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m31.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Nằm ẩn mình trong khung cảnh thơ mộng của khu nghỉ dưỡng 5 sao Maison Hue, dự án "Maison Hue - Tái sinh Kiệt tác" là hành trình tái tạo vẻ đẹp thanh tao cho những căn biệt thự và khách sạn, mang đến trải nghiệm nghỉ dưỡng hoàn toàn mới.

          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Với phương án kiến trúc đương đại, dự án tôn vinh sự giao hòa giữa con người và thiên nhiên, tạo nên một không gian sống hài hòa và độc đáo.

          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch vụ
                        </small>
                        <div className="links fsz-18 fw-500">
                         Thiết kế <br />
                         Xây Dựng <br />
                         Thi công
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Huế
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        1100 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thiết kế kiến trúc
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i31_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i31_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Những điểm nhấn chính:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Kiến trúc đương đại: Sử dụng ngôn ngữ kiến trúc hiện đại, tối giản, tạo nên sự tinh tế, phóng khoáng và gần gũi với thiên nhiên.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Hài hòa với bối cảnh: Các thiết kế được kết nối seamlessly với cảnh quan mặt nước, cây xanh trong khuôn viên resort, mang đến cảm giác thư giãn và thoải mái.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Ánh sáng tự nhiên: Hệ thống cửa kính lớn và giếng trời giúp đón ánh nắng tự nhiên, tạo không gian sống thoáng đãng và tràn đầy năng lượng.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Chất lượng cao cấp: Sử dụng vật liệu cao cấp, bền vững và thân thiện môi trường, mang đến sự sang trọng và đẳng cấp cho từng chi tiết.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tiện nghi hoàn hảo: Được trang bị đầy đủ tiện nghi hiện đại, phục vụ tối ưu nhu cầu nghỉ dưỡng của du khách.

            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Kết quả:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Nâng tầm đẳng cấp cho khu nghỉ dưỡng Maison Hue.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Mang đến trải nghiệm nghỉ dưỡng độc đáo, thư giãn và trọn vẹn.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tạo điểm nhấn thu hút du khách, khẳng định vị thế của Maison Hue là điểm đến nghỉ dưỡng lý tưởng.

            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj31_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj31_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj31_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Thông điệp:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           "Maison Hue - Tái sinh Kiệt tác" không chỉ là dự án nâng cấp cơ sở hạ tầng, mà còn là hành trình kiến tạo một không gian sống lý tưởng, nơi mà sự thanh bình và tinh hoa được kết nối hoàn hảo.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj31_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj31_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj31_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj31_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj31_8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
