import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất </a>
           
          </div>
          <h1 className="title"> GOLDEN PARK SHOP HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét sống động của hiện đại giữa thành phố biển
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m25.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Golden Park Shop House, tọa lạc tại khu đô thị mới Golden Park, Bình Thuận, là một công trình kiến trúc nhà ở liền kề được thiết kế theo phong cách hiện đại, phù hợp với nhịp sống năng động, tươi mới của thành phố du lịch ven biển. Công trình mang đến một không gian sống tiện nghi, thông minh, kết hợp hài hòa giữa kiến trúc và thiên nhiên, tạo nên một điểm nhấn độc đáo cho khu đô thị.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Bình Thuận
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        8.5 ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thiết kế kiến trúc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/GP2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m25.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Năng động và tươi mới: Golden Park Shop House được thiết kế theo phong cách hiện đại, mang đến một không gian sống năng động, tươi mới và đầy cảm hứng. Những đường nét kiến trúc mạnh mẽ, những gam màu sáng, những vật liệu hiện đại, tất cả tạo nên một diện mạo trẻ trung, thu hút.<br/>
          Tận dụng tối đa ánh sáng và gió: Công trình được thiết kế với những ô cửa sổ lớn, giếng trời và ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách. Không gian sống luôn tràn đầy ánh sáng và gió, mang đến cảm giác thoáng đãng, mát mẻ.<br/>
          Kết nối với thiên nhiên: Golden Park Shop House được thiết kế với những khoảng xanh, những mảng tường xanh, những khu vườn nhỏ, tạo nên một không gian sống gần gũi với thiên nhiên, tạo cảm giác thư giãn, thoải mái.<br/>
          Tiện ích đa dạng: Công trình được thiết kế với những tiện ích đa dạng, phù hợp với nhu cầu sống của cư dân, bao gồm: khu vực kinh doanh, khu vực sinh hoạt chung, khu vực nghỉ ngơi thư giãn, khu vực vui chơi giải trí...

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền ấn tượng: Golden Park Shop House sở hữu một mặt tiền ấn tượng với những đường nét kiến trúc mạnh mẽ, những gam màu sáng, những vật liệu hiện đại. Công trình được thiết kế theo phong cách tối giản, tạo nên một diện mạo trẻ trung, thu hút.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Ban công rộng rãi: Ban công rộng rãi được thiết kế để tạo không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m25.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/GP2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m25.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất thông minh: Golden Park Shop House được thiết kế với nội thất thông minh, tối ưu hóa không gian sử dụng, tạo nên một không gian sống tiện nghi, hiện đại.<br/>
          Vật liệu hiện đại: Công trình sử dụng những vật liệu hiện đại, thân thiện với môi trường, mang đến vẻ đẹp sang trọng và bền vững.<br/>
          Gam màu tươi sáng: Gam màu tươi sáng được sử dụng trong trang trí nội thất, tạo cảm giác năng động, tươi mới cho không gian sống.<br/>
          Kiến trúc hiện đại: Công trình được thiết kế theo phong cách hiện đại, mang đến một không gian sống năng động, tươi mới và đầy cảm hứng.<br/>
          Tiện ích đa dạng: Công trình được thiết kế với những tiện ích đa dạng, đáp ứng nhu cầu sống của cư dân.<br/>
          Kết nối với thiên nhiên: Công trình được thiết kế với những khoảng xanh, những mảng tường xanh, những khu vườn nhỏ, tạo nên một không gian sống gần gũi với thiên nhiên.<br/>
<br/>
Golden Park Shop House là một công trình kiến trúc nhà ở liền kề mang phong cách hiện đại, phù hợp với nhịp sống năng động, tươi mới của thành phố du lịch ven biển. Công trình là sự kết hợp hài hòa giữa kiến trúc hiện đại và những yếu tố truyền thống, tạo nên một không gian sống lý tưởng cho cư dân.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m25.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/GP2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/GP2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m25.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/GP2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
