import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Lợi Ích Không Thể Bỏ Qua Của Dịch Vụ Thiết Kế Và Thi Công Trọn Gói </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bạn đang ấp ủ giấc mơ về một ngôi nhà mơ ước, một không gian sống tiện nghi và đẹp như trong mơ? Nhưng bạn lại loay hoay với vô số công việc cần giải quyết: tìm kiếm kiến trúc sư, lựa chọn đơn vị thi công, quản lý vật liệu, giám sát tiến độ... Hãy yên tâm, dịch vụ thiết kế và thi công trọn gói đang ngày càng phổ biến tại Việt Nam sẽ giúp bạn giải quyết mọi khó khăn và biến giấc mơ thành hiện thực.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Sự Tiện Lợi Vượt Trội Của Dịch Vụ Trọn Gói
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Trong bối cảnh cuộc sống hiện đại, với guồng quay công việc bận rộn, việc tự mình quản lý và thực hiện dự án xây dựng trở nên khá vất vả. Dịch vụ thiết kế và thi công trọn gói ra đời như một giải pháp tối ưu, mang đến cho bạn vô số lợi ích:
              </h6>
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. Tiết Kiệm Thời Gian Và Năng Lượng:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Bạn không cần phải mất thời gian tìm kiếm, liên hệ với nhiều đơn vị khác nhau cho từng khâu thiết kế, thi công, mua vật liệu... Thay vào đó, bạn chỉ cần trao đổi ý tưởng với đội ngũ chuyên nghiệp của đơn vị cung cấp dịch vụ trọn gói. Họ sẽ đồng hành cùng bạn suốt quá trình từ lên ý tưởng, thiết kế, lựa chọn vật liệu, thi công đến hoàn thiện công trình, giải phóng mọi gánh nặng và giúp bạn tập trung vào những công việc quan trọng khác.</h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Kiểm Soát Chi Phí Hiệu Quả:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Dịch vụ trọn gói minh bạch hóa mọi khoản chi phí ngay từ đầu, giúp bạn nắm rõ ngân sách và hạn chế tối đa phát sinh chi phí ngoài dự kiến. Bên cạnh đó, việc sử dụng nguồn lực chung của đơn vị thi công giúp tiết kiệm chi phí nguyên vật liệu, nhân công, mang đến hiệu quả kinh tế tối ưu cho bạn.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Chất Lượng Công Trình Được Bảo Đảm:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Với đội ngũ kiến trúc sư, kỹ sư giàu kinh nghiệm, năng lực chuyên môn cao và am hiểu sâu sắc về phong cách thiết kế, vật liệu, công nghệ xây dựng, dịch vụ trọn gói mang đến giải pháp tối ưu cho công trình của bạn. Từ việc lựa chọn vật liệu phù hợp, ứng dụng công nghệ tiên tiến đến kiểm soát chất lượng thi công, mọi khâu đều được giám sát chặt chẽ bởi đội ngũ chuyên nghiệp, đảm bảo công trình đạt tiêu chuẩn về kỹ thuật, thẩm mỹ và an toàn.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >4. Bảo Hành Chuyên Nghiệp:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Dịch vụ trọn gói thường đi kèm với chính sách bảo hành uy tín, giúp bạn an tâm hơn về chất lượng công trình. Trong trường hợp phát sinh lỗi kỹ thuật, đơn vị thi công sẽ chịu trách nhiệm sửa chữa, khắc phục nhanh chóng, đảm bảo quyền lợi của khách hàng.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >5. Phong Cách Thiết Kế Đa Dạng:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Từ phong cách hiện đại, tân cổ điển, tối giản, đến phong cách truyền thống, bạn có thể lựa chọn phong cách thiết kế phù hợp với sở thích và nhu cầu của mình. Đội ngũ kiến trúc sư giàu kinh nghiệm sẽ tư vấn và đưa ra phương án thiết kế tối ưu, mang đến không gian sống tiện nghi và độc đáo, thể hiện cá tính riêng của bạn.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >6. Ưu Điểm Về Vật Liệu:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Với mối quan hệ hợp tác lâu dài với các nhà cung cấp vật liệu uy tín, đơn vị cung cấp dịch vụ trọn gói thường có mức giá ưu đãi hơn, giúp bạn tiết kiệm chi phí. Bên cạnh đó, họ cũng am hiểu về chất lượng, tính năng của từng loại vật liệu, giúp bạn lựa chọn được những sản phẩm phù hợp nhất với công trình của mình.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >Lưu Ý Khi Lựa Chọn Dịch Vụ Trọn Gói:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Để có được trải nghiệm tốt nhất, bạn nên lựa chọn những đơn vị cung cấp dịch vụ trọn gói uy tín, có kinh nghiệm, đội ngũ chuyên nghiệp và hồ sơ năng lực rõ ràng. Hãy tìm hiểu kỹ thông tin, tham khảo ý kiến của những người đã sử dụng dịch vụ trước đó, đồng thời yêu cầu đơn vị cung cấp hợp đồng rõ ràng, minh bạch mọi khoản chi phí, thời gian thi công, bảo hành...</h6>
            </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Kết luận:</h6>
            <br />
             <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Dịch vụ thiết kế và thi công trọn gói là giải pháp thông minh, mang đến nhiều lợi ích cho những ai muốn sở hữu ngôi nhà mơ ước một cách nhanh chóng, hiệu quả và tiết kiệm. Bằng cách tận dụng lợi ích của dịch vụ này, bạn sẽ có được không gian sống lý tưởng, phù hợp với phong cách và nhu cầu của mình.
            </h6>
            <br />
             <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Hãy lựa chọn những đơn vị cung cấp dịch vụ uy tín, chuyên nghiệp để bạn có được trải nghiệm tuyệt vời nhất!
            </h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
