import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Nội thất thông minh: Khơi mở không gian sống trong đô thị nén
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              1. Giữa đô thị chật hẹp, khát khao không gian sống: Nội thất thông minh - Xu hướng tất yếu
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              1.1. Đô thị nén: Thách thức và cơ hội:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Thế kỷ 21 chứng kiến sự bùng nổ dân số đô thị, kéo theo nhu cầu về nhà ở tăng đột biến. Đặc biệt tại các đô thị lớn, diện tích đất hạn chế, dẫn đến hiện tượng đô thị nén, với mật độ dân cư cao, thiếu hụt không gian sống. Đây là thách thức lớn cho kiến trúc và nội thất, đòi hỏi những giải pháp sáng tạo để tối ưu hóa không gian sống, mang đến sự tiện nghi, thoải mái cho người dân.
              </h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              1.2. Nội thất thông minh: Giải pháp tối ưu cho cuộc sống hiện đại:

              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Nội thất thông minh (Smart Interior) ra đời như một giải pháp đột phá cho những không gian sống bị giới hạn. Nó sử dụng công nghệ thông minh để tối ưu hóa chức năng, tùy biến không gian, tạo ra môi trường sống tiện nghi, hiệu quả và thông minh.

              </h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
             1.3. Xu hướng toàn cầu:

              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Nội thất thông minh đang là xu hướng phát triển mạnh mẽ trên toàn thế giới, đặc biệt là ở các đô thị lớn, nơi diện tích sống hạn hẹp và nhu cầu về tiện nghi, an toàn và tiết kiệm năng lượng ngày càng cao.
              </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b27_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b27_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2. Khái niệm và tiềm năng của nội thất thông minh
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.1. Định nghĩa:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Nội thất thông minh là hệ thống nội thất được trang bị các thiết bị điện tử, phần mềm và hệ thống kết nối thông minh, cho phép người sử dụng điều khiển, quản lý và giám sát các chức năng của nội thất từ xa thông qua các thiết bị di động, máy tính hoặc giọng nói.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.2. Hệ thống nội thất thông minh: Cấu trúc và chức năng:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hạ tầng kết nối:</strong> Bao gồm mạng internet, hệ thống mạng không dây, các thiết bị kết nối như bộ định tuyến, hub trung tâm.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Thiết bị thông minh:</strong> Bao gồm các thiết bị gia dụng thông minh, đèn chiếu sáng thông minh, hệ thống âm thanh thông minh, cửa sổ thông minh, v.v.</h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phần mềm điều khiển:</strong> Là phần mềm quản lý và điều khiển hoạt động của các thiết bị thông minh trong nội thất.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Giao diện người dùng:</strong> Cho phép người sử dụng tương tác với hệ thống nội thất thông minh thông qua các thiết bị di động, máy tính, trợ lý ảo.
        </h6>
            </li> 
           </ul>
          </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.3. Ưu điểm vượt trội:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Tối ưu hóa không gian:</strong> Nội thất thông minh giúp tối ưu hóa diện tích sử dụng, tạo ra không gian sống rộng rãi, thoáng đáng hơn bằng cách sử dụng các thiết bị đa năng, gấp gọn, lưu trữ thông minh.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Tùy biến không gian:</strong> Cho phép người sử dụng tùy biến không gian sống theo ý muốn, thích ứng với mọi hoạt động và nhu cầu khác nhau như làm việc, giải trí, ngủ nghỉ.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Nâng cao tiện nghi:</strong> Nội thất thông minh tạo ra môi trường sống tiện nghi, hiệu quả hơn bằng cách tự động hóa các chức năng, tiết kiệm thời gian và công sức cho người sử dụng.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Tiết kiệm năng lượng:</strong> Hệ thống nội thất thông minh giúp tối ưu hóa việc sử dụng điện, nước, giảm thiểu chi phí và bảo vệ môi trường.
        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>An toàn và bảo mật:</strong> Hệ thống an ninh thông minh trong nội thất giúp phát hiện và ngăn chặn các mối nguy hiểm, báo động khi có sự cố.
        </h6>
            </li> 
           </ul>
          </div>
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3. Giải pháp tối ưu cho không gian sống đặc thù: Nâng tầm cuộc sống trong mọi bối cảnh
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.1. Không gian nhỏ:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Nội thất đa năng:</strong> Sử dụng các thiết bị đa năng, gấp gọn, lưu trữ thông minh như giường gấp, bàn gấp, kệ tường thông minh, v.v.
        </h6>
        </li>
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Ánh sáng thông minh:</strong> Sử dụng đèn chiếu sáng thông minh có thể điều chỉnh độ sáng, màu sắc, tạo cảm giác không gian rộng rãi, thoáng đáng hơn.</h6>
        </li>
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong></strong> Sử dụng gương để tăng cảm giác không gian rộng rãi, tạo hiệu ứng thị giác cho không gian nhỏ.
        </h6>
            </li> 
           </ul>
          </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.2. Không gian cần tối ưu hóa công năng:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phòng ngủ thông minh:</strong> Giường gấp thông minh, tủ quần áo thông minh, hệ thống ánh sáng thông minh tạo ra không gian ngủ nghỉ thoải mái, riêng tư.

        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phòng khách thông minh:</strong> Hệ thống âm thanh thông minh, tivi thông minh, bàn ghế gấp gọn tạo ra không gian giải trí sống động, thuận tiện cho tiếp khách.

        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Bếp thông minh:</strong> Bếp nấu thông minh, tủ lạnh thông minh, hệ thống quản lý năng lượng giúp nấu ăn dễ dàng, tiết kiệm thời gian và năng lượng.

        </h6>
            </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span> 
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Phòng tắm thông minh:</strong> Bồn tắm thông minh, vòi nước thông minh, hệ thống quản lý năng lượng tạo ra không gian tắm gọn gàng, tiện nghi và tiết kiệm nước.
        </h6>
            </li> 
           </ul>
          </div>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b27_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b27_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b27_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Nội thất thông minh tại Việt Nam: Nhu cầu tăng cao, tiềm năng bất tận
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Xu hướng đang lên:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Nội thất thông minh ở Việt Nam đang ngày càng được chú ý và phát triển, thu hút sự quan tâm của nhiều người dân, nhất là những người trẻ tuổi, có nhu cầu về một cuộc sống hiện đại, tiện nghi và an toàn.
        </h6>
              </div>
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Thách thức và cơ hội:
        </h6>
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Thách thức:</strong>
        </h6>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Chi phí đầu tư cao.
        </h6>
            </li>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiếu hụt kiến thức, kỹ năng về công nghệ nội thất thông minh.
        </h6>
            </li>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Môi trường pháp lý, chính sách chưa hoàn thiện.
        </h6>
            </li>
            </ul>
                </li> 
          <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Cơ hội:</strong>
        </h6>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thị trường nội thất thông minh Việt Nam có tiềm năng phát triển rất lớn.
        </h6>
            </li>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Sự gia tăng nhu cầu về cuộc sống tiện nghi, an toàn và tiết kiệm năng lượng.
        </h6>
            </li>
        <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Chính phủ đang có những chính sách khuyến khích phát triển công nghệ thông minh.
        </h6>
            </li>
            </ul>
                </li> 
              </ul>
            </div>
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.3. Bức tranh đa dạng:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Hiện nay, có nhiều loại hình nội thất thông minh được ứng dụng ở Việt Nam, từ những ngôi nhà đơn giản cho đến những dự án căn hộ cao cấp, tích hợp đầy đủ các tiện ích thông minh.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5. Giải pháp và vai trò của NEXCON: Kiến tạo cuộc sống thông minh cho người Việt
        </h6>
        <br />
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.1. Đánh thức tiềm năng:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Để phát triển nội thất thông minh một cách bền vững, cần có những giải pháp đồng bộ:
        </h6>
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span>
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
        <strong>Phát triển hạ tầng kỹ thuật số:</strong> Nâng cấp hệ thống mạng internet, tăng cường kết nối không dây, xây dựng hệ thống dữ liệu lớn, ứng dụng AI.
        </h6>
           </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span>
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hoàn thiện chính sách, pháp luật:</strong> Cần có những chính sách khuyến khích đầu tư, phát triển công nghệ nội thất thông minh, xây dựng khung pháp lý rõ ràng, bảo vệ quyền lợi của người tiêu dùng.
          </h6> </li> 
          <li className="d-flex my-4">
          <span className="icon me-3"> • </span>
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Nâng cao nhận thức:</strong> Tăng cường truyền thông, giáo dục để nâng cao nhận thức của người dân về nội thất thông minh, tạo sự tin tưởng và thu hút sự tham gia của cộng đồng.
        </h6>
         </li> 
          </ul>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
       5.2. NEXCON: Tiên phong kiến tạo cuộc sống thông minh:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                  <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
       NEXCON, đơn vị thiết kế thi công trực thuộc Nexholding, là đơn vị tiên phong trong việc nghiên cứu, ứng dụng các giải pháp nhà thông minh và nội thất thông minh vào trong sản phẩm thiết kế. NEXCON mang đến những giải pháp nội thất thông minh đầy đủ, hiện đại, phù hợp với nhu cầu và điều kiện cụ thể của từng khách hàng.
        </h6>  
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
        5.3. Lợi thế vượt trội:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          > 
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strongg>Kiến thức chuyên môn:</strongg> Đội ngũ kỹ sư, kiến trúc sư giàu kinh nghiệm, nắm bắt sâu sắc kiến thức về công nghệ nội thất thông minh, thiết kế nội thất, kiến trúc.
        </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Hệ sinh thái sản phẩm đa dạng:</strong> Cung cấp đầy đủ các giải pháp nội thất thông minh, từ hệ thống quản lý năng lượng, an ninh, giải trí, cho đến các thiết bị gia dụng thông minh, phần mềm điều khiển, v.v.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Chất lượng và dịch vụ:</strong> Cam kết mang đến những sản phẩm chất lượng cao, dịch vụ chuyên nghiệp, hỗ trợ khách hàng tận tâm, bảo hành chu đáo.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span> 
            <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        ><strong>Cập nhật công nghệ:</strong> Luôn cập nhật những công nghệ mới nhất, mang đến những giải pháp tiên tiến, đáp ứng nhu cầu ngày càng cao của khách hàng.
        </h6>
            </li>
          </ul>
              </div>
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b27_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b27_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             6. Kết nối tương lai: Khơi mở tiềm năng cho cuộc sống Việt
            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Nội thất thông minh không chỉ là một xu hướng, mà còn là một giải pháp góp phần nâng cao chất lượng cuộc sống, xây dựng một xã hội văn minh, hiện đại và bền vững. NEXCON, với tư cách là đơn vị tiên phong trong lĩnh vực nội thất thông minh, sẽ tiếp tục nỗ lực phát triển các giải pháp đột phá, góp phần kiến tạo một cuộc sống thông minh, tiện nghi và hạnh phúc cho người dân Việt Nam.

            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Nội thất thông minh không chỉ là một công trình kiến trúc, mà còn là một lời khẳng định về tầm nhìn và ý chí kiến tạo một cuộc sống tốt đẹp hơn. NEXCON cam kết sẽ đồng hành cùng khách hàng, thực hiện những giấc mơ về một ngôi nhà thông minh, tiện nghi và an toàn, góp phần nâng tầm cuộc sống Việt Nam.

            </h6>
            <br />

           
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
