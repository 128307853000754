import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home1 from './pages/home1';
import Home2Page from './pages/home2';
import Home3Page from './pages/home3_construction';
import Home4Page from './pages/home4_personal_architect';
import Home5Page from './pages/home5_residence';
import Home7Page from './pages/home7_landscape';
import Home7Page1 from './pages/home7_landscape1';
import AboutPage from './pages/innerpages/about';
import BlogPage from './pages/innerpages/blog';
import ContactPage from './pages/innerpages/contact';
import PortfolioPage from './pages/innerpages/portfolio';
import PortfolioPage1 from './pages/innerpages/portfolio1';
import SinglePostPage from './pages/innerpages/single_post';
import SinglePostPage1 from './pages/innerpages/single_post1';
import SinglePostPage2 from './pages/innerpages/single_post2';
import SinglePostPage3 from './pages/innerpages/single_post3';
import SinglePostPage4 from './pages/innerpages/single_post4';
import SinglePostPage5 from './pages/innerpages/single_post5';
import SinglePostPage6 from './pages/innerpages/single_post6';
import SinglePostPage7 from './pages/innerpages/single_post7';
import SinglePostPage8 from './pages/innerpages/single_post8';
import SinglePostPage9 from './pages/innerpages/single_post9';
import SinglePostPage10 from './pages/innerpages/single_post10';
import SinglePostPage11 from './pages/innerpages/single_post11';
import SinglePostPage12 from './pages/innerpages/single_post12';
import SinglePostPage13 from './pages/innerpages/single_post13';
import SinglePostPage14 from './pages/innerpages/single_post14';
import SinglePostPage15 from './pages/innerpages/single_post15';
import SinglePostPage16 from './pages/innerpages/single_post16';
import SinglePostPage17 from './pages/innerpages/single_post17';
import SinglePostPage18 from './pages/innerpages/single_post18';
import SinglePostPage19 from './pages/innerpages/single_post19';
import SinglePostPage20 from './pages/innerpages/single_post20';
import SinglePostPage21 from './pages/innerpages/single_post21';
import SinglePostPage22 from './pages/innerpages/single_post22';
import SinglePostPage23 from './pages/innerpages/single_post23';
import SinglePostPage24 from './pages/innerpages/single_post24';
import SinglePostPage25 from './pages/innerpages/single_post25';
import SinglePostPage26 from './pages/innerpages/single_post26';
import SinglePostPage27 from './pages/innerpages/single_post27';
import SinglePostPage28 from './pages/innerpages/single_post28';
import SinglePostPage29 from './pages/innerpages/single_post29';
import SinglePostPage30 from './pages/innerpages/single_post30';
import SingleProject from './pages/innerpages/single_project';
import SingleProject02 from './pages/innerpages/single_project02';
import SingleProject03 from './pages/innerpages/single_project03';
import SingleProject04 from './pages/innerpages/single_project04';
import SingleProject05 from './pages/innerpages/single_project05';
import SingleProject06 from './pages/innerpages/single_project06';
import SingleProject07 from './pages/innerpages/single_project07';
import SingleProject08 from './pages/innerpages/single_project08';
import SingleProject09 from './pages/innerpages/single_project09';
import SingleProject10 from './pages/innerpages/single_project10';
import SingleProject11 from './pages/innerpages/single_project11';
import SingleProject12 from './pages/innerpages/single_project12';
import SingleProject13 from './pages/innerpages/single_project13';
import SingleProject14 from './pages/innerpages/single_project14';
import SingleProject15 from './pages/innerpages/single_project15';
import SingleProject16 from './pages/innerpages/single_project16';
import SingleProject17 from './pages/innerpages/single_project17';
import SingleProject18 from './pages/innerpages/single_project18';
import SingleProject19 from './pages/innerpages/single_project19';
import SingleProject20 from './pages/innerpages/single_project20';
import SingleProject21 from './pages/innerpages/single_project21';
import SingleProject22 from './pages/innerpages/single_project22';
import SingleProject23 from './pages/innerpages/single_project23';
import SingleProject24 from './pages/innerpages/single_project24';
import SingleProject25 from './pages/innerpages/single_project25';
import SingleProject26 from './pages/innerpages/single_project26';
import SingleProject27 from './pages/innerpages/single_project27';
import SingleProject28 from './pages/innerpages/single_project28';
import SingleProject29 from './pages/innerpages/single_project29';
import SingleProject30 from './pages/innerpages/single_project30';
import SingleProject31 from './pages/innerpages/single_project31';
import SingleProject32 from './pages/innerpages/single_project32';
import SingleProject33 from './pages/innerpages/single_project33';
import SingleProject34 from './pages/innerpages/single_project34';
import SingleProject35 from './pages/innerpages/single_project35';
import SingleProject36 from './pages/innerpages/single_project36';
import SingleProject37 from './pages/innerpages/single_project37';
import SingleProject38 from './pages/innerpages/single_project38';
import SingleProject39 from './pages/innerpages/single_project39';
import SingleProject40 from './pages/innerpages/single_project40';
import SingleProject41 from './pages/innerpages/single_project41';
import SingleProject42 from './pages/innerpages/single_project42';
import SingleProject43 from './pages/innerpages/single_project43';
import SingleProject44 from './pages/innerpages/single_project44';
import SingleProject45 from './pages/innerpages/single_project45';
import SingleProject46 from './pages/innerpages/single_project46';
import SingleProject47 from './pages/innerpages/single_project47';
import SingleProject48 from './pages/innerpages/single_project48';
import SingleProject49 from './pages/innerpages/single_project49';
import SingleProject50 from './pages/innerpages/single_project50';
import SingleProject51 from './pages/innerpages/single_project51';
import SingleProject52 from './pages/innerpages/single_project52';
import SingleProject53 from './pages/innerpages/single_project53';
import SingleProject54 from './pages/innerpages/single_project54';
import SingleProject55 from './pages/innerpages/single_project55';
import SingleProject56 from './pages/innerpages/single_project56';
import SingleProject57 from './pages/innerpages/single_project57';
import SingleProject58 from './pages/innerpages/single_project58';
import SingleProject59 from './pages/innerpages/single_project59';
import SingleProject60 from './pages/innerpages/single_project60';
import SingleProject61 from './pages/innerpages/single_project61';
import SingleProject62 from './pages/innerpages/single_project62';
import SingleProject63 from './pages/innerpages/single_project63';
import SingleProject64 from './pages/innerpages/single_project64';
import SingleProject65 from './pages/innerpages/single_project65';
import SingleProject66 from './pages/innerpages/single_project66';
import SingleProject67 from './pages/innerpages/single_project67';
import SingleProject68 from './pages/innerpages/single_project68';
import SingleProject69 from './pages/innerpages/single_project69';
import SingleProject70 from './pages/innerpages/single_project70';
import SingleProject71 from './pages/innerpages/single_project71';
import SingleProject72 from './pages/innerpages/single_project72';
import SingleProject73 from './pages/innerpages/single_project73';
import SingleProject74 from './pages/innerpages/single_project74';
import SingleProject75 from './pages/innerpages/single_project75';
import SingleProject76 from './pages/innerpages/single_project76';
import SingleProject77 from './pages/innerpages/single_project77';
import SingleProject78 from './pages/innerpages/single_project78';
import SingleProject79 from './pages/innerpages/single_project79';
import SingleProject80 from './pages/innerpages/single_project80';
import SingleProject81 from './pages/innerpages/single_project81';
import SingleProject82 from './pages/innerpages/single_project82';
import SingleProject83 from './pages/innerpages/single_project83';
import SingleProject84 from './pages/innerpages/single_project84';
import SingleProject85 from './pages/innerpages/single_project85';
import SingleProject86 from './pages/innerpages/single_project86';
import SingleProject87 from './pages/innerpages/single_project87';
import SingleProject88 from './pages/innerpages/single_project88';
import SingleProject89 from './pages/innerpages/single_project89';
import SingleProject90 from './pages/innerpages/single_project90';
import SingleProject91 from './pages/innerpages/single_project91';
import SingleProject92 from './pages/innerpages/single_project92';
import SingleProject93 from './pages/innerpages/single_project93';
import SingleProject94 from './pages/innerpages/single_project94';
import SingleProject95 from './pages/innerpages/single_project95';
import SingleProject96 from './pages/innerpages/single_project96';
import SingleProject97 from './pages/innerpages/single_project97';
import SingleProject98 from './pages/innerpages/single_project98';
import SingleProject99 from './pages/innerpages/single_project99';
import SingleProject100 from './pages/innerpages/single_project100';

function App() {
  return ( <Router >
        <Routes >
        <Route path = "/AboutPage"
        element = { < AboutPage / > }
        /> 
        <Route path = "/Home2Page"
        element = { < Home2Page / > }
        /> 
        <Route path = "/Home3Page"
        element = { < Home3Page / > }
        /> 
        <Route path = "/Home4Page"
        element = { < Home4Page / > }
        /> 
        <Route path = "/Home5Page"
        element = { < Home5Page / > }
        /> 
        <Route path = "/Home7Page"
        element = { < Home7Page / > }
        /> 
        <Route path = "/Home7Page1"
        element = { < Home7Page1 / > }
        /> 
        <Route path = "/SingleProject02"
        element = { < SingleProject02 / > }
        /> 
        <Route path = "/SingleProject03"
        element = { < SingleProject03 / > }
        /> 
        <Route path = "/SingleProject04"
        element = { < SingleProject04 / > }
        /> 
        <Route path = "/SingleProject05"
        element = { < SingleProject05 / > }
        /> 
        <Route path = "/SingleProject06"
        element = { < SingleProject06 / > }
        /> 
        <Route path = "/SingleProject07"
        element = { < SingleProject07 / > }
        /> 
        <Route path = "/SingleProject08"
        element = { < SingleProject08 / > }
        /> 
        <Route path = "/SingleProject09"
        element = { < SingleProject09 / > }
        /> 
        <Route path = "/SingleProject10"
        element = { < SingleProject10 / > }
        /> 
        <Route path = "/SingleProject11"
        element = { < SingleProject11 / > }
        /> 
        <Route path = "/SingleProject12"
        element = { < SingleProject12 / > }
        /> 
        <Route path = "/SingleProject13"
        element = { < SingleProject13 / > }
        /> 
        <Route path = "/SingleProject14"
        element = { < SingleProject14 / > }
        /> 
        <Route path = "/SingleProject15"
        element = { < SingleProject15 / > }
        /> 
        <Route path = "/SingleProject16"
        element = { < SingleProject16 / > }
        /> 
        <Route path = "/SingleProject17"
        element = { < SingleProject17 / > }
        /> 
        <Route path = "/SingleProject18"
        element = { < SingleProject18 / > }
        /> 
        <Route path = "/SingleProject19"
        element = { < SingleProject19 / > }
        /> 
        <Route path = "/SingleProject20"
        element = { < SingleProject20 / > }
        /> 
        <Route path = "/SingleProject21"
        element = { < SingleProject21 / > }
        /> 
        <Route path = "/SingleProject22"
        element = { < SingleProject22 / > }
        /> 
        <Route path = "/SingleProject23"
        element = { < SingleProject23 / > }
        /> 
        <Route path = "/SingleProject24"
        element = { < SingleProject24 / > }
        /> 
        <Route path = "/SingleProject25"
        element = { < SingleProject25 / > }
        /> 
        <Route path = "/SingleProject26"
        element = { < SingleProject26 / > }
        /> 
        <Route path = "/SingleProject27"
        element = { < SingleProject27 / > }
        /> 
        <Route path = "/SingleProject28"
        element = { < SingleProject28 / > }
        /> 
        <Route path = "/SingleProject29"
        element = { < SingleProject29 / > }
        /> 
        <Route path = "/SingleProject30"
        element = { < SingleProject30 / > }
        /> 
        <Route path = "/SingleProject31"
        element = { < SingleProject31 / > }
        /> 
        <Route path = "/SingleProject32"
        element = { < SingleProject32 / > }
        /> 
        <Route path = "/SingleProject33"
        element = { < SingleProject33 / > }
        /> 
        <Route path = "/SingleProject34"
        element = { < SingleProject34 / > }
        /> 
        <Route path = "/SingleProject35"
        element = { < SingleProject35 / > }
        /> 
        <Route path = "/SingleProject36"
        element = { < SingleProject36 / > }
        /> 
        <Route path = "/SingleProject37"
        element = { < SingleProject37 / > }
        /> 
        <Route path = "/SingleProject38"
        element = { < SingleProject38 / > }
        /> 
        <Route path = "/SingleProject39"
        element = { < SingleProject39 / > }
        /> 
        <Route path = "/SingleProject40"
        element = { < SingleProject40 / > }
        /> 
        <Route path = "/SingleProject41"
        element = { < SingleProject41 / > }
        /> 
        <Route path = "/SingleProject42"
        element = { < SingleProject42 / > }
        /> 
        <Route path = "/SingleProject43"
        element = { < SingleProject43 / > }
        /> 
        <Route path = "/SingleProject44"
        element = { < SingleProject44 / > }
        /> 
        <Route path = "/SingleProject45"
        element = { < SingleProject45 / > }
        /> 
        <Route path = "/SingleProject46"
        element = { < SingleProject46 / > }
        /> 
        <Route path = "/SingleProject47"
        element = { < SingleProject47 / > }
        /> 
        <Route path = "/SingleProject48"
        element = { < SingleProject48 / > }
        /> 
        <Route path = "/SingleProject49"
        element = { < SingleProject49 / > }
        /> 
        <Route path = "/SingleProject50"
        element = { < SingleProject50 / > }
        /> 
        <Route path = "/SingleProject51"
        element = { < SingleProject51 / > }
        /> 
        <Route path = "/SingleProject52"
        element = { < SingleProject52 / > }
        /> 
        <Route path = "/SingleProject53"
        element = { < SingleProject53 / > }
        /> 
        <Route path = "/SingleProject54"
        element = { < SingleProject54 / > }
        /> 
        <Route path = "/SingleProject55"
        element = { < SingleProject55 / > }
        /> 
        <Route path = "/SingleProject56"
        element = { < SingleProject56 / > }
        /> 
        <Route path = "/SingleProject57"
        element = { < SingleProject57 / > }
        /> 
        <Route path = "/SingleProject58"
        element = { < SingleProject58 / > }
        /> 
        <Route path = "/SingleProject59"
        element = { < SingleProject59 / > }
        /> 
        <Route path = "/SingleProject60"
        element = { < SingleProject60 / > }
        /> 
        <Route path = "/SingleProject61"
        element = { < SingleProject61 / > }
        /> 
        <Route path = "/SingleProject62"
        element = { < SingleProject62 / > }
        /> 
        <Route path = "/SingleProject63"
        element = { < SingleProject63 / > }
        /> 
        <Route path = "/SingleProject64"
        element = { < SingleProject64 / > }
        /> 
        <Route path = "/SingleProject65"
        element = { < SingleProject65 / > }
        /> 
        <Route path = "/SingleProject66"
        element = { < SingleProject66 / > }
        /> 
        <Route path = "/SingleProject67"
        element = { < SingleProject67 / > }
        /> 
        <Route path = "/SingleProject68"
        element = { < SingleProject68 / > }
        /> 
        <Route path = "/SingleProject69"
        element = { < SingleProject69 / > }
        /> 
        <Route path = "/SingleProject70"
        element = { < SingleProject70 / > }
        /> 
        <Route path = "/SingleProject71"
        element = { < SingleProject71 / > }
        /> 
        <Route path = "/SingleProject72"
        element = { < SingleProject72 / > }
        /> 
        <Route path = "/SingleProject73"
        element = { < SingleProject73 / > }
        /> 
        <Route path = "/SingleProject74"
        element = { < SingleProject74 / > }
        /> 
        <Route path = "/SingleProject75"
        element = { < SingleProject75 / > }
        /> 
        <Route path = "/SingleProject76"
        element = { < SingleProject76 / > }
        /> 
        <Route path = "/SingleProject77"
        element = { < SingleProject77 / > }
        /> 
        <Route path = "/SingleProject78"
        element = { < SingleProject78 / > }
        /> 
        <Route path = "/SingleProject79"
        element = { < SingleProject79 / > }
        /> 
        <Route path = "/SingleProject80"
        element = { < SingleProject80 / > }
        /> 
        <Route path = "/SingleProject81"
        element = { < SingleProject81 / > }
        /> 
        <Route path = "/SingleProject82"
        element = { < SingleProject82 / > }
        /> 
        <Route path = "/SingleProject83"
        element = { < SingleProject83 / > }
        /> 
        <Route path = "/SingleProject84"
        element = { < SingleProject84 / > }
        /> 
        <Route path = "/SingleProject85"
        element = { < SingleProject85 / > }
        /> 
        <Route path = "/SingleProject86"
        element = { < SingleProject86 / > }
        /> 
        <Route path = "/SingleProject87"
        element = { < SingleProject87 / > }
        /> 
        <Route path = "/SingleProject88"
        element = { < SingleProject88 / > }
        /> 
        <Route path = "/SingleProject89"
        element = { < SingleProject89 / > }
        /> 
        <Route path = "/SingleProject90"
        element = { < SingleProject90 / > }
        /> 
        <Route path = "/SingleProject91"
        element = { < SingleProject91 / > }
        /> 
        <Route path = "/SingleProject92"
        element = { < SingleProject92 / > }
        /> 
        <Route path = "/SingleProject93"
        element = { < SingleProject93 / > }
        /> 
        <Route path = "/SingleProject94"
        element = { < SingleProject94 / > }
        /> 
        <Route path = "/SingleProject95"
        element = { < SingleProject95 / > }
        /> 
        <Route path = "/SingleProject96"
        element = { < SingleProject96 / > }
        /> 
        <Route path = "/SingleProject97"
        element = { < SingleProject97 / > }
        /> 
        <Route path = "/SingleProject98"
        element = { < SingleProject98 / > }
        /> 
        <Route path = "/SingleProject99"
        element = { < SingleProject99 / > }
        /> 
        <Route path = "/SingleProject100"
        element = { < SingleProject100 / > }
        /> 
        <Route path = "/SinglePostPage"
        element = { < SinglePostPage / > }
        /> 
        <Route path = "/SinglePostPage1"
        element = { < SinglePostPage1 / > }
        /> 
        <Route path = "/SinglePostPage2"
        element = { < SinglePostPage2 / > }
        /> 
        <Route path = "/SinglePostPage3"
        element = { < SinglePostPage3 / > }
        /> 
        <Route path = "/SinglePostPage4"
        element = { < SinglePostPage4 / > }
        /> 
        <Route path = "/SinglePostPage5"
        element = { < SinglePostPage5 / > }
        /> 
        <Route path = "/SinglePostPage6"
        element = { < SinglePostPage6 / > }
        /> 
        <Route path = "/SinglePostPage7"
        element = { < SinglePostPage7 / > }
        /> 
        <Route path = "/SinglePostPage8"
        element = { < SinglePostPage8 / > }
        /> 
        <Route path = "/SinglePostPage9"
        element = { < SinglePostPage9 / > }
        /> 
        <Route path = "/SinglePostPage10"
        element = { < SinglePostPage10 / > }
        /> 
        <Route path = "/SinglePostPage11"
        element = { < SinglePostPage11 / > }
        /> 
        <Route path = "/SinglePostPage12"
        element = { < SinglePostPage12 / > }
        /> 
        <Route path = "/SinglePostPage13"
        element = { < SinglePostPage13 / > }
        /> 
        <Route path = "/SinglePostPage14"
        element = { < SinglePostPage14 / > }
        /> 
        <Route path = "/SinglePostPage15"
        element = { < SinglePostPage15 / > }
        /> 
        <Route path = "/SinglePostPage16"
        element = { < SinglePostPage16 / > }
        /> 
        <Route path = "/SinglePostPage17"
        element = { < SinglePostPage17 / > }
        /> 
        <Route path = "/SinglePostPage18"
        element = { < SinglePostPage18 / > }
        /> 
        <Route path = "/SinglePostPage19"
        element = { < SinglePostPage19 / > }
        /> 
        <Route path = "/SinglePostPage20"
        element = { < SinglePostPage20 / > }
        /> 
        <Route path = "/SinglePostPage21"
        element = { < SinglePostPage21 / > }
        /> 
        <Route path = "/SinglePostPage22"
        element = { < SinglePostPage22 / > }
        /> 
        <Route path = "/SinglePostPage23"
        element = { < SinglePostPage23 / > }
        /> 
        <Route path = "/SinglePostPage24"
        element = { < SinglePostPage24 / > }
        /> 
        <Route path = "/SinglePostPage25"
        element = { < SinglePostPage25 / > }
        /> 
        <Route path = "/SinglePostPage26"
        element = { < SinglePostPage26 / > }
        /> 
        <Route path = "/SinglePostPage27"
        element = { < SinglePostPage27 / > }
        /> 
        <Route path = "/SinglePostPage28"
        element = { < SinglePostPage28 / > }
        /> 
        <Route path = "/SinglePostPage29"
        element = { < SinglePostPage29 / > }
        /> 
        <Route path = "/SinglePostPage30"
        element = { < SinglePostPage30 / > }
        /> 
        <Route path = "/BlogPage"
        element = { < BlogPage / > }
        /> 
        <Route path = "/SingleProject"
        element = { < SingleProject / > }
        /> 
        <Route path = "/PortfolioPage1"
        element = { < PortfolioPage1 / > }
        /> 
        <Route path = "/PortfolioPage"
        element = { < PortfolioPage / > }
        /> 
        <Route path = "/"
        element = { < Home1 / > }
        /> 
          <Route path = "/ContactPage"
        element = { < ContactPage / > }
        /> 
        </Routes> 
    </Router>     
    );
}

export default App;