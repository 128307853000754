import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> CÔNG THU VILLA </h1>
          <div className="text color-666 mt-30">
          Nét đẹp kiêu sa của phong cách tân cổ điển
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m17.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Công Thu Villa là một kiệt tác kiến trúc được thiết kế theo phong cách tân cổ điển, mang đến một không gian sống sang trọng, đẳng cấp và đầy tinh tế. Công trình này là sự kết hợp hài hòa giữa những nét đẹp truyền thống của kiến trúc cổ điển với những yếu tố hiện đại, tạo nên một kiệt tác nghệ thuật kiến trúc độc đáo và ấn tượng.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hà Nam
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        450 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/CT2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/CT3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Kiêu sa và thanh lịch: Công Thu Villa được thiết kế theo phong cách tân cổ điển, toát lên vẻ đẹp kiêu sa, thanh lịch và sang trọng. Những đường nét uốn lượn mềm mại, những họa tiết tinh xảo, những gam màu trầm ấm, tất cả tạo nên một không gian sống đầy mê hoặc.<br/>
          Tôn vinh vẻ đẹp cổ điển: Công trình được lấy cảm hứng từ những nét đẹp tinh hoa của kiến trúc cổ điển châu Âu, với những cột trụ uy nghi, những vòm cong thanh thoát, những hoa văn trang trí tinh xảo, tạo nên một không gian sống đậm chất nghệ thuật.<br/>
          Tích hợp yếu tố hiện đại: Công Thu Villa được thiết kế với những tiện nghi hiện đại, đáp ứng nhu cầu sống của gia chủ. Hệ thống thông gió tự nhiên, hệ thống chiếu sáng thông minh, hệ thống an ninh hiện đại, tất cả tạo nên một không gian sống tiện nghi, hiện đại và đẳng cấp.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền uy nghi: Công Thu Villa sở hữu một mặt tiền uy nghi với những cột trụ Roman hùng vĩ, những vòm cong thanh thoát, những chi tiết trang trí tinh xảo. Mặt tiền được ốp đá tự nhiên cao cấp, tạo nên vẻ đẹp sang trọng và bền vững theo thời gian.<br/>
Mái vòm độc đáo: Mái vòm được thiết kế theo phong cách tân cổ điển, tạo nên vẻ đẹp uy nghi và thanh thoát cho ngôi nhà. Mái vòm được trang trí bằng những họa tiết tinh xảo, thể hiện sự tinh tế và đẳng cấp của gia chủ.<br/>
Sân vườn xanh mát: Công Thu Villa được bao quanh bởi một khu vườn xanh mát, tạo không gian thư giãn, gần gũi với thiên nhiên cho gia đình. Sân vườn được thiết kế theo phong cách tân cổ điển, với những thảm cỏ xanh mướt, những bụi hoa rực rỡ sắc màu, những cây cổ thụ cao vút, tạo nên một không gian sống yên bình và thanh bình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/CT4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/CT5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/CT2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
        Nội thất gỗ tự nhiên: Công Thu Villa sử dụng nội thất gỗ tự nhiên cao cấp, mang đến sự ấm cúng, sang trọng và đẳng cấp cho không gian sống. Những bộ bàn ghế gỗ được chạm khắc tinh xảo, những tủ sách gỗ cổ điển, những chiếc gương soi bằng gỗ, tất cả tạo nên một không gian sống đầy mê hoặc.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/Ct2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/Ct5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
