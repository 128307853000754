import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Xây Dựng </a>
            <a href="#"> Nội Thất </a>``
          </div>
          <h1 className="title"> 
          Nắng Mai Apartment

            </h1>
          <div className="text color-666 mt-30">
          PHONG CÁCH HIỆN ĐẠI MANG LẠI SỰ ẤM CÚNG
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m52.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           "Nắng Mai" - cái tên được đặt cho căn hộ này như một lời khẳng định về bầu không khí ấm áp, rạng rỡ mà nó mang lại. Căn hộ như một bản giao hưởng được phối hợp bởi những gam màu nhẹ nhàng, thanh lịch, phảng phất nét hoài cổ, tạo cảm giác an nhiên, thư giãn.

          </div>
         
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch Vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                          Thiết kế <br /> Xây Dựng
                          <br /> Bảo Hiểm
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Thảo Điền, Q2, TP.HCM
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        67 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i52_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i52_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Bước vào "Nắng Mai", bạn sẽ bị thu hút bởi sự tinh tế trong cách bố trí nội thất. Phong cách hiện đại được thể hiện rõ nét qua những đường nét sắc sảo, gọn gàng, tạo nên một không gian thoáng đãng, thoáng đãng, nhưng không kém phần sang trọng. Gỗ tự nhiên được sử dụng như một "nhân vật chính" trong căn hộ, tạo nên một cảm giác gần gũi, ấm cúng, và thân thiện với môi trường. Sàn nhà bằng gỗ sáng bóng, bàn ghế gỗ được thiết kế chắc chắn, tủ gỗ tích hợp tạo nên một tổng thể hài hòa, đầy ấn tượng.

          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Gam màu chủ đạo của căn hộ là những tông màu trung tính nhẹ nhàng như trắng, be, xám, tạo nên sự thanh thoát, tinh tế cho không gian. Những mảng tường trắng như tấm lụa mềm mại được điểm tô bởi các bức tranh trừu tượng đầy nghệ thuật, những chiếc đèn thả độc đáo như những viên ngọc bóng lấp lánh, và những chậu cây xanh tươi mát như những điểm nhấn sinh động.

          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Không chỉ là nơi để ở, "Nắng Mai" còn là không gian tĩnh tâm, nơi bạn có thể tận hưởng sự thanh bình, tươi mới sau một ngày dài làm việc vất vả. Ánh nắng mịn màng lọt qua khe cửa sổ, ánh sáng nhẹ nhàng tỏa rộng khắp căn phòng, tạo nên cảm giác ấm cúng, yên bình. Bạn có thể ngồi thư giãn trên chiếc ghế sofa êm ái, đọc quyển sách yêu thích, hoặc chỉ đơn giản là ngắm nhìn cảnh vật bên ngoài cửa sổ.

          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Căn hộ "Nắng Mai" không chỉ là một không gian sống đẹp mà còn là một tác phẩm nghệ thuật, một nơi gợi cảm hứng, nơi bạn có thể tìm được sự bình yên và hạnh phúc trong cuộc sống hiện đại bận rộn.

          </div>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj52_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj52_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj52_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           {/* tieu de tong ket */}
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          {/* tieu de tong quan */}
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj52_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj52_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj52_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj52_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj52_8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
