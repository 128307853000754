import React from 'react';
import data1 from '../../data/home1/projects/projects1';
import data2 from '../../data/home1/projects/projects2';
import data3 from '../../data/home1/projects/projects3';
import data4 from '../../data/home1/projects/projects4';
import data5 from '../../data/home1/projects/projects5';
function Projects() {
  return (
    <section className="tc-projects-style1">
      <div className="container">
        <div className="title mb-70">
          <h2 className="fsz-45"> CÁC DỰ ÁN NỔI BẬT </h2>
        </div>
        <div className="tabs-links mb-50">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-proj1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proj1"
                    type="button"
                  >
                    Quy Hoạch Đô Thị
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-proj2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proj2"
                    type="button"
                  >
                    Quy Hoạch Nghỉ Dưỡng
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-proj3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proj3"
                    type="button"
                  >
                    Biệt Thụ Villa
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-proj4-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proj4"
                    type="button"
                  >
                    Nội Thất
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-proj5-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proj5"
                    type="button"
                  >
                    Ngoại Thất
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 mt-4 mt-lg-0 text-lg-end">
              <a
                href="/PortfolioPage"
                className="butn border rounded-pill color-orange1 border-orange1 hover-bg-orange1"
              >
                <span>
                Tất cả Quy Hoạch
                  <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
            <div className="col-lg-3 mt-4 mt-lg-0 text-lg-end">
              <a
                href="/PortfolioPage1"
                className="butn border rounded-pill color-orange1 border-orange1 hover-bg-orange1"
              >
                <span>
                Tất cả Kiến Trúc
                  <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="projects">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-proj1"
              role="tabpanel"
              aria-labelledby="pills-proj1-tab"
            >
              <div className="projects-content float_box_container">
                <div className="projects-slider">
                  <div className="swiper-wrapper">
                    {data1.map((item, i) => (
                      <div key={i} className="swiper-slide">
                        <div className="project-card">
                          <a
                            href={item.herf}
                            className="img"
                            
                          >
                            <img src={item.img} alt="" className="img-cover" />
                          </a>
                          <div className="info">
                            <div className="tags">
                              <a href={item.herf}> {item.sub1} </a>
                              <a href={item.herf}> {item.sub2} </a>
                            </div>
                            <h3 className="title">
                              <a href={item.herf}> {item.title} </a>
                            </h3>
                            <div className="text">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="float-cursor float_box">
                  Hold <br /> and Drag
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-proj2"
              role="tabpanel"
              aria-labelledby="pills-proj2-tab"
            >
              <div className="projects-content float_box_container">
                <div className="projects-slider">
                  <div className="swiper-wrapper">
                    {data2.map((item, i) => (
                      <div key={i} className="swiper-slide">
                        <div className="project-card">
                          <a
                            href={item.herf}
                            className="img"
                            
                          >
                            <img src={item.img} alt="" className="img-cover" />
                          </a>
                          <div className="info">
                            <div className="tags">
                              <a href={item.herf}> {item.sub1} </a>
                              <a href={item.herf}> {item.sub2} </a>
                            </div>
                            <h3 className="title">
                              <a href={item.herf}> {item.title} </a>
                            </h3>
                            <div className="text">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="float-cursor float_box">
                  Hold <br /> and Drag
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-proj3"
              role="tabpanel"
              aria-labelledby="pills-proj3-tab"
            >
              <div className="projects-content float_box_container">
                <div className="projects-slider">
                  <div className="swiper-wrapper">
                    {data3.map((item, i) => (
                      <div key={i} className="swiper-slide">
                        <div className="project-card">
                          <a
                            href={item.herf}
                            className="img"
                            
                          >
                            <img src={item.img} alt="" className="img-cover" />
                          </a>
                          <div className="info">
                            <div className="tags">
                              <a href={item.herf}> {item.sub1} </a>
                              <a href={item.herf}> {item.sub2} </a>
                            </div>
                            <h3 className="title">
                              <a href={item.herf}> {item.title} </a>
                            </h3>
                            <div className="text">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="float-cursor float_box">
                  Hold <br /> and Drag
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-proj4"
              role="tabpanel"
              aria-labelledby="pills-proj4-tab"
            >
              <div className="projects-content float_box_container">
                <div className="projects-slider">
                  <div className="swiper-wrapper">
                    {data4.map((item, i) => (
                      <div key={i} className="swiper-slide">
                        <div className="project-card">
                          <a
                            href={item.herf}
                            className="img"
                            
                          >
                            <img src={item.img} alt="" className="img-cover" />
                          </a>
                          <div className="info">
                            <div className="tags">
                              <a href={item.herf}> {item.sub1} </a>
                              <a href={item.herf}> {item.sub2} </a>
                            </div>
                            <h3 className="title">
                              <a href={item.herf}> {item.title} </a>
                            </h3>
                            <div className="text">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="float-cursor float_box">
                  Hold <br /> and Drag
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-proj5"
              role="tabpanel"
              aria-labelledby="pills-proj5-tab"
            >
              <div className="projects-content float_box_container">
                <div className="projects-slider">
                  <div className="swiper-wrapper">
                    {data5.map((item, i) => (
                      <div key={i} className="swiper-slide">
                        <div className="project-card">
                          <a
                            href={item.herf}
                            className="img"
                            
                          >
                            <img src={item.img} alt="" className="img-cover" />
                          </a>
                          <div className="info">
                            <div className="tags">
                              <a href={item.herf}> {item.sub1} </a>
                              <a href={item.herf}> {item.sub2} </a>
                            </div>
                            <h3 className="title">
                              <a href={item.herf}> {item.title} </a>
                            </h3>
                            <div className="text">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="float-cursor float_box">
                  Hold <br /> and Drag
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
