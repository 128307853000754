import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Quy Hoạch Nghỉ Dưỡng </a>
           
          </div>
          <h1 className="title"> QUY HOẠCH KHU TỔ HỢP NGHỈ DƯỠNG LAM HILL </h1>
          <div className="text color-666 mt-30">
          Lam Hill Resort: Nơi tâm hồn tìm về chốn bình yên
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m8.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Khu tổ hợp nghỉ dưỡng Lam Hill tọa lạc tại vùng đất Yên Bái, với khung cảnh thiên nhiên hùng vĩ, non nước hữu tình, hứa hẹn mang đến trải nghiệm nghỉ dưỡng độc đáo, kết hợp hài hòa giữa thiên nhiên và dịch vụ cao cấp theo tiêu chuẩn Spa Resort.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Yên Bái
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        20 ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Kiến trúc:<br/></b>
Phong cách: Kiến trúc hiện đại kết hợp với yếu tố truyền thống của văn hóa địa phương, tạo nên nét độc đáo riêng biệt. Sử dụng các vật liệu tự nhiên như gỗ, đá, tre nứa, tạo sự gần gũi với thiên nhiên.
Bố cục: Khu nghỉ dưỡng được bố trí hài hòa, dựa theo địa hình tự nhiên, tận dụng tối đa cảnh quan và tầm nhìn, tạo sự thông thoáng, riêng tư.
Kiến trúc xanh: Ưu tiên sử dụng năng lượng mặt trời, hệ thống xử lý nước thải sinh hoạt, trồng cây xanh tạo bóng mát, góp phần bảo vệ môi trường.
Hình ảnh: Các công trình được thiết kế với hình khối đơn giản, tạo sự thanh thoát, hòa hợp với thiên nhiên.<br/>
Chức năng:<br/>
<b>Khu nghỉ dưỡng:<br/></b>
Gồm các villa, bungalow được thiết kế sang trọng, tiện nghi, có tầm nhìn hướng ra khung cảnh thiên nhiên.
Ưu tiên sử dụng các vật liệu tự nhiên, tạo không gian ấm cúng, thư giãn.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Khu dịch vụ:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Nhà hàng ẩm thực: Cung cấp các món ăn đặc sản địa phương, ẩm thực Á - Âu, với không gian thoáng đãng, hướng nhìn đẹp.
Quán bar: Không gian sang trọng, phục vụ các loại đồ uống, cocktail, cùng âm nhạc nhẹ nhàng.
Khu vui chơi giải trí: Cung cấp các hoạt động giải trí phù hợp với mọi lứa tuổi, ví dụ: bể bơi ngoài trời, khu vui chơi trẻ em, sân tennis, khu vực cắm trại...
Khu vực tổ chức sự kiện: Trang bị đầy đủ tiện nghi cho các buổi hội nghị, hội thảo, tiệc cưới...
Khu vực Spa & Chăm sóc sức khỏe:
Cung cấp dịch vụ massage, yoga, thiền định, liệu pháp trị liệu bằng thiên nhiên, giúp du khách thư giãn, tái tạo năng lượng.
Thiết kế không gian Spa độc đáo, kết hợp với thiên nhiên, mang đến cảm giác thư thái, an nhiên.
Hoạt động trải nghiệm:
Tổ chức các hoạt động du lịch sinh thái, khám phá văn hóa địa phương, như trekking, đạp xe, chèo thuyền kayak, tham quan các làng nghề truyền thống...

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
