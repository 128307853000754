import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> LAKESIDE CLUB HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét thanh lịch hiện đại giữa thiên nhiên thơ mộng
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m19.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Lakeside Club House, tọa lạc tại Quảng Bình, là một công trình kiến trúc nhà tiếp đón (clubhouse) dành cho sân golf được thiết kế theo phong cách hiện đại, hòa quyện tinh tế với thiên nhiên thơ mộng của hồ điều hòa. Công trình mang đến một không gian sống động, sang trọng, phục vụ nhu cầu nghỉ ngơi, giải trí và giao lưu của các golfer.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Quảng Bình
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        360 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LC2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LC3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Hòa quyện thiên nhiên: Lakeside Club House được thiết kế theo phong cách hiện đại, nhưng vẫn giữ được nét thanh lịch, tinh tế, hòa quyện với thiên nhiên xung quanh. Công trình được bố trí dựa lưng vào hồ điều hòa, tạo ra tầm nhìn thoáng đãng, thư giãn, kết nối con người với thiên nhiên.<br/>
          Không gian mở và thông thoáng: Công trình được thiết kế với những ô cửa sổ lớn, giếng trời, ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách.<br/>
          Sự tiện nghi và sang trọng: Lakeside Club House được trang bị đầy đủ các tiện nghi hiện đại, phục vụ tối ưu cho nhu cầu của các golfer, tạo ra một không gian sống động, sang trọng và đầy đủ tiện nghi.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền ấn tượng: Lakeside Club House sở hữu một mặt tiền ấn tượng với những đường nét kiến trúc mạnh mẽ, những gam màu trung tính, những vật liệu hiện đại. Điểm nhấn của mặt tiền là những ô cửa sổ lớn, giếng trời, ban công rộng rãi, tạo nên một diện mạo thoáng đãng, thu hút.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian bên trong.<br/>
Ban công rộng rãi: Ban công rộng rãi được thiết kế để tạo không gian thư giãn, ngắm cảnh hồ điều hòa, tận hưởng không khí trong lành.Ban công rộng rãi: Ban công rộng rãi được thiết kế để tạo không gian thư giãn, ngắm cảnh hồ điều hòa, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LC4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LC5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LC6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất sang trọng: Lakeside Club House sử dụng nội thất cao cấp, mang đến sự sang trọng, tinh tế cho không gian bên trong.<br/>
          Vật liệu hiện đại: Công trình sử dụng những vật liệu hiện đại, thân thiện với môi trường, mang đến vẻ đẹp sang trọng và bền vững.<br/>
          Gam màu trung tính: Gam màu trung tính được sử dụng trong trang trí nội thất, tạo cảm giác thanh lịch, tinh tế cho không gian bên trong.<br/>
<b>Ưu điểm của Lakeside Club House:<br/></b>
KKiến trúc hiện đại: Công trình được thiết kế theo phong cách hiện đại, mang đến một không gian sống động, sang trọng và đầy cảm hứng.<br/>
Hòa quyện thiên nhiên: Công trình được thiết kế với những ô cửa sổ lớn, giếng trời, ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách.<br/>
Sự tiện nghi và sang trọng: Lakeside Club House được trang bị đầy đủ các tiện nghi hiện đại, phục vụ tối ưu cho nhu cầu của các golfer.<br/>
Vị trí thuận lợi: Công trình được bố trí dựa lưng vào hồ điều hòa, tạo ra tầm nhìn thoáng đãng, thư giãn, kết nối con người với thiên nhiên.<br/>
Vị trí thuận lợi: Công trình được bố trí dựa lưng vào hồ điều hòa, tạo ra tầm nhìn thoáng đãng, thư giãn, kết nối con người với thiên nhiên.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LC7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LC3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LC2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LC7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LC5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
