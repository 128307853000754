import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <h1 className="title"> KHU ĐÔ THỊ NAM SÔNG LAM </h1>
          <div className="text color-666 mt-30">
          Kết Nối Xanh, Phát Triển Bền Vững
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m3.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Dự án quy hoạch khu đô thị Nam Sông Lam được thiết kế với mục tiêu tạo dựng một môi trường sống xanh, hiện đại và thân thiện, nơi cư dân có thể hòa mình vào thiên nhiên mà vẫn tận hưởng những tiện ích đô thị. 
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Dịch vụ: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Quy hoạch chi tiết
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Anh Sơn, Nghệ An
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        10.2ha
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img1_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/s_img2_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Khu đô thị Nam Sông Lam được quy hoạch trên diện tích rộng lớn bên bờ sông Lam, với không gian mở và nhiều khu vực xanh. <br />
          Mục tiêu chính là tạo ra một khu đô thị sinh thái, nơi cư dân có thể sống và làm việc trong một môi trường lành mạnh và bền vững.
          <br />
          <br />
          Đặc điểm nổi bật: 
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Kết hợp không gian xanh: Các khu vườn, công viên và bờ sông được thiết kế để tạo không gian xanh mát và tăng cường chất lượng không khí.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Không gian công cộng: Bao gồm các quảng trường, trung tâm cộng đồng, và khu vui chơi giải trí, nhằm tạo điều kiện cho các hoạt động cộng đồng và giao lưu văn hóa.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Tính kết nối: Hệ thống giao thông nội bộ được thiết kế thông minh, đảm bảo sự liên kết giữa các khu vực và thuận tiện cho việc di chuyển.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sức khỏe cộng đồng: Cung cấp một môi trường sống trong lành, giúp cải thiện sức khỏe và tinh thần cho cư dân.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Phát triển kinh tế: Kích thích hoạt động kinh tế thông qua việc thu hút đầu tư và tạo ra cơ hội việc làm mới.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Giao lưu văn hóa: Tạo ra không gian cho các sự kiện văn hóa, nghệ thuật, thể thao, góp phần phong phú đời sống cộng đồng.
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> Sử dụng các giải pháp xây dựng tiên tiến và bền vững, với việc áp dụng các tiêu chuẩn xanh trong quy hoạch và thiết kế.
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/b1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/b2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/b3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng Quan:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Khu đô thị Nam Sông Lam hứa hẹn sẽ trở thành một mô hình tiêu biểu cho sự phát triển đô thị bền vững, 
            nơi mỗi cư dân có thể tìm thấy sự cân bằng giữa cuộc sống hiện đại và môi trường tự nhiên, 
            đồng thời tạo ra một cộng đồng mạnh mẽ và đoàn kết. Đây sẽ là một biểu tượng mới cho sự phát triển đô thị 
            thông minh và bền vững tại Việt Nam.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/b4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/b5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/b6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/b7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/b8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
