import React from 'react';

function Testimonials() {
  return (
    <section className="tc-testimonials-style2">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="img-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="slider-card">
                    <div className="img">
                      <img
                        src="/home2/assets/img/testimonials/testi1.jpg"
                        alt=""
                        className="main-img img-cover"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap1.png"
                        alt=""
                        className="shap1"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap2.png"
                        alt=""
                        className="shap2"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="slider-card">
                    <div className="img">
                      <img
                        src="/home2/assets/img/testimonials/testi2.jpg"
                        alt=""
                        className="main-img img-cover"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap1.png"
                        alt=""
                        className="shap1"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap2.png"
                        alt=""
                        className="shap2"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="slider-card">
                    <div className="img">
                      <img
                        src="/home2/assets/img/testimonials/testi3.jpg"
                        alt=""
                        className="main-img img-cover"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap1.png"
                        alt=""
                        className="shap1"
                      />
                      <img
                        src="/home2/assets/img/testimonials/testi_shap2.png"
                        alt=""
                        className="shap2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="arrows">
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-side">
              <div className="text-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="text sub-font">
                      “Toàn bộ nhóm đã khéo léo thực hiện một dự án
                      chất lượng được đảm bảo trong khi vẫn đúng tiến độ và dưới
                      ngân sách. Nhiều hơn những gì tôi mong đợi. Tôi cảm thấy ưng
                       ý và chắc chắn sẽ hợp tác tiếp tục trong tương lai.”
                    </div>
                    <div className="user-wrapper">
                      <div className="user-content">
                        <div className="img">
                          <img
                            src="/home1/assets/img/team/team1.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="inf">
                          <h6 className="fsz-18"> Bùi Thanh Hằng </h6>
                          <p className="color-ccc mt-2"> Top 5 miss Universe Viet Nam 2017 </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="text sub-font">
                      “Toàn bộ nhóm đã khéo léo thực hiện một dự án
                      chất lượng được đảm bảo trong khi vẫn đúng tiến độ và dưới
                      ngân sách. Nhiều hơn những gì tôi mong đợi. Tôi cảm thấy ưng
                       ý và chắc chắn sẽ hợp tác tiếp tục trong tương lai.”
                    </div>
                    <div className="user-wrapper">
                      <div className="user-content">
                        <div className="img">
                          <img
                            src="/home1/assets/img/team/team2.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="inf">
                          <h6 className="fsz-18">  Bùi Tuấn Ngọc </h6>
                          <p className="color-ccc mt-2"> Ban ngoại giao tổng cục chính trị bộ quốc phòng </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="text sub-font">
                    "Tôi thấy đúng như kỳ vọng với dịch vụ của Nexsa trong dự án này. 
                      Chất lượng của Nexcon mang lại kết quả ấn tượng với tôi."
                    </div>
                    <div className="user-wrapper">
                      <div className="user-content">
                        <div className="img">
                          <img
                            src="/home1/assets/img/team/team3.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="inf">
                          <h6 className="fsz-18"> Vũ Thành Công </h6>
                          <p className="color-ccc mt-2">  Co-Fouder Công ty cổ phần công nghệ viễn thông AWing  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider-controls">
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="logos">
          <a href="#">
            <img src="/home2/assets/img/clients/cl1.png" alt="" />
          </a>
          <a href="#">
            <img src="/home2/assets/img/clients/cl2.png" alt="" />
          </a>
          <a href="#">
            <img src="/home2/assets/img/clients/cl3.png" alt="" />
          </a>
          <a href="#">
            <img src="/home2/assets/img/clients/cl4.png" alt="" />
          </a>
          <a href="#">
            <img src="/home2/assets/img/clients/cl5.png" alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
