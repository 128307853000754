import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Biệt thự </a>``
          </div>
          <h1 className="title">  TROPICAL HAVEN  </h1>
          <div className="text color-666 mt-30">
          Trải nghiệm cuộc sống xanh, hạnh phúc viên mãn

          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m30.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Nằm giữa khung cảnh thơ mộng của Thái Nguyên, dự án "Tropical Haven" là một tuyệt tác kiến trúc độc đáo, kết hợp hài hòa giữa phong cách hiện đại và nét đẹp nhiệt đới, mang đến một không gian sống lý tưởng cho những ai yêu thích sự thanh bình và gần gũi thiên nhiên.

          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch vụ
                        </small>
                        <div className="links fsz-18 fw-500">
                         Thiết kế <br />
                         Thi công <br />
                         Xây dựng
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm:

                        </small>
                        <div className="links fsz-18 fw-500">
                        Thái Nguyên
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích:

                        </small>
                        <div className="links fsz-18 fw-500">
                        450 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án: 

                        </small>
                        <div className="links fsz-18 fw-500">
                        Thiết kế kiến trúc
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i30_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i30_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Kiến trúc độc đáo:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Phong cách hiện đại kết hợp tropical: Sử dụng các đường nét thanh thoát, bố cục cân đối, kết hợp với các chi tiết trang trí mang hơi thở nhiệt đới, tạo nên vẻ đẹp độc đáo và thu hút.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Facade thoáng đãng: Thiết kế gồm nhiều ô thoáng độc đáo giúp đón ánh sáng tự nhiên tối đa, mang đến cảm giác thông thoáng và tràn đầy năng lượng cho không gian nội thất bên trong.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Kết nối hài hòa với thiên nhiên: Sử dụng các vật liệu tự nhiên như gỗ, đá, cây xanh, tạo sự gần gũi và hài hòa với môi trường xung quanh, mang đến cảm giác thư giãn và thanh bình.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Hồ bơi riêng tư: Tạo điểm nhấn ấn tượng cho không gian sống, giúp gia chủ tận hưởng cảm giác thư giãn và sảng khoái ngay tại nhà.

            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Không gian sống tiện nghi:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Nội thất hiện đại: Thiết kế nội thất tinh tế, tối giản, mang đến sự tiện nghi và thoải mái cho gia chủ.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Công năng tối ưu: Tận dụng tối đa diện tích, tạo nên các không gian chức năng đa dạng và phù hợp với nhu cầu của gia đình.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Tiện ích phong phú: Bao gồm sân vườn, khu vực BBQ ngoài trời, phòng gym, khu vui chơi trẻ em,... mang đến cuộc sống tiện nghi và trọn vẹn.

            </li>
          </ul>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Kết quả:
          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Mang đến một không gian sống lý tưởng, kết nối hài hòa con người và thiên nhiên.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Tạo dựng một phong cách sống hiện đại, tiện nghi và đầy đủ tiện nghi.

            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>Nâng cao giá trị cuộc sống cho gia chủ, tạo nên một tổ ấm lý tưởng.

            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj30_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj30_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj30_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Thông điệp:

          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            "Tropical Haven" không chỉ là một căn biệt thự, mà là một chốn an cư lý tưởng, nơi mà hạnh phúc được vun trồng và cuộc sống được tận hưởng trọn vẹn.

          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_9.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_10.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj30_11.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
