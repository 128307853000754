import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Lâu Đài Việt: Kiến Trúc Hòa Quyện Giữa Phong Cách Âu Và Hồn Việt </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Lâu đài, biểu tượng của quyền uy, sự xa hoa và tráng lệ, thường được liên tưởng đến các quốc gia châu Âu. Tuy nhiên, ở Việt Nam, nơi mang nét văn hóa Á Đông đậm nét, cũng xuất hiện những ngôi nhà mang kiến trúc lâu đài, tạo nên sự tò mò và băn khoăn cho nhiều người: Tại sao có những ngôi nhà kiểu lâu đài tại Việt Nam?
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. Ảnh hưởng Của Kiến Trúc Âu Châu:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Lịch sử thực dân:</strong> Việt Nam từng là thuộc địa của Pháp trong hơn một thế kỷ, đã để lại những ảnh hưởng sâu sắc về văn hóa, kiến trúc. Nhiều công trình kiến trúc Pháp được xây dựng tại Việt Nam, trong đó có những ngôi nhà mang phong cách lâu đài, như Dinh Độc Lập, Dinh Thống Nhất, Nhà hát Lớn Hà Nội...</h6>            
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Du nhập văn hóa:</strong> Sau khi đạt độc lập, Việt Nam tiếp tục giao lưu và hợp tác với các nước trên thế giới, trong đó có nhiều nước Âu Châu. Kiến trúc lâu đài cũng được du nhập vào Việt Nam qua con đường giao lưu văn hóa, ảnh hưởng đến lối sống và phong cách kiến trúc của người Việt.</h6>            
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nhu cầu thẩm mỹ:</strong> Kiến trúc lâu đài mang đến vẻ đẹp xa hoa, tráng lệ, thể hiện sự quyền uy, sự thành công, nên được nhiều người ưa chuộng.</h6>            
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Hòa Quyện Giữa Phong Cách Âu Và Hồn Việt:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết hợp hài hòa:</strong> Kiến trúc lâu đài tại Việt Nam không phải là sự sao chép hoàn toàn kiến trúc Âu Châu. Kiến trúc sư Việt Nam đã kết hợp hài hòa giữa phong cách Âu Châu và những nét đặc trưng của kiến trúc truyền thống Việt Nam, tạo nên sự độc đáo, mang đậm bản sắc Việt.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Mái ngói cong vút:</strong> Nhiều ngôi nhà kiểu lâu đài tại Việt Nam vẫn giữ lại mái ngói cong vút, tạo nên nét đẹp kiến trúc truyền thống.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Hệ thống cửa sổ, giếng trời:</strong> Những ngôi nhà kiểu lâu đài tại Việt Nam thường có hệ thống cửa sổ, giếng trời, giúp đón ánh sáng tự nhiên, tạo sự thoáng đáng, thoáng mát cho không gian, phù hợp với khí hậu nhiệt đới ẩm gió mùa của Việt Nam.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Sự Phát Triển Của Kiến Trúc Lâu Đài Tại Việt Nam:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Xu hướng nhà ở cao cấp:</strong> Kiến trúc lâu đài thường được ưa chuộng trong các dự án nhà ở cao cấp, biệt thự, resort, thể hiện sự xa hoa, quyền uy và sự thành công của chủ nhân.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự đa dạng về phong cách:</strong> Kiến trúc lâu đài tại Việt Nam ngày càng đa dạng về phong cách, kết hợp hài hòa giữa phong cách Âu Châu và những nét đặc trưng của kiến trúc truyền thống Việt Nam, tạo nên những ngôi nhà độc đáo, mang đậm bản sắc Việt.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự phát triển bền vững:</strong> Nhiều ngôi nhà kiểu lâu đài tại Việt Nam được xây dựng theo các tiêu chuẩn xanh, ứng dụng công nghệ tiết kiệm năng lượng, thân thiện môi trường, góp phần bảo vệ môi trường sống.</h6>
            </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            4. Kết luận:</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kiến trúc lâu đài tại Việt Nam là minh chứng cho sự hòa quyện tinh tế giữa phong cách Âu Châu và hồn Việt. Sự kết hợp này tạo nên sự độc đáo, hấp dẫn cho kiến trúc Việt Nam, thể hiện sự thành công trong việc kết hợp những yếu tố truyền thống và hiện đại. Kiến trúc lâu đài không chỉ là sự thể hiện thẩm mỹ, mà còn là lời khẳng định về sự phát triển và tự tin của kiến trúc Việt Nam trong thời đại mới.</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
