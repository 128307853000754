import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Kiến Trúc Bền Vững: Tiết Kiệm Năng Lượng & Tối Ưu Hóa Không Gian Sống

                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Giới thiệu:

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Trong bối cảnh biến đổi khí hậu toàn cầu và nhu cầu sử dụng năng lượng ngày càng gia tăng, kiến trúc bền vững đang trở thành một trong những ưu tiên hàng đầu của ngành xây dựng. Kiến trúc bền vững không chỉ hướng đến việc tạo ra những công trình đẹp mắt, tiện nghi mà còn tập trung vào việc tối ưu hóa sử dụng năng lượng, bảo vệ môi trường và nâng cao chất lượng cuộc sống.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bài viết này sẽ phân tích đánh giá các nghiên cứu liên quan đến các giải pháp tiết kiệm năng lượng trong thiết kế kiến trúc, đặc biệt là ứng dụng công nghệ mặt đứng thông minh và xu hướng thiết kế lớp vỏ công trình tiết kiệm năng lượng. Bên cạnh đó, bài viết sẽ giới thiệu về kiến trúc sinh thái, kiến trúc xanh và những giải pháp sử dụng hiệu quả năng lượng trong thiết kế kiến trúc.
              </h6>
              <br />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b21_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b21_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
<br />
<br />
<h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              2. Các Giải Pháp Tiết Kiệm Năng Lượng Trong Thiết Kế Kiến Trúc:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               2.1. Ứng dụng Công Nghệ Mặt Đứng Thông Minh:

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Khái niệm: Mặt đứng thông minh là hệ thống mặt đứng của công trình được tích hợp các công nghệ tiên tiến giúp điều chỉnh ánh sáng, nhiệt độ và thông gió một cách tự động, tối ưu hóa sử dụng năng lượng.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
            Cơ chế hoạt động: Mặt đứng thông minh sử dụng các cảm biến, bộ điều khiển và các thiết bị điều chỉnh như kính thông minh, tấm che nắng, hệ thống thông gió tự động… để phản ứng linh hoạt với các yếu tố môi trường như ánh sáng mặt trời, nhiệt độ, độ ẩm…


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Tiết kiệm năng lượng: Giảm thiểu lượng nhiệt truyền vào bên trong công trình, giảm tải cho hệ thống điều hòa, giảm tiêu thụ điện năng.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Bảo vệ môi trường: Giảm lượng khí thải CO2, góp phần bảo vệ môi trường.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Tăng cường sự thoải mái: Tạo ra môi trường làm việc và sinh hoạt thoải mái, dễ chịu.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               2.2. Xu Hướng Thiết Kế Lớp Vỏ Công Trình Tiết Kiệm Năng Lượng:

              </h6>
               
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Vật liệu cách nhiệt: Sử dụng các vật liệu cách nhiệt hiệu quả như bông thủy tinh, xốp EPS, PU foam… giúp giảm thiểu lượng nhiệt truyền vào bên trong công trình.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Kính cách nhiệt: Kính cách nhiệt có khả năng phản chiếu ánh sáng mặt trời, giảm thiểu lượng nhiệt truyền vào bên trong công trình.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Tấm che nắng: Thiết kế các tấm che nắng giúp chắn nắng trực tiếp, giảm thiểu lượng nhiệt truyền vào bên trong công trình.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Hệ thống thông gió tự nhiên: Tận dụng gió tự nhiên để thông gió cho công trình, giảm tải cho hệ thống điều hòa, giảm tiêu thụ điện năng.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Thiết kế kiến trúc: Thiết kế kiến trúc hợp lý với hướng nắng, hướng gió, tận dụng ánh sáng tự nhiên, giảm thiểu diện tích tiếp xúc với ánh nắng mặt trời.


              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              2.3. Công Trình Kiến Trúc Sinh Thái – Kiến Trúc Xanh – Tiết Kiệm Năng Lượng:

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
             Khái niệm: Kiến trúc sinh thái, kiến trúc xanh, kiến trúc tiết kiệm năng lượng là những thuật ngữ chỉ những công trình được thiết kế và xây dựng theo những tiêu chí bền vững, tập trung vào việc bảo vệ môi trường, tiết kiệm năng lượng và nâng cao chất lượng cuộc sống.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Các tiêu chí:


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
             Sử dụng vật liệu xanh: Sử dụng các vật liệu tự nhiên, thân thiện với môi trường, có khả năng tái chế, giảm thiểu lượng khí thải CO2.


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Tiết kiệm nước: Áp dụng các giải pháp tiết kiệm nước như sử dụng hệ thống thu gom nước mưa, thiết bị tiết kiệm nước…


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Tối ưu hóa năng lượng: Tận dụng ánh sáng tự nhiên, gió tự nhiên, sử dụng năng lượng tái tạo như năng lượng mặt trời, năng lượng gió…


              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Xây dựng môi trường sống xanh: Tạo ra không gian xanh, tăng cường diện tích cây xanh, giảm thiểu ô nhiễm môi trường.
              </h6>  
<h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              3. Giải Pháp Sử Dụng Hiệu Quả Năng Lượng Trong Thiết Kế Kiến Trúc:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                3.1. Tận Dụng Ánh Sáng Tự Nhiên:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thiết kế cửa sổ: Thiết kế cửa sổ thông minh, có thể điều chỉnh ánh sáng, hướng gió, tận dụng tối đa ánh sáng tự nhiên, giảm thiểu lượng nhiệt truyền vào bên trong công trình.</h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Bố trí nội thất: Bố trí nội thất hợp lý, tận dụng ánh sáng tự nhiên, giảm thiểu sử dụng ánh sáng nhân tạo.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Sử dụng vật liệu phản chiếu ánh sáng: Sử dụng các vật liệu phản chiếu ánh sáng như gương, kính… để phản chiếu ánh sáng tự nhiên vào những khu vực thiếu sáng.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.2. Tận Dụng Gió Tự Nhiên:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thiết kế thông gió: Thiết kế hệ thống thông gió tự nhiên hiệu quả, tận dụng gió mát từ hướng Đông, giảm tải cho hệ thống điều hòa, giảm tiêu thụ điện năng.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Kênh gió: Thiết kế các kênh gió, mái thông gió giúp tạo ra luồng không khí tự nhiên, thông thoáng cho công trình.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >3.3. Sử Dụng Năng Lượng Tái Tạo:</h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Năng lượng mặt trời: Sử dụng năng lượng mặt trời để sản xuất điện cho công trình, giảm thiểu sự phụ thuộc vào năng lượng dầu mỏ.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Năng lượng gió: Sử dụng năng lượng gió để sản xuất điện, phù hợp với những vùng có gió mạnh.</h6>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b21_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b21_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b21_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >4. Các Nghiên Cứu Liên Quan:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >4.1. Nghiên cứu về Vật liệu Xây dựng:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vật liệu cách nhiệt: Các nghiên cứu về vật liệu cách nhiệt cho thấy hiệu quả của các vật liệu cách nhiệt trong việc giảm thiểu lượng nhiệt truyền vào bên trong công trình.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vật liệu xanh: Các nghiên cứu về vật liệu xanh cho thấy vai trò quan trọng của vật liệu xanh trong việc bảo vệ môi trường và nâng cao chất lượng cuộc sống.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Vật liệu tái chế: Các nghiên cứu về vật liệu tái chế cho thấy khả năng tái chế vật liệu xây dựng, giảm thiểu lượng rác thải và bảo vệ môi trường.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >4.2. Nghiên cứu về Thiết Kế Kiến Trúc:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thiết kế kiến trúc xanh: Các nghiên cứu về thiết kế kiến trúc xanh cho thấy hiệu quả của việc áp dụng những nguyên tắc bền vững trong thiết kế kiến trúc.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thiết kế thông gió tự nhiên: Các nghiên cứu về thiết kế thông gió tự nhiên cho thấy hiệu quả của hệ thống thông gió tự nhiên trong việc tạo ra luồng không khí tự nhiên, thông thoáng cho công trình.</h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tận dụng ánh sáng tự nhiên: Các nghiên cứu về tận dụng ánh sáng tự nhiên cho thấy hiệu quả của việc tận dụng ánh sáng tự nhiên trong việc giảm thiểu sử dụng ánh sáng nhân tạo, tiết kiệm năng lượng.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >4.3. Nghiên cứu về Năng Lượng Tái Tạo:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Năng lượng mặt trời: Các nghiên cứu về năng lượng mặt trời cho thấy khả năng sử dụng năng lượng mặt trời để sản xuất điện cho công trình.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Năng lượng gió: Các nghiên cứu về năng lượng gió cho thấy khả năng sử dụng năng lượng gió để sản xuất điện, phù hợp với những vùng có gió mạnh.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Năng lượng địa nhiệt: Các nghiên cứu về năng lượng địa nhiệt cho thấy khả năng sử dụng năng lượng địa nhiệt để sưởi ấm và làm mát cho công trình.
              </h6>
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b21_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b21_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             5. Kết Luận:

            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Kiến trúc bền vững là hướng đi tất yếu cho ngành xây dựng trong tương lai, góp phần bảo vệ môi trường, tiết kiệm năng lượng và nâng cao chất lượng cuộc sống. Việc ứng dụng công nghệ mặt đứng thông minh, thiết kế lớp vỏ công trình tiết kiệm năng lượng, kiến trúc sinh thái, kiến trúc xanh và các giải pháp sử dụng hiệu quả năng lượng trong thiết kế kiến trúc là những giải pháp quan trọng để thực hiện mục tiêu này.


            </h6>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Sự phát triển của công nghệ và nghiên cứu khoa học sẽ cung cấp thêm nhiều giải pháp mới cho kiến trúc bền vững, góp phần tạo ra những công trình xanh, thân thiện với môi trường và tối ưu hóa sử dụng năng lượng.


            </h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
