import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Kiến Trúc Đông Dương: Hòa Quyện Giữa Phong Cách Âu Và Hồn Việt </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Kiến trúc Đông Dương, một phong cách kiến trúc độc đáo mang dấu ấn của thời kỳ thực dân Pháp tại Việt Nam, là sự kết hợp tinh tế giữa phong cách kiến trúc Âu Châu và nét đặc trưng của kiến trúc truyền thống Việt Nam. Nó không chỉ là một phong cách kiến trúc mà còn là minh chứng cho sự giao thoa văn hóa sâu sắc, tạo nên một di sản kiến trúc độc đáo và hấp dẫn cho Việt Nam.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Sự Ra Đời Của Kiến Trúc Đông Dương:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Ảnh hưởng của thực dân Pháp:</strong> Trong thời kỳ thực dân Pháp (1858-1954), Việt Nam trở thành thuộc địa của Pháp, đã để lại những ảnh hưởng sâu sắc về văn hóa, kiến trúc. Kiến trúc Pháp được du nhập vào Việt Nam, tạo nên những công trình kiến trúc lớn như Dinh Độc Lập, Dinh Thống Nhất, Nhà hát Lớn Hà Nội...</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự kết hợp hài hòa:</strong> Kiến trúc sư Pháp đã không sao chép hoàn toàn kiến trúc Âu Châu mà đã biết kết hợp hài hòa với những nét đặc trưng của kiến trúc truyền thống Việt Nam, tạo nên một phong cách kiến trúc mới, mang đậm bản sắc Việt.</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự ra đời của phong cách kiến trúc Đông Dương:</strong> Phong cách kiến trúc này thường được gọi là phong cách kiến trúc Đông Dương, hay còn gọi là phong cách kiến trúc "Indochine".</h6>  
            </li>
      </ul>
          </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               2. Đặc Trưng Của Kiến Trúc Đông Dương:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết hợp giữa phong cách Âu Châu và kiến trúc truyền thống Việt Nam:</strong> Kiến trúc Đông Dương mang nét đẹp của kiến trúc Pháp, như sự thanh thoát, đơn giản của các đường nét, kết hợp với những nét đặc trưng của kiến trúc truyền thống Việt Nam, như mái ngói cong vút, hệ thống cửa sổ, giếng trời, tạo nên sự hài hòa, độc đáo.</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sử dụng vật liệu tại chỗ:</strong> Kiến trúc Đông Dương thường sử dụng những vật liệu tại chỗ, như gỗ, tre, nứa, đá, gạch nướng, mang đến sự thân thiện với môi trường và phù hợp với khí hậu nhiệt đới ẩm gió mùa của Việt Nam.</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tận dụng ánh sáng tự nhiên:</strong> Kiến trúc Đông Dương luôn chú trọng đến việc tận dụng ánh sáng tự nhiên, tạo nên không gian thoáng đáng, thoáng mát, giúp giảm thiểu sử dụng ánh sáng nhân tạo, tiết kiệm năng lượng.</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết hợp với thiên nhiên:</strong> Kiến trúc Đông Dương thường kết hợp với thiên nhiên, tạo ra những không gian xanh mát, gần gũi với thiên nhiên, mang đến cảm giác thư giãn và thoải mái.</h6>  
            </li>
      </ul>
          </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               3. Sự Phát Triển Của Kiến Trúc Đông Dương:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Bảo tồn và phát huy:</strong> Hiện nay, nhiều công trình kiến trúc Đông Dương đang được bảo tồn và phát huy giá trị, như Dinh Độc Lập, Dinh Thống Nhất, Nhà hát Lớn Hà Nội...</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Kết hợp với kiến trúc hiện đại:</strong> Kiến trúc sư Việt Nam đang kết hợp hài hòa giữa phong cách kiến trúc Đông Dương và kiến trúc hiện đại, tạo nên những công trình độc đáo, mang đậm bản sắc Việt nhưng vẫn đáp ứng được nhu cầu của thời đại.</h6>  
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự hồi sinh:</strong> Phong cách kiến trúc Đông Dương đang có dấu hiệu hồi sinh, được ứng dụng trong thiết kế các công trình nhà ở, khách sạn, resort, mang đến sự độc đáo và hấp dẫn cho các công trình.</h6>  
            </li>
      </ul>
          </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            4. Kết luận:</h6>
            <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kiến trúc Đông Dương là minh chứng cho sự giao thoa văn hóa sâu sắc giữa Việt Nam và Pháp. Nó là sự kết hợp tinh tế giữa phong cách kiến trúc Âu Châu và nét đặc trưng của kiến trúc truyền thống Việt Nam, tạo nên một di sản kiến trúc độc đáo và hấp dẫn. Kiến trúc Đông Dương không chỉ là một phong cách kiến trúc mà còn là minh chứng cho sự phát triển và tự tin của kiến trúc Việt Nam.</h6>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
