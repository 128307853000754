import React from 'react';

function Insatgram() {
  return (
    <section className="tc-instagram-style4">
      <div className="container">
        <div className="title mb-120">
          <h2 className="sec-title mb-30 js-splittext-lines"> Một số Công trình </h2>
          <p className="fsz-18 color-666 js-splittext-lines">
           Nổi bật mà chúng tôi đã triển khai.
          </p>
        </div>
        <div className="imgs">
          <div className="row gx-2">
            <div className="col-lg-3">
              <a
                href="/SingleProject24"
                className="img d-block th-300 mt-3 mt-lg-0 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <img
                  src="/innerpages/assets/img/s_project/m24.png"
                  alt=""
                  className="img-cover"
                />
                <span className="icon">
                  <i>LY HOUSE</i>
                </span>
              </a>
            </div>
            <div className="col-lg-3">
              <a
                href="/SingleProject27"
                className="img d-block th-300 mt-3 mt-lg-0 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <img
                  src="/innerpages/assets/img/s_project/m27.jpg"
                  alt=""
                  className="img-cover"
                />
                <span className="icon">
                  <i>T House</i>
                </span>
              </a>
            </div>
            <div className="col-lg-3">
              <a
                href="/SingleProject13"
                className="img d-block th-300 mt-3 mt-lg-0 wow zoomIn slow"
                data-wow-delay="0.6s"
              >
                <img
                  src="/innerpages/assets/img/s_project/m13.jpg"
                  alt=""
                  className="img-cover"
                />
                <span className="icon">
                  <i>Hà House</i>
                </span>
              </a>
            </div>
            <div className="col-lg-3">
              <a
                href="/SingleProject15"
                className="img d-block th-300 mt-3 mt-lg-0 wow zoomIn slow"
                data-wow-delay="0.8s"
              >
                <img
                  src="/innerpages/assets/img/s_project/m15.jpg"
                  alt=""
                  className="img-cover"
                />
                <span className="icon">
                  <i>NBL House</i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Insatgram;
