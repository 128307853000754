import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> NAS TROPICAL </h1>
          <div className="text color-666 mt-30">
          Nơi hòa quyện giữa Bắc Âu và Nhiệt đới
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m28.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          NAS TROPICAL, tọa lạc tại ngoại ô, là một công trình kiến trúc nhà ở cá nhân được thiết kế theo phong cách đồng quê hiện đại Bắc Âu, kết hợp hài hòa với những yếu tố nhiệt đới đặc trưng, mang đến một không gian sống độc đáo, thanh bình và tràn đầy năng lượng.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoài Đức
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        320 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Hòa quyện hai phong cách: NAS TROPICAL là sự kết hợp độc đáo giữa phong cách đồng quê hiện đại Bắc Âu và những nét đẹp nhiệt đới. Phong cách Bắc Âu mang đến sự tối giản, tinh tế, ấm cúng với gam màu nhẹ nhàng, vật liệu tự nhiên, trong khi đó, yếu tố nhiệt đới tạo nên sự tươi mới, năng động với những khoảng xanh, ánh sáng tự nhiên và những vật liệu địa phương.<br/>
Kết nối với thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống. Hệ thống cửa sổ lớn, ban công rộng rãi, giếng trời, những khoảng xanh được bố trí khéo léo, tạo nên một không gian thông thoáng, gần gũi với thiên nhiên.

<br/>
<b>Kiến trúc:<br/></b>
Hình khối đơn giản: Ngôi nhà được thiết kế với những hình khối đơn giản, mạnh mẽ, tạo nên vẻ đẹp thanh lịch và hiện đại.<br/>
Mái dốc truyền thống: Mái dốc truyền thống, được thiết kế theo phong cách Bắc Âu, tạo nên vẻ đẹp ấm cúng và thân thiện.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Sân vườn xanh mát: Ngôi nhà được bao quanh bởi một khu vườn xanh mát, tạo không gian thư giãn, gần gũi với thiên nhiên cho gia đình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/HH4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Ưu điểm:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Sự độc đáo trong thiết kế: NAS TROPICAL là sự kết hợp hài hòa giữa phong cách đồng quê hiện đại Bắc Âu và những yếu tố nhiệt đới, tạo nên một không gian sống độc đáo.<br/>
         Hòa quyện thiên nhiên: Ngôi nhà được thiết kế mở, tận dụng tối đa ánh sáng tự nhiên và gió trời, mang thiên nhiên vào không gian sống.<br/>
         Công năng tối ưu: Ngôi nhà được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình.<br/>
         Thân thiện môi trường: Ngôi nhà được xây dựng bằng những vật liệu tự nhiên, góp phần bảo vệ môi trường sống.<br/>
         NAS TROPICAL là một công trình kiến trúc nhà ở cá nhân độc đáo, mang đến một không gian sống thanh bình, tiện nghi và tràn đầy năng lượng. Ngôi nhà là sự kết hợp hài hòa giữa phong cách Bắc Âu và những yếu tố nhiệt đới, tạo nên một không gian sống lý tưởng cho gia đình.<br/>


          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m28.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/HH2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
