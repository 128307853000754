import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Kiến trúc xanh: Hòa nhịp thiên nhiên, nâng tầm cuộc sống
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Không gian xanh: Lời khẳng định về sự thống nhất và hài hòa
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >1.1. Sự trỗi dậy của xu hướng kiến trúc xanh:</h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Trong bối cảnh môi trường biến đổi toàn cầu và nhu cầu về không gian sống xanh, sạch đẹp ngày càng tăng cao, xu hướng kiến trúc xanh đã trở thành một lựa chọn tất yếu cho các công trình kiến trúc hiện đại. Kiến trúc xanh không chỉ đơn thuần là việc sử dụng cây xanh trong thiết kế, mà còn là sự kết hợp hài hòa giữa không gian xây dựng và môi trường thiên nhiên, tạo ra một hệ sinh thái bền vững và thân thiện với con người.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >1.2. Hòa nhịp thiên nhiên - Nâng tầm chất lượng sống:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Kiến trúc xanh mang đến nhiều lợi ích cho con người và môi trường:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Cải thiện chất lượng không khí: Cây xanh có khả năng hấp thụ CO2 và phát ra oxy, giúp cải thiện chất lượng không khí, tạo ra môi trường sống trong lành hơn.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Giảm thiểu hiệu ứng nhà kính: Cây xanh giúp hấp thụ nhiệt lượng từ mặt trời, giảm thiểu hiệu ứng nhà kính, giúp điều hòa nhiệt độ cho không gian sống.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Giảm ô nhiễm tiếng ồn: Cây xanh có khả năng hấp thụ tiếng ồn, tạo ra môi trường sống yên tĩnh và thoải mái hơn.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Tăng cường tính thẩm mỹ: Cây xanh mang đến sự thẩm mỹ cho không gian, tạo ra cảm giác thư giãn và yên bình, nâng cao chất lượng cuộc sống.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Thúc đẩy sức khỏe: Việc tiếp xúc với cây xanh có tác động tích cực đến sức khỏe của con người, giúp giảm stress, cải thiện tâm trạng và tăng cường sức khỏe.
              </h6>
              <br />
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b23_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b23_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2. Xu hướng thiết kế không gian xanh: Hành trình kết nối và hài hòa
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.1. Mang thiên nhiên vào trong không gian sống:</h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiết kế vườn treo: Sử dụng không gian trên mái nhà để tạo ra những khu vườn treo xanh mát, mang lại cảm giác thư giãn và gần gũi với thiên nhiên.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiết kế tường cây xanh: Sử dụng tường xanh để tăng cường mảng xanh cho không gian, tạo ra không khí trong lành và tăng cường thẩm mỹ cho không gian.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiết kế ban công xanh: Tận dụng không gian ban công để tạo ra những khu vườn nhỏ xanh mát, mang lại cảm giác thư giãn và gần gũi với thiên nhiên.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiết kế nội thất xanh: Sử dụng cây xanh trong nội thất để tăng cường mảng xanh cho không gian, tạo ra không khí trong lành và tăng cường thẩm mỹ cho không gian.
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.2. Hài hòa kiến trúc với môi trường:
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Sử dụng vật liệu thân thiện môi trường: Lựa chọn những vật liệu xây dựng thân thiện với môi trường, có khả năng tái chế và giảm thiểu tác động tiêu cực đến môi trường.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Áp dụng các giải pháp tiết kiệm năng lượng: Sử dụng các giải pháp tiết kiệm năng lượng như năng lượng mặt trời, năng lượng gió, v.v., giúp giảm thiểu tác động tiêu cực đến môi trường.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Xây dựng hệ thống xử lý nước thải: Xây dựng hệ thống xử lý nước thải hiệu quả, giảm thiểu ô nhiễm môi trường nước.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Sử dụng ánh sáng tự nhiên: Tận dụng ánh sáng tự nhiên tối đa cho không gian, giảm thiểu việc sử dụng ánh sáng nhân tạo, tiết kiệm năng lượng và bảo vệ môi trường.
        </h6>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b23_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b23_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b23_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3. Tái tạo không gian sống kết nối hài hòa với môi trường thiên nhiên:
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.1. Nâng cao ý thức về kiến trúc xanh:
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Truyền thông và giáo dục: Tăng cường truyền thông và giáo dục để nâng cao ý thức của người dân về kiến trúc xanh, những lợi ích của kiến trúc xanh và vai trò của mỗi người trong việc bảo vệ môi trường.</h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Chính sách khuyến khích: Ban hành những chính sách khuyến khích phát triển kiến trúc xanh, như giảm thuế cho những công trình áp dụng kiến trúc xanh, v.v.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Khuyến khích sử dụng vật liệu thân thiện môi trường: Khuyến khích người dân sử dụng những vật liệu xây dựng thân thiện với môi trường, có khả năng tái chế và giảm thiểu tác động tiêu cực đến môi trường.
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.2. Tạo lên những điểm nhấn riêng cho dự án:</h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thiết kế độc đáo: Tạo ra những thiết kế độc đáo, mang đậm nét riêng cho dự án, thể hiện sự hài hòa giữa kiến trúc và thiên nhiên.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Sử dụng cây xanh độc đáo: Lựa chọn những loài cây xanh độc đáo, phù hợp với khí hậu và phong cách thiết kế của dự án, tạo nên điểm nhấn cho không gian.
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Tạo ra những không gian xanh chức năng: Tạo ra những không gian xanh chức năng như khu vườn thư giãn, khu vườn trồng rau sạch, v.v., mang lại lợi ích cho cuộc sống.
        </h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Kiến trúc xanh: Hành trình kết nối và hài hòa</h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Tầm quan trọng của kiến trúc xanh trong sự phát triển bền vững:
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Kiến trúc xanh không chỉ góp phần cải thiện chất lượng cuộc sống của con người, mà còn là một phần quan trọng trong việc xây dựng một xã hội bền vững. Kiến trúc xanh giúp giảm thiểu tác động tiêu cực đến môi trường, tiết kiệm năng lượng và tăng cường sức khỏe cho con người.</h6>
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Hành động của mỗi người:
        </h6>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Mỗi người đều có thể góp phần xây dựng một xã hội bền vững bằng cách tham gia vào những hoạt động bảo vệ môi trường, sử dụng những sản phẩm thân thiện với môi trường và ủng hộ những dự án kiến trúc xanh.
        </h6>
          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b23_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b23_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             5. Kết Luận:
            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kiến trúc xanh không chỉ là một xu hướng, mà còn là một lựa chọn tất yếu cho sự phát triển bền vững của xã hội. Kiến trúc xanh giúp con người sống hài hòa với thiên nhiên, tạo ra một môi trường sống trong lành, tiện nghi và ấn tượng. Hãy cùng nhau nâng cao ý thức về kiến trúc xanh, góp phần xây dựng một xã hội bền vững cho thế hệ tương lai.
            </h6>
            <br />
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
