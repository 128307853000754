import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất </a>
           
          </div>
          <h1 className="title"> LY HOUSE </h1>
          <div className="text color-666 mt-30">
          Nét bình yên Nhật Bản giữa lòng Hà Nội
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m24.png"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Ly House, tọa lạc tại Hà Nội, là một công trình kiến trúc nhà ở cá nhân được thiết kế theo phong cách Japandi, mang đến một không gian sống thanh bình, tinh tế và đầy cảm hứng. Công trình kết hợp hài hòa giữa nét đẹp tối giản của phong cách Nhật Bản và sự ấm cúng của phong cách Scandinavian, tạo nên một không gian sống độc đáo, phù hợp với cá tính của gia chủ. Điểm nhấn của Ly House là giải pháp sử dụng lam che nắng mặt tiền, vừa mang tính thẩm mỹ, vừa đảm bảo công năng, tạo nên một vẻ đẹp thanh lịch và độc đáo cho ngôi nhà.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hà Nội
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        172 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH2.png"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m24.png"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Tối giản và tinh tế: Ly House được thiết kế theo phong cách Japandi, toát lên vẻ đẹp tối giản, tinh tế và thanh lịch. Những đường nét kiến trúc đơn giản, những gam màu trung tính, những vật liệu tự nhiên, tất cả tạo nên một không gian sống thanh bình, thư giãn.<br/>
          Hòa quyện thiên nhiên: Ly House được thiết kế với những ô cửa sổ lớn, giếng trời, ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách. Đặc biệt, giải pháp sử dụng lam che nắng mặt tiền không chỉ mang tính thẩm mỹ mà còn giúp điều chỉnh ánh sáng, tạo bóng mát, giúp ngôi nhà luôn mát mẻ, dễ chịu.<br/>
          Công năng tối ưu: Ly House được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng thành viên.

<br/>
<b>Kiến trúc:<br/></b>
Mặt tiền thanh lịch: Ly House sở hữu một mặt tiền thanh lịch với những đường nét kiến trúc đơn giản, những gam màu trung tính, những vật liệu tự nhiên. Điểm nhấn của mặt tiền là hệ thống lam che nắng được thiết kế tinh tế, tạo nên một vẻ đẹp thanh lịch và độc đáo cho ngôi nhà.<br/>
Hệ thống cửa sổ lớn: Hệ thống cửa sổ lớn được thiết kế để tận dụng tối đa ánh sáng tự nhiên và gió trời, tạo cảm giác thoáng đãng, mát mẻ cho không gian sống.<br/>
Ban công xanh mát: Ban công được thiết kế với những chậu cây xanh mát, tạo nên một không gian thư giãn, ngắm cảnh, tận hưởng không khí trong lành.<br/>
Mái bằng hiện đại: Mái bằng được thiết kế theo phong cách hiện đại, tạo nên vẻ đẹp đơn giản, thanh lịch cho công trình.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH2.png"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m24.png"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/LH2.png"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất gỗ tự nhiên: Ly House sử dụng nội thất gỗ tự nhiên, mang đến sự ấm cúng, sang trọng và gần gũi với thiên nhiên cho không gian sống.<br/>
          Vật liệu tự nhiên: Công trình sử dụng những vật liệu tự nhiên như tre, nứa, mây, tạo nên một không gian sống mộc mạc, gần gũi với thiên nhiên.<br/>
          Gam màu trung tính: Gam màu trung tính như trắng, xám, be được sử dụng trong trang trí nội thất, tạo cảm giác thanh bình, thư giãn cho không gian sống.<br/>
<b>Giải pháp lam che nắng mặt tiền:<br/></b>
Tính thẩm mỹ: Hệ thống lam che nắng mặt tiền được thiết kế tinh tế, tạo nên một vẻ đẹp thanh lịch và độc đáo cho ngôi nhà. Lam được làm từ gỗ tự nhiên, tạo nên sự ấm cúng và gần gũi với thiên nhiên.<br/>
Công năng: Hệ thống lam che nắng giúp điều chỉnh ánh sáng, tạo bóng mát, giúp ngôi nhà luôn mát mẻ, dễ chịu. Lam cũng giúp cản nắng, mưa, bảo vệ nội thất khỏi tác động của thời tiết.<br/>
Thân thiện môi trường: Lam được làm từ gỗ tự nhiên, là vật liệu thân thiện với môi trường, góp phần bảo vệ môi trường sống.


          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m24.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH2.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m24.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/LH2.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/m24.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
