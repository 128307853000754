import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Xây Dựng Xanh: Tiêu Chí Quan Trọng Cho Tương Lai Bền Vững Của Việt Nam </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Trong bối cảnh biến đổi khí hậu ngày càng nghiêm trọng, việc xây dựng các công trình xanh - thân thiện môi trường - đã trở thành một ưu tiên hàng đầu, không chỉ ở Việt Nam mà trên toàn cầu. Nắm bắt xu hướng này, Việt Nam đang tích cực triển khai các chiến lược phát triển bền vững, hướng đến xây dựng các công trình xanh, góp phần bảo vệ môi trường và nâng cao chất lượng cuộc sống.
              </h6>

              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. Tiêu Chí Xây Dựng Xanh: Nền Tảng Cho Tương Lai Bền Vững
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Xây dựng xanh không chỉ là xu hướng, mà còn là một nhu cầu cấp thiết. Nó đóng vai trò quan trọng trong việc bảo vệ môi trường, tiết kiệm năng lượng, giảm thiểu ô nhiễm và nâng cao chất lượng cuộc sống. Một công trình xanh cần đáp ứng các tiêu chí sau:</h6>
              </div>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tiết kiệm năng lượng:</strong> Sử dụng năng lượng hiệu quả, tối ưu hóa ánh sáng tự nhiên, áp dụng các giải pháp tiết kiệm năng lượng như sử dụng vật liệu cách nhiệt, hệ thống chiếu sáng LED tiết kiệm điện, năng lượng mặt trời...</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sử dụng nước hiệu quả:</strong> Tăng cường sử dụng nước mưa, áp dụng các công nghệ xử lý nước thải, hạn chế lãng phí nước trong sinh hoạt và tưới tiêu.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Vật liệu xanh:</strong> Ưu tiên sử dụng vật liệu thân thiện môi trường, vật liệu tái chế, vật liệu địa phương, giảm thiểu sử dụng vật liệu có nguồn gốc từ hóa thạch, hạn chế phát thải khí nhà kính.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Giảm thiểu ô nhiễm:</strong> Áp dụng các giải pháp giảm thiểu tiếng ồn, bụi bẩn, khí thải, nước thải, góp phần bảo vệ môi trường sống xung quanh.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tăng cường không gian xanh:</strong> Tạo lập các khu vườn, cây xanh, mảng xanh trên mái nhà, góp phần cải thiện môi trường không khí, tạo cảnh quan đẹp mắt.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sử dụng công nghệ xanh:</strong> Áp dụng các công nghệ tiên tiến trong quản lý năng lượng, nước, khí thải, giúp tối ưu hóa hiệu quả sử dụng và giảm thiểu tác động tiêu cực đến môi trường.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Phát triển bền vững:</strong> Xây dựng các công trình xanh hướng đến việc bảo tồn tài nguyên, giảm thiểu tác động môi trường và đảm bảo phát triển bền vững cho thế hệ mai sau.</h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Thách Thức Và Cơ Hội Của Xây Dựng Xanh Ở Việt Nam</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Việt Nam đang đối mặt với những thách thức lớn trong việc phát triển xây dựng xanh:
              </h6>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiếu hụt nguồn lực:</strong> Công nghệ và vật liệu xây dựng xanh còn hạn chế, giá thành cao, chưa được phổ biến rộng rãi, dẫn đến chi phí đầu tư xây dựng xanh cao hơn so với xây dựng truyền thống.</h6>
              <br />
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Thiếu nhận thức:</strong> Nâng cao nhận thức về lợi ích của xây dựng xanh cho cộng đồng là điều cần thiết. Tuy nhiên, việc tiếp cận thông tin, kiến thức về xây dựng xanh còn hạn chế.</h6>
              <br />
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chính sách chưa đồng bộ:</strong> Cần có các chính sách hỗ trợ và khuyến khích phát triển xây dựng xanh, tạo điều kiện thuận lợi cho doanh nghiệp đầu tư vào công nghệ và vật liệu xanh.</h6>
              <br />
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chính sách chưa đồng bộ:</strong> Cần có các chính sách hỗ trợ và khuyến khích phát triển xây dựng xanh, tạo điều kiện thuận lợi cho doanh nghiệp đầu tư vào công nghệ và vật liệu xanh.</h6>
              <br />
            </li>
            <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >Bên cạnh những thách thức, xây dựng xanh cũng mang đến nhiều cơ hội:</h6>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao vị thế quốc tế:</strong> Việt Nam có thể thu hút đầu tư nước ngoài, nâng cao năng lực cạnh tranh trong lĩnh vực xây dựng, thu hút khách du lịch quốc tế bởi các công trình xanh độc đáo.</h6>
              <br />
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tạo việc làm mới:</strong> Phát triển xây dựng xanh sẽ tạo ra nhiều ngành nghề mới, thu hút lao động, góp phần giải quyết vấn đề việc làm.</h6>
              <br />
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao chất lượng cuộc sống:</strong> Xây dựng xanh tạo ra môi trường sống trong lành, tiết kiệm năng lượng, giảm thiểu ô nhiễm, nâng cao chất lượng cuộc sống cho người dân.</h6>
              <br />
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Hành Động Cho Tương Lai Bền Vững:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Để thúc đẩy xây dựng xanh tại Việt Nam, cần có sự chung tay của cả Chính phủ, doanh nghiệp và người dân:
              </h6>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Chính phủ:</strong> Ban hành các chính sách khuyến khích đầu tư vào công nghệ và vật liệu xanh, hỗ trợ doanh nghiệp phát triển các dự án xây dựng xanh, đẩy mạnh công tác tuyên truyền nâng cao nhận thức cho cộng đồng về xây dựng xanh.</h6>
              </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Doanh nghiệp:</strong> Đầu tư nghiên cứu và phát triển công nghệ, vật liệu xanh, ứng dụng các tiêu chuẩn xây dựng xanh trong các dự án, tham gia các chương trình đào tạo, chuyển giao công nghệ xây dựng xanh.</h6>
              </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Người dân:</strong> Nâng cao nhận thức về xây dựng xanh, lựa chọn các sản phẩm và dịch vụ thân thiện môi trường, ủng hộ các công trình xanh, cùng chung tay xây dựng một môi trường sống xanh, sạch, đẹp.</h6>
              </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kết luận:</h6>
            <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Xây dựng xanh là một chiến lược phát triển bền vững, góp phần bảo vệ môi trường và nâng cao chất lượng cuộc sống cho người dân. Việt Nam đang trên con đường phát triển, việc xây dựng các công trình xanh là nhiệm vụ trọng tâm, cần được đẩy mạnh trong thời gian tới.
              </h6>
              <br />
             <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Hãy cùng chung tay hành động, xây dựng một tương lai xanh, bền vững cho Việt Nam!
              </h6>
            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
