import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#"> Thiết kế </a>
            <a href="#"> Xây Dựng </a>
            <a href="#"> Nội Thất </a>``
          </div>
          <h1 className="title"> 
          The Scandinavian Modern Apartment
            </h1>
          <div className="text color-666 mt-30">
          PHONG CÁCH HIỆN ĐẠI MANG LẠI SỰ ẤM CÚNG
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m51.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Mô tả dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           Căn hộ như một lời khẳng định cho phong cách sống hiện đại, nhưng vẫn toát lên vẻ ấm cúng và tinh tế, nhờ sự kết hợp hài hòa với phong cách Scandinavian. Tông màu sáng chủ đạo như trắng, be, xanh nhạt tạo nên một không gian thoáng đãng và tràn đầy năng lượng. Ánh sáng tự nhiên được tận dụng tối đa, len lỏi qua những ô cửa sổ lớn, thắp sáng từng góc nhỏ trong căn nhà, tạo nên một bầu không khí thoáng đáng và tràn đầy năng lượng.

          </div>
         
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                          Dịch Vụ:
                        </small>
                        <div className="links fsz-18 fw-500">
                          Thiết kế <br /> Xây Dựng
                          <br /> Bảo Hiểm
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        The Art Gia Hòa, TP.HCM

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        80 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i51_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/i51_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất được lựa chọn kỹ càng, đơn giản nhưng vẫn toát lên vẻ đẹp tinh tế. Những chiếc ghế sofa bọc vải màu be nhạt, bàn trà bằng gỗ tự nhiên màu đen ấn tượng, kết hợp với những chiếc đèn trang trí bằng kim loại màu vàng nhạt, tất cả đều góp phần tạo nên một không gian sống ấm cúng và thu hút. Bên cạnh đó, những chi tiết trang trí như cây xanh, hoa tươi, thảm trải sàn len mềm mại, tạo nên một bầu không khí thư giãn và gần gũi với thiên nhiên.

          </div>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Căn hộ là nơi bạn có thể tìm kiếm sự bình yên và thư giãn sau một ngày dài làm việc. Nó là minh chứng cho lối sống hiện đại, tiện nghi, và gần gũi với thiên nhiên, thể hiện sự am hiểu và yêu thích cái đẹp của chủ nhân.

          </div>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj51_1.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj51_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/pj51_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           {/* tieu de tong ket */}
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          {/* tieu de tong quan */}
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj51_4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj51_5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj51_6.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj51_7.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/pj51_8.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
