import React from 'react';

function Project() {
  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a href="#">Thiết Kế Ngoại Thất</a>
           
          </div>
          <h1 className="title"> PHÚ THỌ MANOR </h1>
          <div className="text color-666 mt-30">
          Nét đẹp cổ điển giữa khung cảnh đồng quê
          </div>
        </div>
      </div>
      <div className="main-img">
        <img
          src="/innerpages/assets/img/s_project/m18.jpg"
          alt=""
          className="img-cover"
          data-speed="1.25"
        />
      </div>
      <div className="description">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
         Mô tả dự án: 
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Phu Tho Manor, tọa lạc tại ngoại ô Phú Thọ, là một công trình kiến trúc nhà ở cá nhân được thiết kế theo phong cách châu Âu truyền thống, mang đến một không gian sống sang trọng, thanh lịch và đầy hoài niệm. Ngôi nhà là sự kết hợp tinh tế giữa nét đẹp cổ điển của kiến trúc châu Âu với những yếu tố hiện đại, tạo nên một không gian sống lý tưởng cho gia đình.
          </div>
          <div
            className="text fsz-24 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="items">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Địa điểm: 
                        </small>
                        <div className="links fsz-18 fw-500">
                        Phú Thọ
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Diện tích
                        </small>
                        <div className="links fsz-18 fw-500">
                        180 m2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.2s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                        Tình trạng dự án:  
                        </small>
                        <div className="links fsz-18 fw-500">
                        Hoàn Thành
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item mt-100 wow fadeInUp slow"
                        data-wow-delay="0.4s"
                      >
                        <small className="d-block color-666 text-uppercase fsz-12 mb-30">
                      
                        </small>
                        <div className="links fsz-18 fw-500">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/innerpages/assets/img/c_line.png"
            alt=""
            className="line wow"
          />
        </div>
        <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/PT2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/PT3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="challenge pb-120">
        <div className="container">
          <h3
            className="sub-title fsz-45 mb-60 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            Tổng quan dự án:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <b>Ý tưởng thiết kế:<br/></b>
          Nét đẹp cổ điển: Phu Tho Manor được thiết kế theo phong cách châu Âu truyền thống, toát lên vẻ đẹp sang trọng, thanh lịch, thể hiện sự tinh tế và đẳng cấp của gia chủ. Những đường nét kiến trúc uốn lượn, những chi tiết trang trí cầu kỳ, những gam màu trầm ấm, tất cả tạo nên một không gian sống đầy mê hoặc.<br/>
          Hòa quyện thiên nhiên: Ngôi nhà được thiết kế với những ô cửa sổ lớn, giếng trời, ban công rộng rãi, tạo điều kiện tối đa cho ánh sáng tự nhiên và gió trời len lỏi vào từng ngóc ngách. Sân vườn được thiết kế theo phong cách châu Âu truyền thống, với những thảm cỏ xanh mướt, những bụi hoa rực rỡ sắc màu, những cây cổ thụ cao vút, tạo nên một không gian sống yên bình và thanh bình.<br/>
          Công năng tối ưu: Phu Tho Manor được bố trí khoa học, tạo sự thông thoáng và thuận tiện cho sinh hoạt của gia đình. Các không gian chức năng được phân chia hợp lý, đảm bảo sự riêng tư và thoải mái cho từng thành viên.

<br/>
<b>Kiến trúc:<br/></b>
Nội thất gỗ tự nhiên: Phu Tho Manor sử dụng nội thất gỗ tự nhiên cao cấp, mang đến sự ấm cúng, sang trọng và đẳng cấp cho không gian sống. Những bộ bàn ghế gỗ được chạm khắc tinh xảo, những tủ sách gỗ cổ điển, những chiếc gương soi bằng gỗ, tất cả tạo nên một không gian sống đầy mê hoặc.<br/>
Họa tiết hoa văn tinh xảo: Họa tiết hoa văn tinh xảo được sử dụng trong trang trí nội thất, tạo điểm nhấn cho không gian sống, thể hiện sự tinh tế và đẳng cấp của gia chủ. Những hoa văn được chạm khắc trên gỗ, trên tường, trên trần nhà, tạo nên một không gian sống đầy nghệ thuật.<br/>
Gam màu trầm ấm: Gam màu trầm ấm được sử dụng trong trang trí nội thất, tạo sự ấm cúng và sang trọng cho không gian sống. Những gam màu như vàng kem, nâu nhạt, xám nhạt, trắng kem, tất cả tạo nên một không gian sống đầy tinh tế và đẳng cấp.

          </div>
          <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3">  </span> 
            </li>
          </ul>
          <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/PT4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/PT5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/s_project/m18.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3
            className="sub-title fsz-45 mb-60 mt-150 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          Nội thất:
          </h3>
          <div
            className="text fsz-24 mb-20 fw-500 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
        Nội thất gỗ tự nhiên: Công Thu Villa sử dụng nội thất gỗ tự nhiên cao cấp, mang đến sự ấm cúng, sang trọng và đẳng cấp cho không gian sống. Những bộ bàn ghế gỗ được chạm khắc tinh xảo, những tủ sách gỗ cổ điển, những chiếc gương soi bằng gỗ, tất cả tạo nên một không gian sống đầy mê hoặc.
          </div>
        </div>
      </div>
      <div className="main-slider-img wow fadeInUp slow" data-wow-delay="0.2s">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT4.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT3.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/Ct2.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/CT1.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="img">
              <img
                src="/innerpages/assets/img/s_project/Ct5.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
        </div>
        <div className="arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default Project;
