import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Hòa Hợp âm Dương: Phong Thủy Trong Kiến Trúc & Nội Thất - Bí Quyết Cho Cuộc Sống Thịnh Vượng
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Giới thiệu:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Phong thủy, một triết lý cổ xưa của người phương Đông, đã trở thành một phần không thể thiếu trong cuộc sống của nhiều người. Phong thủy không chỉ là những nguyên lý về sự hài hòa giữa con người và môi trường mà còn mang ý nghĩa sâu sắc về sự cân bằng âm dương, tạo nên sự thịnh vượng và hạnh phúc cho cuộc sống.
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bài viết này sẽ phân tích đánh giá các nghiên cứu liên quan đến phong thủy trong thiết kế kiến trúc và nội thất, tìm hiểu những nguyên tắc cơ bản của phong thủy, những vấn đề cần lưu ý khi áp dụng phong thủy trong không gian sống, và cung cấp những ví dụ thực tế về ứng dụng phong thủy trong thiết kế kiến trúc và nội thất.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              2. Phong Thủy: Khái Niệm và Nguyên Tắc Cơ Bản:
              </h6>
             <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              2.1. Khái niệm:
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Phong thủy là một ngành khoa học cổ xưa của người Trung Quốc, được hiểu là sự hài hòa giữa con người và môi trường. Phong thủy dựa trên nguyên lý âm dương, ngũ hành, bát quái để phân tích và đánh giá tác động của môi trường đến cuộc sống của con người.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              2.2. Nguyên tắc cơ bản:
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Âm dương: âm dương là hai mặt đối lập và bổ sung cho nhau, tạo nên sự cân bằng cho vũ trụ. Trong phong thủy, âm dương được ứng dụng trong việc bố trí không gian, lựa chọn màu sắc, vật liệu…
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Ngũ hành: Ngũ hành gồm kim, mộc, thủy, hỏa, thổ, là năm yếu tố cơ bản của vũ trụ. Ngũ hành có mối quan hệ sinh khắc lẫn nhau, tạo nên sự cân bằng cho vũ trụ. Trong phong thủy, ngũ hành được ứng dụng trong việc bố trí không gian, lựa chọn màu sắc, vật liệu…

              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bát quái: Bát quái là tám quẻ biểu thị tám hướng trong không gian, mỗi quẻ mang một ý nghĩa riêng. Trong phong thủy, bát quái được ứng dụng trong việc xác định hướng nhà, hướng cửa, hướng giường ngủ…

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              3. Phong Thủy Trong Thiết Kế Kiến Trúc & Nội Thất:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              3.1. Phong thủy trong thiết kế kiến trúc:
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Hướng nhà: Hướng nhà là yếu tố quan trọng trong phong thủy, ảnh hưởng đến sự thịnh vượng, hạnh phúc của gia chủ. Cần lựa chọn hướng nhà phù hợp với ngũ hành của gia chủ và hướng nắng, hướng gió tốt.

              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Hình dáng nhà: Hình dáng nhà cũng là yếu tố quan trọng trong phong thủy. Nên lựa chọn hình dáng nhà hài hòa, không có góc nhọn, góc vuông gây ảnh hưởng xấu đến cuộc sống.
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Mái nhà: Mái nhà nên có hình dạng hài hòa, không quá cao, quá thấp hay quá dốc. Nên lựa chọn màu sắc mái nhà phù hợp với ngũ hành của gia chủ.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              3.2. Phong thủy trong thiết kế nội thất:
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bố trí nội thất: Bố trí nội thất cần phải hài hòa, tạo cảm giác thoáng đáng, dễ chịu. Nên lựa chọn màu sắc vật liệu nội thất phù hợp với ngũ hành của gia chủ.
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Vị trí phòng ngủ: Vị trí phòng ngủ nên là nơi yên tĩnh, thoáng đáng, không nên đặt giường ngủ gần cửa ra vào, gần nhà tắm, gần bếp nấu.
              </h6>
            
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Bố trí bàn làm việc: Bàn làm việc nên đặt ở nơi có ánh sáng tự nhiên tốt, không nên đặt gần cửa sổ, gần nhà tắm, gần bếp nấu.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4. Những Vấn Đề Cần Lưu Ý Khi Thiết Kế Phong Thủy:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4.1. Lựa chọn kiến trúc sư:
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Kiến thức phong thủy: Nên lựa chọn kiến trúc sư có kiến thức phong thủy, am hiểu về nguyên lý âm dương, ngũ hành, bát quái.
              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Kinh nghiệm: Nên lựa chọn kiến trúc sư có kinh nghiệm trong việc thiết kế phong thủy, đã thực hiện nhiều dự án thành công.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4.2. Xác định ngũ hành:
              </h6>
            
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Ngũ hành của gia chủ: Cần xác định ngũ hành của gia chủ để lựa chọn hướng nhà, màu sắc vật liệu phù hợp.

              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Ngũ hành của không gian: Cần xác định ngũ hành của không gian để bố trí nội thất, lựa chọn màu sắc, vật liệu phù hợp.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4.3. Bố trí không gian:
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Hài hòa, thoáng đãng: Bố trí không gian cần phải hài hòa, thoáng đáng, không nên có góc nhọn, góc vuông gây ảnh hưởng xấu đến cuộc sống.
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Hạn chế những yếu tố tiêu cực: Nên hạn chế những yếu tố tiêu cực trong không gian như góc tù, góc nhọn, hành lang hẹp, cửa sổ quá lớn, bồn nước bị bẩn…

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4.4. Sử dụng màu sắc:
              </h6>
             
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Ngũ hành: Lựa chọn màu sắc phù hợp với ngũ hành của gia chủ và ngũ hành của không gian.

              </h6>
            
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Tác động tâm lý: Cần lựa chọn màu sắc phù hợp với tác động tâm lý, tạo cảm giác thoải mái, dễ chịu cho không gian.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              4.5. Sử dụng vật liệu:

              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Vật liệu tự nhiên: Nên sử dụng các vật liệu tự nhiên như gỗ, đá, tre nứa … tạo cảm giác thoải mái, gần gũi với thiên nhiên.

              </h6>
              
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Vật liệu nhân tạo: Nên lựa chọn những loại vật liệu nhân tạo an toàn cho sức khỏe, không gây ảnh hưởng xấu đến phong thủy.
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              5. Phong Thủy Trong Ứng Dụng Thực Tiễn:
              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              5.1. Phong thủy trong thiết kế nhà ở:
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Hướng nhà: Lựa chọn hướng nhà phù hợp với ngũ hành của gia chủ và hướng nắng, hướng gió tốt.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Bố trí phòng ngủ: Bố trí phòng ngủ theo nguyên lý phong thủy, giúp gia chủ ngủ ngon, giấc ngủ êm ái.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Bố trí phòng khách: Bố trí phòng khách theo nguyên lý phong thủy, tạo cảm giác thoáng đáng, hài hòa, thu hút tài lộc.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Bố trí bếp: Bố trí bếp theo nguyên lý phong thủy, đảm bảo sức khỏe cho gia đình, thu hút tài lộc.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
5.2. Phong thủy trong thiết kế văn phòng:

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Hướng bàn làm việc: Lựa chọn hướng bàn làm việc phù hợp với ngũ hành của người làm việc, tạo cảm giác thoải mái, tăng cường hiệu quả làm việc.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Bố trí không gian: Bố trí không gian văn phòng theo nguyên lý phong thủy, tạo cảm giác thoáng đáng, hài hòa, thu hút tài lộc.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Sử dụng màu sắc: Lựa chọn màu sắc phù hợp với ngũ hành của người làm việc, tạo cảm giác thoải mái, tăng cường hiệu quả làm việc.

              </h6>
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
5.3. Phong thủy trong thiết kế cửa hàng:

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Hướng cửa hàng: Lựa chọn hướng cửa hàng phù hợp với ngũ hành của chủ cửa hàng, thu hút khách hàng, tăng doanh thu.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Bố trí không gian: Bố trí không gian cửa hàng theo nguyên lý phong thủy, tạo cảm giác thoáng đáng, hài hòa, thu hút khách hàng.

              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Sử dụng màu sắc: Lựa chọn màu sắc phù hợp với ngành nghề kinh doanh, tạo cảm giác thuận lợi, tăng doanh thu.

              </h6>
            </div>
          </div>
        </div>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b01.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b02.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b03.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        <div className="row justify-content-center">
          <div className="col-lg-8">
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
6. Nghiên cứu về Phong Thủy:

              </h6>
              <br />
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
6.1. Nghiên cứu về Tâm lý:
              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Tác động của môi trường: Các nghiên cứu tâm lý cho thấy ảnh hưởng của môi trường đến tâm trạng, hành vi của con người.

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Cảm giác thoải mái: Những không gian được thiết kế theo nguyên lý phong thủy thường tạo cảm giác thoải mái, dễ chịu cho người sử dụng.

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Tăng cường hiệu quả: Những không gian được thiết kế theo nguyên lý phong thủy thường giúp con người tăng cường hiệu quả làm việc, học tập.

              </h6>
              <br />
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
6.2. Nghiên cứu về Sinh học:
              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Tác động của ánh sáng: Các nghiên cứu sinh học cho thấy ảnh hưởng của ánh sáng đến sức khỏe của con người.

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Ánh sáng tự nhiên: Ánh sáng tự nhiên có tác động tích cực đến sức khỏe, giúp con người cảm thấy thoải mái, tăng cường sự tập trung.

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Hướng ánh sáng: Hướng ánh sáng trong thiết kế kiến trúc và nội thất cũng là yếu tố quan trọng trong phong thủy.

              </h6>
              <br />
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
6.3. Nghiên cứu về Kinh tế:

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Tác động của phong thủy: Các nghiên cứu kinh tế cho thấy tác động của phong thủy đến doanh thu, sự thịnh vượng của các doanh nghiệp.
              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Phong thủy trong kinh doanh: Phong thủy được ứng dụng rộng rãi trong kinh doanh, giúp thu hút khách hàng, tăng doanh thu.

              </h6>
          <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
Thị trường phong thủy: Thị trường phong thủy đang phát triển mạnh mẽ, tạo ra nhiều cơ hội kinh doanh cho các doanh nghiệp.
              </h6>
             <br />
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            7. Kết luận:
</h6>
<br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Phong thủy, một triết lý cổ xưa của người phương Đông, đã trở thành một phần không thể thiếu trong thiết kế kiến trúc và nội thất, góp phần tạo nên sự hài hòa giữa con người và môi trường. Việc áp dụng nguyên lý phong thủy trong thiết kế không gian sống giúp con người tìm kiếm sự cân bằng âm dương, thu hút tài lộc, tăng cường sức khỏe, mang lại sự thịnh vượng và hạnh phúc cho cuộc sống.
            </h6>
            <br />
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
