import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#"> Ánh Sáng: Ngôn Ngữ Của Kiến Trúc, Nét Đẹp Của Cuộc Sống </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Ánh sáng, yếu tố tưởng chừng đơn giản nhưng lại là ngôn ngữ quan trọng nhất trong thiết kế kiến trúc. Nó không chỉ mang đến vẻ đẹp cho công trình mà còn tạo nên bầu không khí, cảm xúc và ảnh hưởng trực tiếp đến cuộc sống của con người. Tại Việt Nam, nơi ánh nắng chan hòa và văn hóa đậm nét Á Đông, ánh sáng càng trở nên đặc biệt, góp phần tạo nên nét đẹp độc đáo cho kiến trúc.
              </h6>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1. Ánh Sáng - Ngôn Ngữ Của Kiến Trúc:
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tạo hình và không gian:</strong> Ánh sáng có khả năng tạo hình, nhấn mạnh, làm nổi bật các chi tiết kiến trúc, tạo chiều sâu và cảm giác rộng rãi cho không gian. Sự tương phản giữa sáng và tối, giữa ánh sáng tự nhiên và nhân tạo, tạo nên hiệu ứng thị giác ấn tượng, mang đến sự hấp dẫn cho công trình.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tạo cảm xúc và tâm trạng:</strong> Ánh sáng có thể tác động trực tiếp đến tâm trạng và cảm xúc của con người. Ánh sáng ấm áp, mềm mại tạo cảm giác thư giãn, ấm cúng, trong khi ánh sáng mạnh, sắc nét lại mang đến sự năng động, tràn đầy năng lượng. Sự kết hợp hài hòa giữa các loại ánh sáng giúp tạo nên không gian phù hợp với chức năng và mục đích sử dụng.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 

              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <strong>Kết nối con người với thiên nhiên:</strong> Ánh sáng tự nhiên là yếu tố quan trọng giúp con người kết nối với thiên nhiên, mang đến sự thư thái và thoải mái. Việc thiết kế kiến trúc tận dụng tối đa ánh sáng tự nhiên, giảm thiểu sử dụng ánh sáng nhân tạo, góp phần bảo vệ môi trường, tiết kiệm năng lượng, nâng cao chất lượng cuộc sống. </h6>
            </li>
          </ul>
          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >2. Ánh Sáng Trong Kiến Trúc Việt Nam:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Truyền thống và hiện đại:</strong> Kiến trúc truyền thống Việt Nam luôn chú trọng đến việc sử dụng ánh sáng tự nhiên. Các ngôi nhà cổ thường có nhiều cửa sổ, giếng trời, giúp đón ánh sáng tự nhiên vào không gian, tạo nên sự thông thoáng, dễ chịu. Trong kiến trúc hiện đại, ánh sáng được ứng dụng một cách linh hoạt hơn, kết hợp hài hòa giữa ánh sáng tự nhiên và nhân tạo, tạo nên những công trình độc đáo, hiện đại và đầy tính thẩm mỹ.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Văn hóa và tâm linh:</strong> Ánh sáng trong kiến trúc Việt Nam cũng mang ý nghĩa tâm linh, thể hiện sự tôn trọng đối với thiên nhiên và tổ tiên. Các ngôi chùa, đền, miếu thường được thiết kế với nhiều cửa sổ, giếng trời, để đón ánh sáng tự nhiên vào không gian, tạo nên bầu không khí trang nghiêm, thánh khiết.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span> 
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Sự đa dạng và sáng tạo:</strong> Kiến trúc Việt Nam đang ngày càng thể hiện sự đa dạng và sáng tạo trong việc sử dụng ánh sáng. Các kiến trúc sư đang ứng dụng nhiều kỹ thuật, công nghệ chiếu sáng tiên tiến, tạo nên những hiệu ứng ánh sáng độc đáo, mang đến sự khác biệt cho các công trình.</h6>
            </li>
          </ul>
              </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >3. Vai trò của ánh sáng trong cuộc sống:</h6>
            <br />
                <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Nâng cao chất lượng cuộc sống:</strong> Ánh sáng phù hợp giúp cải thiện tâm trạng, tăng năng suất làm việc, giúp con người khỏe mạnh và hạnh phúc hơn.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Bảo vệ sức khỏe:</strong> Ánh sáng tự nhiên giúp cơ thể sản sinh vitamin D, tăng cường hệ miễn dịch, giảm nguy cơ mắc bệnh tật. Việc sử dụng ánh sáng nhân tạo cần chú ý đến cường độ, màu sắc, để tránh ảnh hưởng đến thị lực và sức khỏe.</h6>
            </li>
            <li className="d-flex my-4">
              <span className="icon me-3"> • </span>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><strong>Tiết kiệm năng lượng:</strong> Tận dụng tối đa ánh sáng tự nhiên, giảm thiểu sử dụng ánh sáng nhân tạo, góp phần tiết kiệm năng lượng, bảo vệ môi trường.</h6>
            </li>
          </ul>
              </div>
              <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >4. Thách thức và cơ hội:</h6>
            <br />
            <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
          <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Thách thức:</strong></h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
          <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Thiếu kiến thức và kỹ năng:</strong> Việc ứng dụng ánh sáng trong thiết kế kiến trúc đòi hỏi kiến thức và kỹ năng chuyên môn, không phải kiến trúc sư nào cũng nắm vững.</h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
          <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            ><strong>Thiếu nguồn lực:</strong> Việc đầu tư vào các hệ thống chiếu sáng hiện đại đòi hỏi nguồn lực tài chính lớn, không phải dự án nào cũng đủ khả năng đáp ứng.</h6>
            </li>
          </ul>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
            <ul
            className="dots-list fsz-18 color-666 wow fadeInUp slow"
            data-wow-delay="0.2s"
          >
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            <strong>Cơ hội:</strong>
            </h6>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            <strong>Phát triển công nghệ:</strong> Công nghệ chiếu sáng đang ngày càng phát triển, cho ra đời các loại đèn tiết kiệm năng lượng, ánh sáng tự nhiên, và nhiều giải pháp chiếu sáng tiên tiến, mang đến nhiều lựa chọn cho các kiến trúc sư.
            </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            <strong>Xu hướng xanh:</strong> Nhu cầu về các công trình xanh, bền vững đang ngày càng tăng cao, tạo cơ hội cho việc ứng dụng ánh sáng một cách hiệu quả và tiết kiệm năng lượng.
            </h6>
            </li>
            <li className="d-flex my-4">
            <span className="icon me-3"> • </span>
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            <strong>Nâng cao năng lực cạnh tranh:</strong> Việc ứng dụng ánh sáng một cách sáng tạo, hiệu quả giúp các kiến trúc sư nâng cao năng lực cạnh tranh, tạo ra những công trình độc đáo, thu hút khách hàng.
            </h6>
            </li>
          </ul>
            </li>
          </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Kết luận:</h6>
            <br />
            <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Ánh sáng là yếu tố quan trọng, không thể thiếu trong thiết kế kiến trúc. Việc ứng dụng ánh sáng một cách sáng tạo, hiệu quả giúp tạo nên những công trình đẹp mắt, tiện nghi, thân thiện môi trường, nâng cao chất lượng cuộc sống. Hãy cùng chung tay nâng cao nhận thức về vai trò của ánh sáng trong thiết kế kiến trúc, để kiến tạo những công trình kiến trúc đẹp và bền vững cho Việt Nam.</h6>
            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                    <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
