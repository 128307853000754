import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="/"> Home </a>
                <span className="icon">/</span>
                <a href="/BlogPage"> News </a>
                <span className="icon">/</span>
                <a href="#">Thiết kế thi công trọn gói kiến trúc Hành trình kiến tạo giấc mơ của bạn
                </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               1. Hành trình kiến tạo không gian mơ ước: Thiết kế và thi công trọn gói kiến trúc
              </h6>
              <br />
              <br />
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              1.1. Nhu cầu tất yếu trong cuộc sống hiện đại:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              Trong bối cảnh đô thị hóa và phát triển kinh tế, nhu cầu về không gian sống tiện nghi, hiện đại và phù hợp với cá tính ngày càng tăng cao. Thiết kế và thi công trọn gói kiến trúc ra đời như một giải pháp tối ưu cho người dân, giúp họ tiết kiệm thời gian, công sức và tiền bạc trong việc tạo dựng không gian sống lý tưởng của mình.
              </h6>
              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                1.2. Lợi ích vượt trội của dịch vụ thiết kế thi công trọn gói kiến trúc:
              </h6>
              <br />
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Tiết kiệm thời gian và công sức: Khách hàng không cần phải tự mình tìm kiếm, lựa chọn các đơn vị thiết kế, thi công riêng biệt, mà có thể tin tưởng vào dịch vụ trọn gói, giúp họ tập trung vào những việc quan trọng khác trong cuộc sống.
<br /><br />Đảm bảo tính đồng bộ và thống nhất: Các giai đoạn thiết kế và thi công được thực hiện bởi một đơn vị, đảm bảo sự đồng bộ về phong cách, chất lượng và tiến độ, tạo nên một không gian sống hài hòa và ấn tượng.

<br /><br />Kiểm soát chi phí hiệu quả: Việc lựa chọn dịch vụ trọn gói giúp khách hàng kiểm soát chi phí một cách hiệu quả, tránh tình trạng phát sinh chi phí ngoài dự toán, mang đến sự an tâm cho khách hàng.

<br /><br />Bảo hành và bảo trì chuyên nghiệp: Khách hàng sẽ được hưởng dịch vụ bảo hành và bảo trì chuyên nghiệp từ phía đơn vị thi công, đảm bảo không gian sống luôn được bảo vệ và nâng cao chất lượng sống.
              </h6>
              </div>
              
            </div>
          </div>
        </div>
        <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b30_2.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEX-Holding
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b30_3.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                  Hình ảnh cấp bởi NEXSA
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2. Những lưu ý quan trọng khi lựa chọn dịch vụ thiết kế thi công trọn gói kiến trúc:
        </h6>
        <br />
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.1. Lựa chọn đơn vị uy tín và chuyên nghiệp:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Kinh nghiệm và chuyên môn: Lựa chọn đơn vị có kinh nghiệm và chuyên môn cao trong lĩnh vực thiết kế và thi công kiến trúc, đảm bảo khả năng hiểu rõ nhu cầu và mang đến những giải pháp tối ưu cho khách hàng.
<br /><br />Phong cách thiết kế: Lựa chọn đơn vị có phong cách thiết kế phù hợp với sở thích và nhu cầu của khách hàng, tạo ra một không gian sống thực sự phản ánh cá tính của chủ nhân.

<br /><br />Khả năng quản lý dự án: Lựa chọn đơn vị có khả năng quản lý dự án hiệu quả, đảm bảo tiến độ thi công theo kế hoạch đã đặt ra, mang đến sự an tâm cho khách hàng.

<br /><br />Thái độ phục vụ: Lựa chọn đơn vị có thái độ phục vụ chuyên nghiệp, tận tâm, luôn lắng nghe ý kiến của khách hàng và mang đến những giải pháp thỏa mãn nhất.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >2.2. Xây dựng kế hoạch chi tiết và minh bạch:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Xác định rõ mục tiêu: Xác định rõ mục tiêu của việc thiết kế và thi công kiến trúc, phong cách thiết kế, chức năng sử dụng của không gian, vật liệu ưa thích, màu sắc mong muốn, v.v., để đảm bảo sự hiểu rõ giữa hai bên.
<br /><br />Lập kế hoạch chi tiết: Lập kế hoạch chi tiết cho mỗi giai đoạn thiết kế và thi công, bao gồm tiến độ, chi phí, vật liệu, nhân sự, v.v., để có sự chuẩn bị kỹ lưỡng và kiểm soát hiệu quả.

<br /><br />Thỏa thuận rõ ràng: Thỏa thuận rõ ràng với đơn vị thiết kế và thi công về mọi nội dung của hợp đồng, bao gồm quy trình làm việc, tiến độ, chi phí, chất lượng, vật liệu, bảo hành, v.v., để tránh những hiểu lầm và tranh chấp không đáng có.

        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          2.3. Kiểm soát chất lượng trong từng giai đoạn:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          Kiểm tra chất lượng vật liệu: Kiểm tra chất lượng của các vật liệu được sử dụng trong việc thi công, đảm bảo sự phù hợp với tiêu chuẩn và yêu cầu kỹ thuật đã thỏa thuận.
<br /><br />Theo dõi tiến độ thi công: Theo dõi tiến độ thi công để đảm bảo công trình được hoàn thành theo kế hoạch, tránh tình trạng trễ hạn và ảnh hưởng đến tiến độ của khách hàng.

<br /><br />Nghiệm thu công trình: Nghiệm thu công trình sau khi hoàn thành thi công để đảm bảo công trình đạt chuẩn chất lượng theo hợp đồng, mang đến sự hài lòng cho khách hàng.

        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         3. Hành trình kiến tạo: Các bước thiết kế kiến trúc
        </h6>  
        <br />
        <br />
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         3.1. Bước 1: Khảo sát và thu thập thông tin:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
        Khảo sát thực tế: Tiến hành khảo sát thực tế khu vực xây dựng, ghi chép kích thước, hình dạng, vị trí của các cấu trúc, hệ thống điện, nước, đường ống thoát nước, v.v., để có dữ liệu chính xác cho việc thiết kế.
<br /><br />Thu thập thông tin từ khách hàng: Lắng nghe ý kiến, nhu cầu và sở thích của khách hàng về phong cách thiết kế, chức năng sử dụng của không gian, vật liệu, màu sắc ưa thích, những điểm nhấn mong muốn, v.v., để hiểu rõ mong muốn của khách hàng.

<br /><br />Phân tích thông tin: Phân tích thông tin đã thu thập để lập kế hoạch thiết kế phù hợp nhất, tạo ra những giải pháp tối ưu cho không gian.

        </h6>  
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         3.2. Bước 2: Lập phương án thiết kế sơ bộ:
        </h6>  
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
         Xây dựng ý tưởng thiết kế: Xây dựng ý tưởng thiết kế dựa trên nhu cầu của khách hàng, phong cách thiết kế lựa chọn, kích thước và hình dạng của không gian, mang đến những giải pháp sáng tạo và hấp dẫn.
<br /><br />Phác thảo bố trí không gian: Phác thảo bố trí không gian, xác định vị trí của các phòng chức năng, hướng nhìn, ánh sáng, v.v., tạo ra sự hài hòa và thuận tiện cho sử dụng.

<br /><br />Chọn vật liệu và màu sắc: Lựa chọn vật liệu và màu sắc phù hợp với phong cách thiết kế và nhu cầu của khách hàng, tạo ra bầu không khí riêng cho không gian.

<br /><br />Trình bày ý tưởng: Trình bày ý tưởng thiết kế sơ bộ cho khách hàng để nhận được ý kiến, phê duyệt và tiến hành hoàn thiện bản vẽ.
        </h6>  
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >3.3. Bước 3: Hoàn thiện bản vẽ thiết kế:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Hoàn thiện bản vẽ kỹ thuật: Hoàn thiện bản vẽ kỹ thuật chi tiết của toàn bộ công trình, bao gồm bản vẽ mặt bằng, mặt cắt, mặt nâng, v.v., để đảm bảo sự chính xác và hiệu quả trong việc thi công.
<br /><br />Thiết kế chi tiết kiến trúc: Thiết kế chi tiết cho mỗi phòng chức năng, xác định vật liệu xây dựng, kích thước, hình dạng, v.v., tạo nên sự hài hòa và ấn tượng cho công trình.

<br /><br />Chọn lựa vật liệu và phụ kiện: Lựa chọn vật liệu và phụ kiện phù hợp với phong cách thiết kế và yêu cầu kỹ thuật, đảm bảo chất lượng và độ bền cho công trình.

<br /><br />Hoàn thiện bản vẽ 3D: Hoàn thiện bản vẽ 3D mô phỏng công trình thiết kế, giúp khách hàng hình dung rõ hơn về không gian sống tương lai, mang đến sự hài lòng và phê duyệt từ phía khách hàng.

        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          3.4. Bước 4: Chuẩn bị hồ sơ và dự toán thi công:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
          Chuẩn bị hồ sơ thi công: Chuẩn bị hồ sơ thi công chi tiết cho mỗi giai đoạn thi công, bao gồm bản vẽ kỹ thuật, danh mục vật liệu, vật tư, phụ kiện, v.v., để đảm bảo sự chính xác và hiệu quả trong việc thi công.
<br /><br />Lập dự toán chi phí: Lập dự toán chi phí thi công chi tiết, bao gồm chi phí vật liệu, nhân công, vận chuyển, v.v., để khách hàng có thể kiểm soát chi phí một cách hiệu quả.

<br /><br />Trình bày hồ sơ và dự toán: Trình bày hồ sơ và dự toán cho khách hàng để phê duyệt, đảm bảo sự minh bạch và đồng ý của khách hàng.

        </h6>
              </div>
        <div className="imgs mt-120">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b30_4.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b30_5.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="img th-350 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b30_6.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4. Hành động kiến tạo: Các bước thi công kiến trúc
        </h6>
        <br /><br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.1. Bước 1: Chuẩn bị thi công:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                 <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Kiểm tra hiện trạng công trình: Kiểm tra hiện trạng công trình, đảm bảo không gian thi công đạt chuẩn an toàn, có những biện pháp phù hợp để bảo vệ tài sản của khách hàng.
<br /><br />Chuẩn bị vật liệu và dụng cụ: Chuẩn bị đầy đủ vật liệu, dụng cụ thi công theo danh mục trong hồ sơ thi công, đảm bảo chất lượng và đủ lượng để thi công theo kế hoạch.
<br /><br />Phân công nhân sự: Phân công nhân sự thi công theo chuyên ngành, kỹ năng và kinh nghiệm, đảm bảo sự hiệu quả và chất lượng trong thi công.
        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.2. Bước 2: Thi công phần thô:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                  <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thi công phần móng, kết cấu: Thi công phần móng, kết cấu theo bản vẽ kỹ thuật, sử dụng vật liệu chất lượng cao, đảm bảo độ chắc chắn và an toàn cho công trình.
<br /><br />Thi công hệ thống điện, nước: Thi công hệ thống điện, nước theo bản vẽ kỹ thuật, sử dụng vật liệu chất lượng cao, đảm bảo an toàn và tiện nghi cho sử dụng, tránh tình trạng rò rỉ và sự cố không đáng có.

<br /><br />Thi công tường, trần, sàn: Thi công tường, trần, sàn theo bản vẽ kỹ thuật, sử dụng vật liệu chất lượng cao, đảm bảo độ bền, chắc chắn và mỹ quan cho công trình.

        </h6>
              </div>
              <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >4.3. Bước 3: Hoàn thiện kiến trúc:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Thi công ốp lát: Thi công ốp lát tường, sàn theo bản vẽ kỹ thuật, sử dụng vật liệu phù hợp với phong cách thiết kế, đảm bảo độ bền, chắc chắn và mỹ quan cho công trình.
<br /><br />Lắp đặt hệ thống cửa: Lắp đặt hệ thống cửa gỗ, cửa kính, cửa sắt theo bản vẽ kỹ thuật, đảm bảo độ chắc chắn và mỹ quan, tạo nên sự hài hòa cho công trình.

<br /><br />Lắp đặt hệ thống mái: Lắp đặt hệ thống mái theo bản vẽ kỹ thuật, đảm bảo độ chắc chắn và chống thấm nước, tạo nên sự hài hòa cho công trình.

<br /><br />Hoàn thiện cảnh quan: Hoàn thiện cảnh quan ngoại thất theo bản vẽ kỹ thuật, tạo nên một không gian sống thân thiện và ấn tượng.

        </h6></div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5. NEXCON: Chìa khóa cho không gian sống lý tưởng
        </h6>
        <br />
        <br />
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.1. Giới thiệu về NEXCON:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >NEXCON là đơn vị thiết kế thi công trọn gói kiến trúc trực thuộc Nexholding, một tập đoàn bất động sản và kiến trúc uy tín và chuyên nghiệp tại Việt Nam. Với kinh nghiệm phong phú trong lĩnh vực thiết kế và thi công kiến trúc, NEXCON luôn nỗ lực mang đến những giải pháp tối ưu nhất, góp phần kiến tạo những không gian sống đẹp đẽ, tiện nghi và phù hợp với mọi nhu cầu của khách hàng.

        </h6></div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.2. Quy trình 9 bước thiết kế thi công trọn gói của NEXCON:

        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              ><h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >
       Bước 0: Tư vấn phương án thiết kế sơ bộ: Cung cấp cho khách hàng những phương án thiết kế sơ bộ phù hợp với nhu cầu và điều kiện của khách hàng, mang đến những ý tưởng sáng tạo và hấp dẫn.
              <br /><br /> Bước 1: Ký hợp đồng thiết kế: Ký hợp đồng thiết kế rõ ràng về nội dung công việc, tiến độ, chi phí, chất lượng, vật liệu, đảm bảo sự minh bạch và đồng ý của khách hàng.

              <br /><br /> Bước 2: Thiết kế: Tiến hành thiết kế theo hợp đồng, bao gồm thiết kế sơ bộ, thiết kế chi tiết, hoàn thiện bản vẽ 3D, đảm bảo sự chính xác và hiệu quả trong việc thi công.

              <br /><br /> Bước 3: Chuẩn bị hồ sơ và dự toán thi công: Chuẩn bị hồ sơ thi công chi tiết và lập dự toán chi phí thi công, đảm bảo sự minh bạch và đồng ý của khách hàng.

              <br /><br /> Bước 4: Ký hợp đồng thi công: Ký hợp đồng thi công rõ ràng về nội dung công việc, tiến độ, chi phí, chất lượng, vật liệu, bảo hành, v.v., đảm bảo sự minh bạch và đồng ý của khách hàng.

              <br /><br /> Bước 5: Thi công: Tiến hành thi công theo hợp đồng, đảm bảo tiến độ, chất lượng và an toàn trong thi công, mang đến sự an tâm cho khách hàng.

              <br /><br /> Bước 6: Nghiệm thu: Nghiệm thu công trình sau khi hoàn thành thi công để đảm bảo công trình đạt chuẩn chất lượng theo hợp đồng, mang đến sự hài lòng cho khách hàng.

              <br /><br /> Bước 7: Bàn giao: Bàn giao công trình cho khách hàng sau khi đã nghiệm thu và đạt chuẩn chất lượng, mang đến sự hài lòng cho khách hàng.

              <br /><br /> Bước 8: Bảo hành, bào trì sản phẩm: Cung cấp dịch vụ bảo hành, bảo trì chuyên nghiệp cho khách hàng theo hợp đồng, đảm bảo không gian sống luôn được bảo vệ và nâng cao chất lượng sống.</h6> </div>
        <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >5.3. Lợi thế vượt trội của NEXCON:
        </h6>
        <br />
        <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              > <h6
          className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
          data-wow-delay="0.2s"
        >Đội ngũ chuyên nghiệp: NEXCON có đội ngũ kiến trúc sư, kỹ sư giàu kinh nghiệm, nắm bắt sâu sắc kiến thức về thiết kế và thi công kiến trúc, mang đến những giải pháp tối ưu nhất cho khách hàng.
<br /><br />Hệ thống quản lý chuyên nghiệp: NEXCON áp dụng hệ thống quản lý chuyên nghiệp, đảm bảo tiến độ, chất lượng và an toàn trong thi công, mang đến sự an tâm cho khách hàng.

<br /><br />Vật liệu chất lượng cao: NEXCON sử dụng vật liệu chất lượng cao, đảm bảo độ bền, chắc chắn và mỹ quan cho công trình, tạo ra một không gian sống bền bỉ và ấn tượng.

<br /><br />Dịch vụ khách hàng chuyên nghiệp: NEXCON luôn chú trọng dịch vụ khách hàng, luôn lắng nghe ý kiến, nhu cầu của khách hàng và mang đến những giải pháp thỏa mãn nhất, tạo ra sự hài lòng cho khách hàng.

        </h6></div>

          <div className="imgs mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b30_7.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="/innerpages/assets/img/blog/b30_8.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row justify-content-center">
          <div className="col-lg-8">
          
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            6. Đơn giá thi công kiến trúc trọn gói của NEXCON:


            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            Đơn giá thi công kiến trúc trọn gói của NEXCON phụ thuộc vào nhiều yếu tố như: diện tích xây dựng, phong cách thiết kế, vật liệu sử dụng, vị trí xây dựng, v.v. Để nhận được báo giá chi tiết, khách hàng có thể liên hệ trực tiếp với NEXCON qua website hoặc số điện thoại liên hệ.



            </h6>
            <br />
           <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
     Thiết kế và thi công kiến trúc trọn gói là giải pháp tối ưu giúp người dân tiết kiệm thời gian, công sức và tiền bạc trong việc tạo dựng không gian sống lý tưởng. NEXCON, với tư cách là đơn vị thiết kế thi công trọn gói kiến trúc uy tín và chuyên nghiệp, luôn nỗ lực mang đến những giải pháp tối ưu nhất, góp phần kiến tạo những không gian sống đẹp đẽ, tiện nghi và phù hợp với mọi nhu cầu của khách hàng.



            </h6>
            <br />
           
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Nexcon </a>
                    <a href="#"> Nexsa </a>
                    <a href="#"> Wooder </a>
                    <a href="#"> Nex-holding </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/home_preview/assets/img/logo12.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Nex-Holding</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      Không chỉ xây dựng công trình chúng tôi còn xây dựng ước mơ.
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Bài viết khác </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage28"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b28_8.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            Nexsa - Nexcon
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> Ngày 20/01/2023 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Phong cách thiết kế và bản sắc cá nhân
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="/SinglePostPage29"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b29_7.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                          Thành phố thông minh: Con đường phát triển bền vững cho Việt Nam
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
